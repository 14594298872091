import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  value: true,
};

export const priceVisiblitiySlice = createSlice({
  name: 'priceVisibility',
  initialState,
  reducers: {
    changePriceVisibility: (state) => {
      state.value = !state.value;
    },
  },
});

export const { changePriceVisibility } = priceVisiblitiySlice.actions;

export default priceVisiblitiySlice.reducer;
