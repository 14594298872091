import { Box, Button, Paper, TextField } from '@mui/material';
import { FunctionComponent, useContext, useEffect, useState } from 'react';
import { getTotalShopcartQuantity } from '../../../user/shopcartV2/functions/getTotalShopcartQuantity';
import { getTotalShopcartValue } from '../../../user/shopcartV2/functions/getTotalShopcartValue';
import { CSVLink } from 'react-csv';
import DownloadIcon from '@mui/icons-material/Download';
import DeleteIcon from '@mui/icons-material/Delete';
import { Authcontext } from '../../../../auth/context/auth-context';
import { useHistory } from 'react-router-dom';
import { Dialog } from '../../../../common/dialog/Dialog';

interface OrderInformationProps {
  order: any;
}

export const OrderInformation: FunctionComponent<OrderInformationProps> = ({
  order,
}) => {
  const auth = useContext(Authcontext);
  const history = useHistory();

  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);

  const totalShopcartQuantity = getTotalShopcartQuantity(
    order.shopcartProducts
  );
  const totalShopcartValue = getTotalShopcartValue(order.shopcartProducts);

  const headers = [
    { label: 'sku', key: 'sku' },
    { label: 'size', key: 'size' },
    { label: 'price', key: 'price' },
    { label: 'quantity', key: 'quantity' },
  ];

  const [data, setData] = useState<any>([]);

  const [isDownloadAvailable, setIsDownloadAvailable] =
    useState<boolean>(false);

  let count = 0;
  let numProducts = order.shopcartProducts.length;
  useEffect(() => {
    const promise = new Promise((resolve, reject) => {
      const csvFormatProducts: any = [];

      order.shopcartProducts.forEach((product: any) => {
        count++;
        let isFirstVariant = true;

        product.variants.forEach((variant: any) => {
          const csvFormatVariant = {
            sku: isFirstVariant ? product.id : '',
            size: variant.size,
            price: `${variant.price} €`,
            quantity: variant.quantity,
          };
          isFirstVariant = false;

          csvFormatProducts.push(csvFormatVariant);
        });

        if (count === numProducts) {
          setData(csvFormatProducts);
          resolve('all data stored in csv');
        }
      });
    });

    promise.then(() => {
      setIsDownloadAvailable(true);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [order]);

  async function cancelOrder() {
    let url = `${process.env.REACT_APP_BACKEND_URL}/orders/${order.id}/cancel`;

    try {
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${auth.token}`,
        },
      });
      const res = await response.json();

      console.log(res);
      if (res.status === 'success') {
        history.push('/admin/orders');
        // TO DO: show success snackbar
      } else {
        history.push('/admin/orders');
        // TO DO: show error snackbar
      }
    } catch (err) {}
  }

  return (
    <>
      <Paper
        sx={{
          width: '700px',
          height: '295px',
          float: 'right',
          marginTop: '30px',
          marginBottom: '30px',
          boxShadow: '0px 0px 10px 2px rgb(222, 222, 222)',
        }}
        elevation={0}
      >
        <Box
          sx={{
            width: '335px',
            mt: '10px',
            ml: '10px',
            float: 'left',
          }}
        >
          <TextField
            id="outlined-basic"
            label="Order ID"
            variant="outlined"
            size="small"
            sx={{ width: '100%', mb: '10px' }}
            inputProps={{ readOnly: true }}
            value={order.id}
          />
          <TextField
            id="outlined-basic"
            label="Store"
            variant="outlined"
            size="small"
            sx={{ width: '100%' }}
            inputProps={{ readOnly: true }}
            value={order.store}
          />
          <TextField
            id="outlined-basic"
            label="Notes"
            variant="outlined"
            multiline
            rows={7}
            size="small"
            sx={{ width: '100%', mt: '10px' }}
            inputProps={{ readOnly: true }}
            value={order.note}
          />
        </Box>
        <Box
          sx={{
            width: '335px',
            mt: '10px',
            ml: '10px',
            float: 'left',
          }}
        >
          <TextField
            id="outlined-basic"
            label="Email"
            variant="outlined"
            size="small"
            sx={{ width: '100%', mb: '10px' }}
            inputProps={{ readOnly: true }}
            value={order.userInformation.email}
          />
          <TextField
            id="outlined-basic"
            label="Date"
            variant="outlined"
            size="small"
            sx={{ width: '100%', mb: '10px' }}
            inputProps={{ readOnly: true }}
            value={order.date}
          />
          <TextField
            id="outlined-basic"
            label="Quantity"
            variant="outlined"
            size="small"
            sx={{ width: '100%', mb: '10px' }}
            inputProps={{ readOnly: true }}
            value={totalShopcartQuantity}
          />
          <TextField
            id="outlined-basic"
            label="Cost"
            variant="outlined"
            size="small"
            sx={{ width: '100%', mb: '10px' }}
            inputProps={{ readOnly: true }}
            value={`${totalShopcartValue} €`}
          />
          {isDownloadAvailable ? (
            <CSVLink data={data} headers={headers} filename={'order.csv'}>
              <Button
                variant="contained"
                startIcon={<DownloadIcon />}
                sx={{
                  width: '100%',
                  backgroundColor: '#424242',
                  borderColor: '#424242',
                  '&:hover': {
                    backgroundColor: '#9E9E9E',
                    borderColor: '#9E9E9E',
                  },
                }}
                size="small"
              >
                DOWNLOAD STOCK CSV
              </Button>
            </CSVLink>
          ) : (
            <Button
              variant="contained"
              startIcon={<DownloadIcon />}
              disabled
              sx={{
                width: '100%',
                backgroundColor: '#424242',
                borderColor: '#424242',
                '&:hover': {
                  backgroundColor: '#9E9E9E',
                  borderColor: '#9E9E9E',
                },
              }}
              size="small"
            >
              DOWNLOAD STOCK CSV
            </Button>
          )}
          <Button
            variant="contained"
            startIcon={<DeleteIcon />}
            sx={{
              width: '100%',
              mt: '15px',
              backgroundColor: '#424242',
              borderColor: '#424242',
              '&:hover': {
                backgroundColor: '#9E9E9E',
                borderColor: '#9E9E9E',
              },
            }}
            size="small"
            onClick={() => setIsDialogOpen(true)}
          >
            CANCEL ORDER
          </Button>
        </Box>
      </Paper>
      <Dialog
        isDialogOpen={isDialogOpen}
        setIsDialogOpen={setIsDialogOpen}
        title="Order cancelation"
        description="Are you sure you want to cancel this order?"
        buttonLabel="Update"
        action={cancelOrder}
      />
    </>
  );
};
