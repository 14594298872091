import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import { Category } from '../../../../models/Category';
import { GETrequest } from '../../../../common/functions/requests';
import { InputComponent } from '../../common/structure/InputComponent';
import { InputV2 } from '../../common/structure/InputV2';
import { FunctionComponent, useEffect, useState } from 'react';
import { TagsComponentV2 } from './TagsComponentV2';

interface UpdateProductDetailsProps {
  register: any;
  watch: (name: string) => any;
  setValue: (name: string, value: any) => any;
}

export const UpdateProductDetails: FunctionComponent<
  UpdateProductDetailsProps
> = ({ register, watch, setValue }) => {
  const [categories, setCategories] = useState<Category[]>();
  useEffect(() => {
    GETrequest('/stocks/categories').then((categoriesDto: Category[]) => {
      setCategories(categoriesDto);
    });
  }, []);

  const category = watch('category');

  const productTags = watch('tags');

  function createTag(tag: string) {
    if (!productTags.includes(tag)) {
      setValue('tags', [...productTags, tag]);
    }
  }

  function deleteTag(tagToDelete: string) {
    const filteredTags = productTags.filter(
      (tag: string) => tag !== tagToDelete
    );
    setValue('tags', filteredTags);
  }

  return (
    <InputComponent hasTitle={true} title="Details" sx={{ mt: '20px' }}>
      <FormControl
        fullWidth
        sx={{ marginLeft: '15px', marginTop: '15px', width: '560px' }}
      >
        <InputLabel size="small" id="category-select-label">
          Category
        </InputLabel>
        <Select
          labelId="category-select-label"
          name="category"
          id="category-select"
          label="Category"
          value={category || ''}
          size="small"
          {...register('category')}
        >
          {categories?.map((categ) => (
            <MenuItem key={categ.name} value={categ.name}>
              {categ.name}
            </MenuItem>
          ))}
          <MenuItem key="undefined" value={''}>
            None
          </MenuItem>
        </Select>
      </FormControl>
      <TagsComponentV2
        label="Tags"
        values={productTags || []}
        addValue={createTag}
        removeValue={deleteTag}
      />
      <InputV2
        name="description"
        label="Description"
        register={register}
        isMultiline
      />
      <InputV2
        name="materials"
        label="Materials"
        register={register}
        isMultiline
      />
      <InputV2
        name="productInfo"
        label="Information for clients"
        register={register}
      />
    </InputComponent>
  );
};
