import { createContext } from 'react';

export const Authcontext = createContext({
  isLoggedIn: false,
  userId: null,
  token: null,
  email: '',
  login: (uid, token, email) => {},
  logout: () => {},
});
