interface PageTitleProps {
  title: string;
}

export const PageTitle = ({ title }: PageTitleProps) => {
  return (
    <h1
      style={{
        marginTop: '18px',
        marginBottom: '4px',
        fontFamily: 'Roboto',
        fontWeight: '700',
        fontSize: '27px',
        color: '#424242',
      }}
    >
      {title}
    </h1>
  );
};
