import { configureStore } from '@reduxjs/toolkit';
import shopcartReducer from './shopcart.ts';
import priceVisibilityReducer from './priceVisibility.js';
import userInformationReducer from './userInformation.ts';
import snackbarReducer from './snackbar.ts';

export const store = configureStore({
  reducer: {
    shopcart: shopcartReducer,
    priceVisiblitiy: priceVisibilityReducer,
    userInformation: userInformationReducer,
    snackbar: snackbarReducer,
  },
});
