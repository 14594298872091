import { FunctionComponent } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { useHistory } from 'react-router-dom';

interface OrdersTableProps {
  orders: any[];
}

export const OrdersTable: FunctionComponent<OrdersTableProps> = ({
  orders,
}) => {
  const history = useHistory();

  const columns: any[] = [
    { field: 'id', headerName: 'ID', width: 300 },
    {
      field: 'date',
      headerName: 'Date',
      width: 300,
    },
    { field: 'quantity', headerName: 'Quantity', width: 300 },
    { field: 'cost', headerName: 'Cost', width: 300 },
  ];

  return (
    <div style={{ marginTop: '20px', height: '570px', width: '100%' }}>
      <DataGrid
        rows={orders}
        columns={columns}
        onRowClick={({ row }) => history.push(`/user/orders/${row.id}`)}
      />
    </div>
  );
};
