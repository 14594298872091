import { Box } from '@mui/material';
import { FunctionComponent, ReactNode } from 'react';

interface InputColumnProps {
  sx?: any;
  children?: ReactNode;
}

export const InputColumn: FunctionComponent<InputColumnProps> = ({
  sx,
  children,
}) => {
  return (
    <Box
      sx={{
        width: '590px',
        float: 'left',
        ...sx,
      }}
    >
      {children}
    </Box>
  );
};
