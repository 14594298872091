import { Box, TextField } from '@mui/material';
import { FunctionComponent } from 'react';

interface AddressComponentProps {
  name: string;
  label: string;
  changeHandler: any;
  defaultValue?: string;
  sx?: any;
}

export const AddressComponent: FunctionComponent<AddressComponentProps> = ({
  name,
  label,
  changeHandler,
  defaultValue,
  sx,
}) => {
  return (
    <Box
      sx={{
        ...sx,
        width: '565px',
        height: '130px',
        float: 'left',
        backgroundColor: 'white',
        overflow: 'auto',
        marginBottom: '20px',
        borderRadius: '4px',
        boxShadow: '0px 0px 10px 2px rgb(222, 222, 222)',
      }}
    >
      <Box className="company_details_div_1">
        <p>{label}</p>
      </Box>
      <TextField
        label="Address"
        variant="outlined"
        name={name}
        onChange={changeHandler}
        defaultValue={defaultValue}
        size="small"
        sx={{ width: '525px', marginLeft: '20px', marginTop: '20px' }}
      />
    </Box>
  );
};
