import { Box, Typography } from '@mui/material';
import { FunctionComponent } from 'react';
import { Checkout } from './components/Checkout';
import { useSelector } from 'react-redux';
import { ShopcartProductQuantitySelector } from '../common/components/ShopcartProductQuantitySelector';

export const ShopcartPageV2: FunctionComponent = () => {
  const shopcartProducts = useSelector((state: any) => state.shopcart.cart);

  const hasShopcartProducts = shopcartProducts.length !== 0;

  return (
    <Box sx={{ width: '1200px', margin: 'auto', paddingTop: '20px' }}>
      {!hasShopcartProducts && (
        <Typography variant="h5" textAlign="center" mt="20px">
          No product in cart
        </Typography>
      )}
      {shopcartProducts.map((shopcartProduct: any) => (
        <ShopcartProductQuantitySelector
          key={shopcartProduct.id}
          shopcartProduct={shopcartProduct}
        />
      ))}
      {hasShopcartProducts && <Checkout />}
    </Box>
  );
};
