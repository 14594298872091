import { InputComponent } from '../../common/structure/InputComponent';
import { InputV2 } from '../../common/structure/InputV2';
import { FunctionComponent } from 'react';

interface UpdateGeneralInformationProps {
  register: any;
}

export const UpdateGeneralInformation: FunctionComponent<
  UpdateGeneralInformationProps
> = ({ register }) => {
  return (
    <InputComponent hasTitle={true} title="General Information">
      <InputV2 name="title" label="Title" register={register} />
      <InputV2 name="color" label="Color" register={register} />
      <InputV2 name="id" label="Sku" register={register} isDisabled />
    </InputComponent>
  );
};
