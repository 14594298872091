import Box from '@mui/material/Box';
import { ReactNode } from 'react';
import { grey } from '@mui/material/colors';

export const PageV2 = (props: { children?: ReactNode }) => {
  return (
    <Box
      style={{
        height: '100vh',
        marginLeft: '230px',
        backgroundColor: grey[50],
      }}
    >
      <Box
        style={{
          width: '1200px',
          margin: 'auto',
          paddingTop: '0px',
          height: '100%',
        }}
      >
        {props.children}
      </Box>
    </Box>
  );
};
