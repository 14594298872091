import * as React from 'react';
import MuiAlert, { AlertProps } from '@mui/material/Alert';
import { Snackbar as MuiSnackbar } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { SnackbarType, clearSnackbar } from '../../redux2/snackbar';

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
  props,
  ref
) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export function Snackbar() {
  const dispatch = useDispatch();

  const { isOpen, label, snackbarType } = useSelector(
    (state: any) => state.snackbar
  );

  const handleClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    dispatch(clearSnackbar());
  };

  return (
    <MuiSnackbar open={isOpen} autoHideDuration={2000} onClose={handleClose}>
      <div>
        {isOpen && (
          <Alert
            onClose={handleClose}
            severity={
              snackbarType === SnackbarType.Success ? 'success' : 'error'
            }
            sx={{ minWidth: '200px' }}
          >
            {label}
          </Alert>
        )}
      </div>
    </MuiSnackbar>
  );
}
