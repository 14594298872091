import React, { useState } from 'react';
import { Box } from '@mui/material';
import GridComponent2 from './GridComponent2';
import ControlPointIcon from '@mui/icons-material/ControlPoint';

export const AddVariantComponent = (props) => {
  const [variant, setVariant] = useState({
    size: '',
    stock: '',
    price: '',
    pvp: '',
    barcode: '',
  });

  const changeHandler = (e) => {
    setVariant({ ...variant, [e.target.name]: e.target.value });
  };

  const changeHandlerNumber = (e) => {
    if (!isNaN(e.target.value)) {
      setVariant({ ...variant, [e.target.name]: Number(e.target.value) });
    }
  };

  const addVariant = () => {
    // check for errors
    const isRepeated = props.variants.some(
      (existingVariant) => existingVariant.size === variant.size
    );

    if (isRepeated) {
      props.setIsRepeatedSizeWarningActive(true);
      props.setIsUndefinedSizeWarningActive(false);
      props.setIsUndefinedStockWarningActive(false);
      props.setIsUndefinedPriceWarningActive(false);
      props.setIsUndefinedPVPWarningActive(false);
    } else if (!variant.size) {
      props.setIsUndefinedSizeWarningActive(true);
      props.setIsRepeatedSizeWarningActive(false);
      props.setIsUndefinedStockWarningActive(false);
      props.setIsUndefinedPriceWarningActive(false);
      props.setIsUndefinedPVPWarningActive(false);
    } else if (!variant.stock) {
      console.log('error - stock is undefined');
      props.setIsUndefinedStockWarningActive(true);
      props.setIsRepeatedSizeWarningActive(false);
      props.setIsUndefinedSizeWarningActive(false);
      props.setIsUndefinedPriceWarningActive(false);
      props.setIsUndefinedPVPWarningActive(false);
    } else if (!variant.price) {
      props.setIsUndefinedPriceWarningActive(true);
      props.setIsRepeatedSizeWarningActive(false);
      props.setIsUndefinedSizeWarningActive(false);
      props.setIsUndefinedStockWarningActive(false);
      props.setIsUndefinedPVPWarningActive(false);
    } else if (!variant.pvp) {
      props.setIsUndefinedPVPWarningActive(true);
      props.setIsRepeatedSizeWarningActive(false);
      props.setIsUndefinedSizeWarningActive(false);
      props.setIsUndefinedStockWarningActive(false);
      props.setIsUndefinedPriceWarningActive(false);
    } else {
      props.setValue('variants', [...props.variants, variant]);

      setVariant({
        size: '',
        stock: '',
        price: '',
        pvp: '',
        barcode: '',
      });
      props.setIsRepeatedSizeWarningActive(false);
      props.setIsUndefinedSizeWarningActive(false);
      props.setIsUndefinedStockWarningActive(false);
      props.setIsUndefinedPriceWarningActive(false);
      props.setIsUndefinedPVPWarningActive(false);
    }
  };

  return (
    <Box
      sx={{
        width: '580px',
        height: '40px',
        overflow: 'hidden',
        ml: '10px',
      }}
    >
      <GridComponent2
        name="size"
        placeholder="Size"
        onChange={changeHandler}
        value={variant?.size}
      />
      <GridComponent2
        isStock={true}
        name="stock"
        placeholder="Stock"
        onChange={changeHandlerNumber}
        value={variant?.stock}
      />
      <GridComponent2
        name="price"
        placeholder="Price"
        onChange={changeHandlerNumber}
        value={variant?.price}
      />
      <GridComponent2
        name="pvp"
        placeholder="PVP"
        onChange={changeHandlerNumber}
        value={variant?.pvp}
      />
      <GridComponent2
        isBarcode={true}
        name="barcode"
        placeholder="Barcode"
        onChange={changeHandlerNumber}
        value={variant?.barcode}
      />
      <ControlPointIcon
        sx={{ marginLeft: '15px', marginTop: '8px', color: 'green' }}
        className="remove_variant_icon"
        onClick={addVariant}
      />
    </Box>
  );
};
