import * as React from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { LandingPage } from './landingPage/LandingPage';
import { CategoriesAndTagsPage } from './categories/CategoriesAndTagsPage';
import { PageV2 } from '../common/structure/PageV2';
import { PageHeaderV2 } from '../common/structure/PageHeaderV2';

function TabPanel(props: any) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <Typography component={'div'}>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index: any) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export default function BasicTabs() {
  const [value, setValue] = React.useState(0);

  const handleChange = (event: any, newValue: any) => {
    setValue(newValue);
  };

  return (
    <Box>
      <Tabs
        value={value}
        onChange={handleChange}
        aria-label="basic tabs example"
        TabIndicatorProps={{ style: { backgroundColor: '#424242' } }}
      >
        <Tab
          sx={{
            fontFamily: 'Roboto',
            fontSize: '16px',
            color: '#9E9E9E',
            '&.Mui-selected': {
              color: '#424242',
            },
          }}
          label="Categories & Tags"
          {...a11yProps(0)}
        />
        <Tab
          sx={{
            fontFamily: 'Roboto',
            fontSize: '16px',
            color: '#9E9E9E',
            '&.Mui-selected': {
              color: '#424242',
            },
          }}
          label="Landing page"
          {...a11yProps(1)}
        />
      </Tabs>
      <TabPanel value={value} index={0}>
        {<CategoriesAndTagsPage />}
      </TabPanel>
      <TabPanel value={value} index={1}>
        {<LandingPage />}
      </TabPanel>
    </Box>
  );
}

export const StoreSettingsPage = () => {
  return (
    <PageV2>
      <PageHeaderV2 title="Settings" />
      <BasicTabs />
    </PageV2>
  );
};
