import { FunctionComponent } from 'react';
import { useSelector } from 'react-redux';

interface ReadOnlyQuantitySelectorProps {
  size: string;
  stock: number;
  price: number;
  pvp: number;
  quantity?: number;
}

export const ReadOnlyQuantitySelector: FunctionComponent<
  ReadOnlyQuantitySelectorProps
> = ({ size, price, pvp, quantity }) => {
  const isPriceVisible = useSelector(
    (state: any) => state.priceVisiblitiy.value
  );

  return (
    <div
      style={{
        width: '65px',
        height: '185px',
        float: 'left',
        marginLeft: '5px',
        overflow: 'hidden',
      }}
    >
      <div
        style={{
          width: '95%',
          height: '20px',
          border: '1px solid #E5E5E5',
          overflow: 'hidden',
          backgroundColor: '#E5E5E5',
          borderRadius: '3px',
        }}
      >
        <p
          style={{
            margin: '0',
            marginTop: '2px',
            fontSize: '13px',
            textAlign: 'center',
            fontFamily: 'Source Code Pro',
            fontWeight: '500',
          }}
        >
          {size}
        </p>
      </div>
      <div
        style={{
          width: '95%',
          height: '25px',
          border: '1px solid #E5E5E5',
          marginTop: '10px',
          borderRadius: '3px',
        }}
      >
        <>
          <p
            style={{
              margin: '0',
              width: '100%',
              marginTop: '4px',
              fontSize: '13px',
              textAlign: 'center',
              fontFamily: 'Source Code Pro',
              fontWeight: '500',
            }}
          >
            {quantity}
          </p>
        </>
      </div>
      {isPriceVisible && (
        <div>
          <p
            style={{
              height: '20px',
              marginTop: '5px',
              marginBottom: '0',
              fontSize: '12px',
              textAlign: 'center',
              fontFamily: 'Source Code Pro',
              fontWeight: '300',
            }}
          >
            {Number(price).toFixed(2)}€
          </p>
          <p
            style={{
              height: '20px',
              marginTop: '0px',
              marginBottom: '0',
              fontSize: '12px',
              textAlign: 'center',
              fontFamily: 'Source Code Pro',
              fontWeight: '300',
            }}
          >
            {Number(pvp).toFixed(2)}€
          </p>
        </div>
      )}
    </div>
  );
};
