import { Box } from '@mui/material';
import { AddVariantComponent } from './AddVariantComponent';
import { VariantComponent } from './VariantComponent';
import { TableLegend } from './TableLegend';
import { Variant } from '../../../../models/Product';
import { FunctionComponent, useState } from 'react';
import { InputComponent } from '../../common/structure/InputComponent';

type VariantsComponentProps = {
  variants: Variant[];
  setVariants?: React.Dispatch<React.SetStateAction<Variant[]>>;
  setValue?: (name: string, name2: string) => any;
};

export const VariantsComponent: FunctionComponent<VariantsComponentProps> = ({
  variants,
  setVariants,
  setValue,
}) => {
  const [isRepeatedSizeWarningActive, setIsRepeatedSizeWarningActive] =
    useState(false);

  const [isUndefinedSizeWarningActive, setIsUndefinedSizeWarningActive] =
    useState(false);

  const [isUndefinedStockWarningActive, setIsUndefinedStockWarningActive] =
    useState(false);

  const [isUndefinedPriceWarningActive, setIsUndefinedPriceWarningActive] =
    useState(false);

  const [isUndefinedPVPWarningActive, setIsUndefinedPVPWarningActive] =
    useState(false);

  return (
    <InputComponent
      hasTitle={true}
      title="Variants"
      sx={{ mt: '20px', float: 'right' }}
    >
      <Box
        sx={{
          width: '100%',
          mt: '15px',
          float: 'left',
          borderRadius: '5px',
        }}
      >
        {isRepeatedSizeWarningActive && (
          <p style={{ fontSize: '13px', marginLeft: '25px', color: 'red' }}>
            Variant size already exists
          </p>
        )}
        {isUndefinedSizeWarningActive && (
          <p style={{ fontSize: '13px', marginLeft: '25px', color: 'red' }}>
            Variant size is not defined
          </p>
        )}
        {isUndefinedStockWarningActive && (
          <p style={{ fontSize: '13px', marginLeft: '25px', color: 'red' }}>
            Variant stock is not defined
          </p>
        )}
        {isUndefinedPriceWarningActive && (
          <p style={{ fontSize: '13px', marginLeft: '25px', color: 'red' }}>
            Variant price is not defined
          </p>
        )}
        {isUndefinedPVPWarningActive && (
          <p style={{ fontSize: '13px', marginLeft: '25px', color: 'red' }}>
            Variant PVP is not defined
          </p>
        )}
        <TableLegend />
        {variants.map((variant: Variant) => {
          return (
            <VariantComponent
              key={variant.size}
              size={variant.size}
              defaultStock={variant.stock}
              defaultPrice={variant.price}
              defaultPvp={variant.pvp}
              defaultBarcode={variant.barcode}
              variants={variants}
              setValue={setValue}
            />
          );
        })}
        <AddVariantComponent
          size={'XL'}
          setValue={setValue}
          variants={variants}
          setIsRepeatedSizeWarningActive={setIsRepeatedSizeWarningActive}
          setIsUndefinedSizeWarningActive={setIsUndefinedSizeWarningActive}
          setIsUndefinedStockWarningActive={setIsUndefinedStockWarningActive}
          setIsUndefinedPriceWarningActive={setIsUndefinedPriceWarningActive}
          setIsUndefinedPVPWarningActive={setIsUndefinedPVPWarningActive}
        />
      </Box>
    </InputComponent>
  );
};
