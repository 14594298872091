import '../products/styles/AdminEditProduct.css';
import React from 'react';
import { Page } from '../common/structure/Page';
import { PageHeader } from '../common/structure/PageHeader';
import { PageTitle } from '../common/structure/PageTitle';
import { Box, Tab, Tabs, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { DownloadOmodaStock } from './Components/DownloadOmodaStock';
import { ProductsStockPage } from './Components/ProductsStockPage';
import { UpdateProductsStock } from './Components/UpdateProductsStock';
import { PageSubTitle } from '../common/structure/PageSubTitle';

function TabPanel(props: any) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography component={'div'}>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index: any) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export const StockPage = (props: any) => {
  const [value, setValue] = React.useState(0);

  const handleChange = (event: any, newValue: any) => {
    setValue(newValue);
  };

  return (
    <Page>
      <PageHeader>
        <PageTitle title="Stock" />
        <PageSubTitle title="Inventory and stock control" />
      </PageHeader>
      <Box sx={{ width: '100%' }}>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="basic tabs example"
          TabIndicatorProps={{ style: { backgroundColor: '#424242' } }}
        >
          <Tab
            sx={{
              fontFamily: 'Roboto',
              fontSize: '16px',
              color: '#9E9E9E',
              '&.Mui-selected': {
                color: '#424242',
              },
            }}
            label="Products Stock"
            {...a11yProps(0)}
          />
          <Tab
            sx={{
              fontFamily: 'Roboto',
              fontSize: '16px',
              color: '#9E9E9E',
              '&.Mui-selected': {
                color: '#424242',
              },
            }}
            label="Update Stock"
            {...a11yProps(1)}
            //indicatorColor="#424242"
          />
          <Tab
            sx={{
              fontFamily: 'Roboto',
              fontSize: '16px',
              color: '#9E9E9E',
              '&.Mui-selected': {
                color: '#424242',
              },
            }}
            label="Omoda CSV"
            {...a11yProps(2)}
          />
        </Tabs>
        <TabPanel value={value} index={0}>
          {<ProductsStockPage />}
        </TabPanel>
        <TabPanel value={value} index={1}>
          {<UpdateProductsStock />}
        </TabPanel>
        <TabPanel value={value} index={2}>
          {<DownloadOmodaStock />}
        </TabPanel>
      </Box>
    </Page>
  );
};
