import { FunctionComponent } from 'react';
import { ImageComponentV2 } from './ImageComponentV2';
import { InputComponent } from '../../common/structure/InputComponent';

export const MediaComponentV2: FunctionComponent = () => {
  return (
    <InputComponent
      hasTitle={true}
      title="Media"
      sx={{ mt: '20px', float: 'right' }}
    >
      <ImageComponentV2 />
      <ImageComponentV2 />
      <ImageComponentV2 />
      <ImageComponentV2 />
    </InputComponent>
  );
};
