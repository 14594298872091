import './style.css';
import React, { useContext } from 'react';
import {
  FiActivity,
  FiUser,
  FiUsers,
  FiFileText,
  FiGrid,
  FiLogOut,
  FiDatabase,
} from 'react-icons/fi';
import StoreIcon from '@mui/icons-material/Store';
import { FaShopify } from 'react-icons/fa';
import { NavLink } from 'react-router-dom';
import logo from '../../../../images/logo.png';
import { Authcontext } from '../../../../auth/context/auth-context';
import { Box } from '@mui/material';

export const Navbar = () => {
  const auth = useContext(Authcontext);

  function logout() {
    auth.logout();
  }

  return (
    <div className="admin-header">
      <div className="admin-header-link-image">
        <img src={logo} alt="logo" />
      </div>
      <NavLink
        to="/admin/dashboard/overview"
        activeClassName="admin_selected"
        style={{ textDecoration: 'none' }}
      >
        <div className="admin-header-link">
          <FiActivity className="admin-link-icon2" />
          <p className="admin-link">Dashboard</p>
        </div>
      </NavLink>
      <NavLink
        to="/admin/shopify"
        activeClassName="admin_selected"
        style={{ textDecoration: 'none' }}
      >
        <div className="admin-header-link">
          <FaShopify className="admin-link-icon" />
          <p className="admin-link">Shopify</p>
        </div>
      </NavLink>
      <NavLink
        to="/admin/catalog"
        activeClassName="admin_selected"
        style={{ textDecoration: 'none' }}
      >
        <div className="admin-header-link">
          <FiGrid className="admin-link-icon" />
          <p className="admin-link">Products</p>
        </div>
      </NavLink>
      <NavLink
        to="/admin/updateStocks"
        activeClassName="admin_selected"
        style={{ textDecoration: 'none',
       }}
      >
        <div className="admin-header-link">
          <FiDatabase className="admin-link-icon" />
          <p className="admin-link">Stock</p>
        </div>
      </NavLink>
      <NavLink
        to="/admin/orders"
        activeClassName="admin_selected"
        style={{ textDecoration: 'none' }}
      >
        <div className="admin-header-link">
          <FiFileText className="admin-link-icon" />
          <p className="admin-link">Orders</p>
        </div>
      </NavLink>
      <NavLink
        to="/admin/users"
        activeClassName="admin_selected"
        style={{ textDecoration: 'none' }}
      >
        <div className="admin-header-link">
          <FiUsers className="admin-link-icon" />
          <p className="admin-link">Users</p>
        </div>
      </NavLink>
      <Box
        sx={{
          width: '100%',
          height: '225px',
          position: 'absolute',
          bottom: '0',
          borderTop: '1px solid #EBEFF2',
        }}
      >
        <p className="admin_name">Admin</p>
        <p className="admin_email">B2B</p>
        <NavLink to="/admin/store" activeClassName="admin_selected">
          <div className="admin-header-link">
            <StoreIcon className="admin-link-icon" />
            <p className="admin-link" style={{ marginLeft: '14px' }}>
              Settings
            </p>
          </div>
        </NavLink>
        <NavLink to="/admin/account" activeClassName="admin_selected">
          <div className="admin-header-link">
            <FiUser className="admin-link-icon" />
            <p className="admin-link">Account</p>
          </div>
        </NavLink>
        <div className="admin-header-link">
          <FiLogOut className="admin-link-icon" />
          <p
            onClick={logout}
            className="admin-logout admin-link"
            style={{ textDecoration: 'none' }}
          >
            Logout
          </p>
        </div>
        <p className="admin_pertodesign">
          Powered by <span>StockWised</span>
        </p>
      </Box>
    </div>
  );
};
