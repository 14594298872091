import './styles/Components.css';
import { Box } from '@mui/material';
import GridComponent2 from './GridComponent2';
import { FunctionComponent } from 'react';
import ClearIcon from '@mui/icons-material/Clear';

interface VariantComponentProps {
  size: string;
  variants: any;
  setValue?: (name: string, name2: any) => any;
  defaultStock: number;
  defaultPrice: number;
  defaultPvp: number;
  defaultBarcode?: number | null;
}

export const VariantComponent: FunctionComponent<VariantComponentProps> = ({
  size,
  variants,
  setValue,
  defaultStock,
  defaultPrice,
  defaultPvp,
  defaultBarcode,
}) => {
  const removeVariant = (size: string) => {
    const newVariants = variants.filter(
      (variant: any) => variant.size !== size
    );
    if (setValue) setValue('variants', newVariants);
  };

  const changeHandlerNumber = (e: any) => {
    const updatedVariants = variants.map((variant: any) => {
      if (variant.size === size) {
        let updatedVariant = {
          ...variant,
          [e.target.name]: Number(e.target.value),
        };
        return updatedVariant;
      }
      return variant;
    });
    console.log(updatedVariants);
    if (setValue) setValue('variants', updatedVariants);
  };

  return (
    <Box
      sx={{
        width: '580px',
        height: '40px',
        margin: 'auto',
        overflow: 'hidden',
        ml: '10px',
      }}
    >
      <GridComponent2
        isSize={true}
        name={'size'}
        placeholder="Size"
        defaultValue={size && size}
      />
      <GridComponent2
        isStock={true}
        name={'stock'}
        placeholder="0"
        onChange={changeHandlerNumber}
        defaultValue={defaultStock && defaultStock}
      />
      <GridComponent2
        name={'price'}
        placeholder="0.00"
        onChange={changeHandlerNumber}
        defaultValue={defaultPrice && defaultPrice}
      />
      <GridComponent2
        name={'pvp'}
        placeholder="0.00"
        onChange={changeHandlerNumber}
        defaultValue={defaultPvp && defaultPvp}
      />
      <GridComponent2
        isBarcode={true}
        name={'barcode'}
        placeholder="barcode"
        onChange={changeHandlerNumber}
        defaultValue={defaultBarcode && defaultBarcode}
      />
      <ClearIcon
        sx={{ marginLeft: '15px', marginTop: '8px', color: 'red' }}
        className="remove_variant_icon"
        onClick={() => removeVariant(size)}
      />
    </Box>
  );
};
