import { useParams } from 'react-router-dom';
import { useState, useEffect, useContext } from 'react';
import { Authcontext } from '../../../auth/context/auth-context';
import { Loading } from '../../user/common/Loading';
import { Page } from '../common/structure/Page';
import { OrderProductQuantitySelector } from './components/OrderProductQuantitySelector';
import { OrderInformation } from './components/OrderInformation';

export const OrderDetailPage = () => {
  const auth = useContext(Authcontext);
  const orderId = useParams<any>().orderId;

  const [isLoading, setIsLoading] = useState(true);

  const [loadedData, setLoadedData] = useState<any>();

  let url = process.env.REACT_APP_BACKEND_URL + '/orders/' + orderId;

  useEffect(() => {
    const sendRequest = async () => {
      try {
        const response = await fetch(url, {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${auth.token}`,
          },
        });
        const order = await response.json();
        setLoadedData(order);
        setIsLoading(false);
      } catch (err) {}
    };
    sendRequest();
  }, [auth.token, url]);

  return (
    <Page>
      {isLoading ? (
        <Loading />
      ) : (
        <>
          {loadedData &&
            loadedData.shopcartProducts.map((shopcartProduct: any) => (
              <OrderProductQuantitySelector
                key={shopcartProduct.id}
                shopcartProduct={shopcartProduct}
              />
            ))}
          {loadedData && <OrderInformation order={loadedData} />}
        </>
      )}
    </Page>
  );
};
