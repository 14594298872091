import './Navbar.css';
import { useContext } from 'react';
import { Authcontext } from '../../../../auth/context/auth-context';
import { NavLink } from 'react-router-dom';
import { FiShoppingCart, FiUser, FiEye, FiEyeOff } from 'react-icons/fi';
import logo from '../../../../images/logo.png';
import Banner from '../../../../common/formElements/Banner';
import { useSelector, useDispatch } from 'react-redux';
import { changePriceVisibility } from '../../../../redux2/priceVisibility';
import { styled } from '@mui/material/styles';
import Badge from '@mui/material/Badge';
import { getTotalShopcartQuantity } from '../../shopcartV2/functions/getTotalShopcartQuantity';

const StyledBadge = styled(Badge)(({ theme }) => ({
  '& .MuiBadge-badge': {
    right: -5,
    top: 27,
    border: `2px solid ${theme.palette.background.paper}`,
    padding: '0 4px',
  },
}));

export const Navbar = () => {
  const cart = useSelector((state: any) => state.shopcart.cart);
  const totalShopcartQuantity = getTotalShopcartQuantity(cart);

  const isPriceVisible = useSelector(
    (state: any) => state.priceVisiblitiy.value
  );
  const dispatch = useDispatch();

  const auth = useContext(Authcontext);

  function logout() {
    auth.logout();
  }

  return (
    <div
      style={{
        height: '110px',
        position: 'sticky',
        top: '0',
        backgroundColor: 'white',
        zIndex: 99,
      }}
    >
      <div className="user-header-size">
        <div className="user-div_link_produtos_logo">
          <NavLink to="/user/catalog">
            <img src={logo} alt="logo" />
          </NavLink>
        </div>
        <div className="user-div_link_produtos">
          <NavLink to="/user/catalog" activeClassName="selected">
            <p className="user-link">Catalog</p>
          </NavLink>
        </div>
        <div className="user-div_link_encomendas">
          <NavLink to="/user/orders" activeClassName="selected">
            <p className="user-link">Orders</p>
          </NavLink>
        </div>
        <div
          className="user-div_link_encomendas"
          style={{ width: '155px', height: '100%', float: 'left' }}
        >
          <a
            className="div-footer-about-a"
            target="_tab"
            href="https://trademixpt-my.sharepoint.com/:f:/g/personal/aqa_giga_trademixpt_onmicrosoft_com/Eo_tZBmBdr1InBkwaomvstQBj0Z6erxGcFUBTCQqmAfefA?e=Oc02i8"
          >
            <p className="user-link">Photo Catalog</p>
          </a>
        </div>
        <div className="user-div_link_sair">
          <p onClick={logout} className="user-logout user-link">
            Logout
          </p>
        </div>
        <div
          style={{
            width: '60px',
            height: '100%',
            float: 'right',
          }}
        >
          <NavLink to="/user/account" activeClassName="selected">
            <FiUser
              id="navbar_shopcart_item"
              className="user-div_link_conta-iconAccount"
              style={{ color: '#999999' }}
            />
          </NavLink>
        </div>
        <div
          style={{
            width: '60px',
            height: '100%',
            float: 'right',
          }}
          onClick={() => dispatch(changePriceVisibility())}
        >
          {isPriceVisible ? (
            <FiEyeOff
              className="user-div_link_conta-iconAccount"
              style={{ color: '#999999' }}
            />
          ) : (
            <FiEye
              className="user-div_link_conta-iconAccount"
              style={{ color: '#999999' }}
            />
          )}
        </div>
        <div
          style={{
            width: '70px',
            height: '100%',
            float: 'right',
          }}
        >
          <NavLink to="/user/catalog/shopcart" activeClassName="selected">
            <StyledBadge badgeContent={totalShopcartQuantity} color="primary">
              <FiShoppingCart
                id="navbar_shopcart_item"
                className="div_link_cart-icon"
                style={{
                  color: '#999999',
                  fontSize: '20px',
                  marginTop: '28px',
                  marginLeft: '0px',
                }}
              />
            </StyledBadge>
          </NavLink>
        </div>
      </div>
      <Banner />
    </div>
  );
};

/*
<FiShoppingCart
              id="navbar_shopcart_item"
              className="div_link_cart-icon"
              style={{ color: '#999999' }}
            />
 */
