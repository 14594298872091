import { PayloadAction, createSlice } from '@reduxjs/toolkit';

export enum SnackbarType {
  Success = 'success',
  Error = 'error',
}

export interface SnackbarSliceState {
  isOpen: boolean;
  label: string;
  snackbarType?: SnackbarType;
}

const initialState: SnackbarSliceState = {
  isOpen: false,
  label: '',
  snackbarType: undefined,
};

export const snackbarSlice = createSlice({
  name: 'snackbar',
  initialState,
  reducers: {
    showSuccess: (state, action: PayloadAction<string>) => {
      state.isOpen = true;
      state.label = action.payload;
      state.snackbarType = SnackbarType.Success;
    },
    showError: (state, action: PayloadAction<string>) => {
      state.isOpen = true;
      state.label = action.payload;
      state.snackbarType = SnackbarType.Error;
    },
    clearSnackbar: (state) => {
      state.isOpen = false;
      state.label = '';
      state.snackbarType = undefined;
    },
  },
});

export const { showSuccess, showError, clearSnackbar } = snackbarSlice.actions;

export default snackbarSlice.reducer;
