import { DashboardComponent } from './components/DashboardComponent';
import { useState, useEffect, useContext } from 'react';
import { Authcontext } from '../../../auth/context/auth-context';
import { PageHeader } from '../common/structure/PageHeader';
import { Page } from '../common/structure/Page';
import { PageTitle } from '../common/structure/PageTitle';
import Loading2 from '../common/loading/Loading2';
import products from '../../../permanentImages/products.png';
import clients from '../../../permanentImages/clients.png';
import orders from '../../../permanentImages/orders.png';
import sales from '../../../permanentImages/sales.png';

export const DashboardOverviewPage = () => {
  const auth = useContext(Authcontext);
  const [dashboardData, setDashboardData] = useState<any>();
  useEffect(() => {
    const sendRequest = async () => {
      try {
        const response = await fetch(
          process.env.REACT_APP_BACKEND_URL + '/dashboard/overview',
          {
            method: 'GET',
            headers: {
              Authorization: `Bearer ${auth.token}`,
            },
          }
        );

        const responseData = await response.json();

        setDashboardData(responseData);
      } catch (err) {}
    };
    sendRequest();
  }, [auth.token]);

  if (!dashboardData) {
  }

  return (
    <>
      {!dashboardData && <Loading2 />}
      <Page>
        <PageHeader>
          <PageTitle title="Overview" />
        </PageHeader>
        {dashboardData && (
          <>
            <DashboardComponent
              label="Products"
              value={dashboardData.numberOfProducts}
              icon={products}
            />
            <DashboardComponent
              label="Clients"
              value={dashboardData.numberOfUsers}
              icon={clients}
            />
            <DashboardComponent
              label="Orders"
              value={dashboardData.numberOfOrders}
              icon={orders}
            />
            <DashboardComponent
              label="Sales"
              value={`${dashboardData.sales.toFixed(2)} €`}
              icon={sales}
            />
          </>
        )}
      </Page>
    </>
  );
};
