import React from 'react';
import './styles/ImportProducts.css';
import { Page } from '../common/structure/Page';
import { PageHeader } from '../common/structure/PageHeader';
import { PageTitle } from '../common/structure/PageTitle';
import { Box, Tab, Tabs, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { ImportImagesPage } from './componentsV2/ImportImagesPage';
import { ImportCsvPage } from './componentsV2/ImportCsvPage';

function TabPanel(props: any) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography component={'div'}>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index: any) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export const ImportProductsPage = () => {
  const [value, setValue] = React.useState(0);

  const handleChange = (event: any, newValue: any) => {
    setValue(newValue);
  };

  return (
    <Page>
      <>
        <PageHeader>
          <PageTitle title="Import products" />
        </PageHeader>

        <Box sx={{ width: '100%' }}>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example"
            indicatorColor="primary"
            TabIndicatorProps={{ style: { backgroundColor: '#424242' } }}
          >
            <Tab
              sx={{
                fontFamily: 'Source Code Pro',
                fontSize: '16px',
                color: '#9E9E9E',
                '&.Mui-selected': {
                  color: '#424242',
                },
              }}
              label="Import images"
              {...a11yProps(0)}
            />
            <Tab
              sx={{
                fontFamily: 'Source Code Pro',
                fontSize: '16px',
                color: '#9E9E9E',
                '&.Mui-selected': {
                  color: '#424242',
                  borderColor: '#424242',
                },
              }}
              label="Import products"
              {...a11yProps(1)}
            />
          </Tabs>
          <TabPanel value={value} index={0}>
            {<ImportImagesPage />}
          </TabPanel>
          <TabPanel value={value} index={1}>
            {<ImportCsvPage />}
          </TabPanel>
        </Box>
      </>
    </Page>
  );
};

/*

        let image1 =
          'https://storage.googleapis.com/react-hosting-73546.appspot.com/images/' +
          array[j].image1 +
          '.jpg';
        let image2 =
          'https://storage.googleapis.com/react-hosting-73546.appspot.com/images/' +
          array[j].image2 +
          '.jpg';
        let image3 =
          'https://storage.googleapis.com/react-hosting-73546.appspot.com/images/' +
          array[j].image3 +
          '.jpg';
        let image4 =
          'https://storage.googleapis.com/react-hosting-73546.appspot.com/images/' +
          array[j].image4 +
          '.jpg';

*/

/*

  const post = async (userBody) => {
    try {
      const response = await fetch(
        process.env.REACT_APP_BACKEND_URL + '/products',
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${auth.token}`,
          },
          body: userBody,
        }
      );
      const responseData = await response.json();
      let responseArray = responses;
      responseArray.push(responseData.message);
      setResponses(responseArray);
    } catch (err) {}
  };


*/
