export async function GETrequest(path: string) {
  try {
    const response = await fetch(process.env.REACT_APP_BACKEND_URL + path, {
      method: 'GET',
    });

    const data = await response.json();
    return data.reverse();
  } catch (err) {
    return;
  }
}

export async function GETauthrequest(auth: any, path: string) {
  try {
    const response = await fetch(process.env.REACT_APP_BACKEND_URL + path, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${auth.token}`,
      },
    });

    const data = await response.json();
    return data;
  } catch (err) {
    return;
  }
}

export async function GETauthResourceRequest(
  auth: any,
  path: string,
  resource: string
) {
  try {
    const response = await fetch(
      process.env.REACT_APP_BACKEND_URL + path + '/' + resource,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${auth.token}`,
        },
      }
    );

    const data = await response.json();
    return data;
  } catch (err) {
    return;
  }
}
