import { FunctionComponent } from 'react';
import Button from '@mui/material/Button';
import DialogActions from '@mui/material/DialogActions';
import { Dialog as MuiDialog } from '@mui/material';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

interface DialogProps {
  isDialogOpen: boolean;
  setIsDialogOpen: any;
  title: string;
  description: string;
  buttonLabel: string;
  action: any;
}

export const Dialog: FunctionComponent<DialogProps> = ({
  isDialogOpen,
  setIsDialogOpen,
  title,
  description,
  buttonLabel,
  action,
}) => {
  const handleClose = () => {
    setIsDialogOpen(false);
  };

  return (
    <div>
      <MuiDialog open={isDialogOpen} onClose={handleClose}>
        <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {description}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={() => action()} autoFocus>
            {buttonLabel}
          </Button>
        </DialogActions>
      </MuiDialog>
    </div>
  );
};
