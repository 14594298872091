import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { getTotalQuantity } from '../features/user/common/functions/getTotalQuantity';

export interface shopcartSliceState {
  cart: any[];
}

const initialState: shopcartSliceState = {
  cart: [],
};

export const shopcartSlice = createSlice({
  name: 'shopcart',
  initialState,
  reducers: {
    addProductToShopcart: (state, action: PayloadAction<any>) => {
      const foundProduct = state.cart.find(
        (product) => product.id === action.payload.id
      );

      const totalQuantity = getTotalQuantity(action.payload.variants);
      if (foundProduct && totalQuantity === 0) {
        const updatedCart = state.cart.filter(
          (product) => product.id !== action.payload.id
        );
        state.cart = updatedCart;
      } else if (foundProduct) {
        const updatedCart = state.cart.map((product) => {
          if (product.id === foundProduct.id) {
            return action.payload;
          }
          return product;
        });
        state.cart = updatedCart;
      } else {
        state.cart.push(action.payload);
      }
    },

    removeProductFromShopcart: (state, action: PayloadAction<string>) => {
      const productId = action.payload;
      const updatedCart = state.cart.filter(
        (product) => product.id !== productId
      );
      state.cart = updatedCart;
    },

    cleanShopcart: (state) => {
      state.cart = [];
    },
  },
});

export const {
  addProductToShopcart,
  removeProductFromShopcart,
  cleanShopcart,
} = shopcartSlice.actions;

export default shopcartSlice.reducer;
