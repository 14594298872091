import { FunctionComponent, useContext } from 'react';
import { PageV2 } from '../common/structure/PageV2';
import { PageHeaderV2 } from '../common/structure/PageHeaderV2';
import { InputColumn } from '../common/structure/InputColumn';
import { CreateUserAuthentication } from './components/CreateUserAuthentication';
import { CreateUserInformation } from './components/CreateUserInformation';
import { CreateUserAddresses } from './components/CreateUserAddresses';
import { PageSubmit } from '../common/structure/PageSubmit';
import { useForm } from 'react-hook-form';
import {
  CreateUserDto,
  getCreateUserDtoDefaultValues,
} from '../../../dtos/CreateUserDto';
import { Box } from '@mui/material';
import { useHistory } from 'react-router-dom';
import { Authcontext } from '../../../auth/context/auth-context';
import { useDispatch } from 'react-redux';
import { showError, showSuccess } from '../../../redux2/snackbar';

export const CreateUserPageV2: FunctionComponent = () => {
  const history = useHistory();
  const auth = useContext(Authcontext);
  const dispatch = useDispatch();

  const { register, handleSubmit } = useForm<CreateUserDto>({
    defaultValues: getCreateUserDtoDefaultValues(),
  });

  async function onSubmit(createUserDto: CreateUserDto) {
    try {
      const response = await fetch(
        process.env.REACT_APP_BACKEND_URL + '/users',
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${auth.token}`,
          },
          body: JSON.stringify(createUserDto),
        }
      );

      if (response.ok) {
        dispatch(showSuccess('User created'));
      } else {
        dispatch(showError('User creation failed'));
      }
      history.push('/admin/users');
    } catch (err) {
      dispatch(showError('User creation failed'));
      history.push('/admin/users');
    }
  }

  return (
    <PageV2>
      <PageHeaderV2 title="Create user" />
      <Box component="form" onSubmit={handleSubmit(onSubmit)}>
        <PageSubmit title="Create" />
        <InputColumn>
          <CreateUserAuthentication register={register} />
          <CreateUserInformation register={register} />
        </InputColumn>
        <InputColumn sx={{ ml: '20px' }}>
          <CreateUserAddresses register={register} />
        </InputColumn>
      </Box>
    </PageV2>
  );
};
