import { useContext, useState } from 'react';
import { Authcontext } from '../../auth/context/auth-context';
import { apiRequest } from '../common/apiRequest';
import { Method } from '../common/Method';
import { RecoverPasswordDto } from '../../dtos/RecoverPasswordDto';

export const useRecoverPassword = () => {
  const auth = useContext(Authcontext);

  const [data, setData] = useState<any>();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const recoverPassword = async (recoverPasswordDto: RecoverPasswordDto) => {
    setIsLoading(true);
    const response = await apiRequest(
      `/auth/recover-password`,
      Method.PUT,
      auth,
      JSON.stringify(recoverPasswordDto)
    );

    setData(response);
    setIsLoading(false);
    return response;
  };

  return { recoverPassword, data, isLoading };
};
