import { Box, Button, Paper, TextField } from '@mui/material';
import { FunctionComponent, useContext, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getTotalShopcartQuantity } from '../functions/getTotalShopcartQuantity';
import { getTotalShopcartValue } from '../functions/getTotalShopcartValue';
import { cleanShopcart } from '../../../../redux2/shopcart';
import { Authcontext } from '../../../../auth/context/auth-context';
import { StoreSelector } from './StoreSelector';
import { clearEmptySizes } from '../functions/clearEmptySizes';
import { useHistory } from 'react-router-dom';
import { Status } from '../../../../models/Status';

export const Checkout: FunctionComponent = () => {
  const auth = useContext(Authcontext);
  const history = useHistory();

  const dispatch = useDispatch();

  const [vimagro, setVimagro] = useState(false);
  const [note, setNote] = useState('');
  const [store, setStore] = useState('');

  const shopcartProducts = useSelector((state: any) => state.shopcart.cart);
  const userInformation = useSelector((state: any) => state.userInformation);

  const totalShopcartQuantity = getTotalShopcartQuantity(shopcartProducts);
  const totalShopcartValue = getTotalShopcartValue(shopcartProducts);

  async function createOrder() {
    const clearedShopcartProducts = clearEmptySizes(shopcartProducts);

    const order = {
      isAdminOrder: false,
      userInformation,
      note,
      store,
      shopcartProducts: clearedShopcartProducts,
      quantity: totalShopcartQuantity,
      cost: totalShopcartValue,
    };

    dispatch(cleanShopcart());

    console.log(order);

    try {
      const response = await fetch(
        process.env.REACT_APP_BACKEND_URL + '/orders',
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${auth.token}`,
          },
          body: JSON.stringify(order),
        }
      );

      const responseData = await response.json();
      if (responseData.status === Status.Success) {
        history.push('/user/catalog/confirmation');
      } else {
        history.push('/user/catalog/stockError');
      }
    } catch (err) {}
  }

  return (
    <Paper
      sx={{
        width: '700px',
        height: '205px',
        float: 'right',
        marginTop: '30px',
        marginBottom: '30px',
        boxShadow: '0px 0px 10px 2px rgb(222, 222, 222)',
      }}
      elevation={0}
    >
      <Box
        sx={{
          width: '335px',
          mt: '10px',
          ml: '10px',
          float: 'left',
        }}
      >
        <StoreSelector
          stores={userInformation.shippingAddresses}
          setStore={setStore}
        />
        <TextField
          id="outlined-basic"
          label="Notes"
          variant="outlined"
          multiline
          onChange={(e) => setNote(e.target.value)}
          rows={5}
          size="small"
          sx={{ width: '100%', mt: '10px' }}
        />
      </Box>
      <Box
        sx={{
          width: '335px',
          mt: '10px',
          ml: '10px',
          float: 'left',
        }}
      >
        <div
          style={{
            width: '100%',
            height: '38px',
            border: '1px solid #CCCCCC',
            borderRadius: '4px',
            marginBottom: '10px',
          }}
        >
          <p
            style={{
              fontSize: '13px',
              fontFamily: 'Source Code Pro',
              fontWeight: '500',
              marginTop: '10px',
              marginLeft: '15px',
            }}
          >
            Quantity: {totalShopcartQuantity}
          </p>
        </div>
        <div
          style={{
            width: '100%',
            height: '38px',
            border: '1px solid #CCCCCC',
            borderRadius: '4px',
            marginBottom: '10px',
          }}
        >
          <p
            style={{
              fontSize: '13px',
              fontFamily: 'Source Code Pro',
              fontWeight: '500',
              marginTop: '10px',
              marginLeft: '15px',
            }}
          >
            Cost: {totalShopcartValue}€
          </p>
        </div>
        <Button
          variant="contained"
          className="button_save"
          style={{ width: '100%' }}
          disabled={vimagro ? false : true}
          onClick={createOrder}
        >
          ORDER
        </Button>
        <Box>
          {vimagro ? (
            <div
              style={{
                width: '10px',
                height: '10px',
                border: '1px solid #c2c2c2',
                float: 'left',
                marginTop: '10px',
                marginRight: '10px',
                cursor: 'pointer',
                backgroundColor: '#CCCCCC',
              }}
              onClick={() => setVimagro(!vimagro)}
            ></div>
          ) : (
            <div
              style={{
                width: '10px',
                height: '10px',
                border: '1px solid #c2c2c2',
                float: 'left',
                marginTop: '10px',
                marginRight: '10px',
                cursor: 'pointer',
              }}
              onClick={() => setVimagro(!vimagro)}
            ></div>
          )}
          <Box
            sx={{
              width: '90%',
              marginLeft: '30px',
            }}
          >
            <p
              style={{
                fontSize: '10px',
                fontFamily: 'Source Code Pro',
                fontWeight: '500',
                textAlign: 'justify',
              }}
            >
              All transactions are made according to the Vimagro terms and
              conditions, I have read and accepted the terms and conditions
            </p>
          </Box>
        </Box>
      </Box>
    </Paper>
  );
};
