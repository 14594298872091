import { useState, useEffect, useContext } from 'react';
import { Authcontext } from '../../../auth/context/auth-context';
import { Box } from '@mui/material';
import { OrdersTable } from './componentsV2/OrdersTable';
import { Loading } from '../common/Loading';

export const OrdersListPage = () => {
  const auth = useContext(Authcontext);

  const [isLoading, setIsLoading] = useState(true);

  const [orders, serOrders] = useState<any>();
  useEffect(() => {
    const url =
      process.env.REACT_APP_BACKEND_URL + '/orders/email/' + auth.email;
    const sendRequest = async () => {
      try {
        const response = await fetch(url, {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${auth.token}`,
          },
        });
        const responseData = await response.json();
        serOrders(responseData.reverse());
        setIsLoading(false);
      } catch (err) {}
    };
    sendRequest();
  }, [auth.token, auth.email]);

  return (
    <>
      {isLoading ? (
        <Loading />
      ) : (
        <Box sx={{ width: '1200px', margin: 'auto', paddingTop: '20px' }}>
          <OrdersTable orders={orders} />
        </Box>
      )}
    </>
  );
};
