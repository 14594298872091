import Box from '@mui/material/Box';
import { ReactElement } from 'react';

export const PageContent = (props: { children: ReactElement }) => {
  return (
    <Box
      style={{
        width: '100%',
      }}
    >
      {props.children}
    </Box>
  );
};
