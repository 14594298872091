const Banner = (props) => {
  return (
    <>
      <div
        style={{
          width: '100%',
          height: '27px',
          paddingTop: '0px',
          borderTop: '0.5px solid #CCCCCC',
          borderBottom: '0.5px solid #CCCCCC',
        }}
      >
        <p
          style={{
            display: 'inline-block',
            width: '100%',
            textAlign: 'center',
            color: 'black',
            fontSize: '12px',
            fontWeight: '400',
            fontFamily: 'Source Code Pro',
            marginTop: '6px',
          }}
        >
          FREE SHIPPING 6 PAIRS AND MORE. 10€ TRANSPORT COST - LESS THAN 6
        </p>
      </div>
    </>
  );
};

export default Banner;
