export interface CreateUserDto {
  id: string;
  name: string;
  email: string;
  password: string;
  shippingAddress1?: string;
  shippingAddress2?: string;
  shippingAddress3?: string;
  discount?: number;
  phoneNumber?: string;
  billingAddress?: string;
}

export function getCreateUserDtoDefaultValues() {
  return {
    id: '',
    name: '',
    email: '',
    password: '',
  };
}
