import defaultImage from '../../../../images/default_image.png';
import {
  ChangeEvent,
  FunctionComponent,
  useEffect,
  useRef,
  useState,
} from 'react';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';

interface ImageComponentV2Props {
  product: any;
  imagePosition: number;
}

export const UpdateImageComponentV2: FunctionComponent<
  ImageComponentV2Props
> = ({ product, imagePosition }) => {
  const [image, setImage] = useState<any>(null);

  const fileInputRef = useRef<HTMLInputElement>(null);

  const handleClick = (): void => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleFileChange = (event: ChangeEvent<HTMLInputElement>): void => {
    if (event.target.files && event.target.files[0]) {
      setImage(event.target.files[0]);
    } else {
      setImage(null);
    }
  };

  const [previewUrl, setPreviewUrl] = useState<any>();
  const [isPreviewUrl, setIsPreviewUrl] = useState<boolean>(false);
  const [previewImage, setPreviewImage] = useState<any>('');

  useEffect(() => {
    if (!image) {
      return;
    }
    const fileReader = new FileReader();
    fileReader.onload = () => {
      setPreviewUrl(fileReader.result);
      setPreviewImage(fileReader.result);
    };
    setIsPreviewUrl(true);
    fileReader.readAsDataURL(image);
  }, [image, previewUrl, setPreviewUrl]);

  useEffect(() => {
    const img = product.media[imagePosition]
      ? product.media[imagePosition].url
      : isPreviewUrl
      ? previewUrl
      : defaultImage;
    setPreviewImage(img);
  }, [imagePosition, isPreviewUrl, previewUrl, product.media]);

  function deleteImage(event: any) {
    event.stopPropagation();
    setPreviewImage(defaultImage);
  }

  return (
    <div
      onClick={handleClick}
      style={{
        width: '129px',
        height: '129px',
        backgroundImage: `url("${previewImage}")`,
        backgroundSize: 'contain',
        backgroundRepeat: 'no-repeat',
        marginTop: '15px',
        marginLeft: '15px',
        float: 'left',
        borderRadius: '4px',
        cursor: 'pointer',
      }}
    >
      <input
        id="image"
        ref={fileInputRef}
        style={{ display: 'none' }}
        type="file"
        onChange={handleFileChange}
      ></input>
      <HighlightOffIcon
        className="cursor_on_hover"
        sx={{ float: 'right', mt: '2px', mr: '2px' }}
        onClick={deleteImage}
      />
    </div>
  );
};
