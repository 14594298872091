import { FunctionComponent, useContext, useEffect, useState } from 'react';
import { InputColumn } from '../../common/structure/InputColumn';
import { InputComponent } from '../../common/structure/InputComponent';
import { TagsComponent } from '../../products/components/TagsComponent';
import { Authcontext } from '../../../../auth/context/auth-context';
import { useDispatch } from 'react-redux';
import { GETrequest } from '../../../../common/functions/requests';
import { showError, showSuccess } from '../../../../redux2/snackbar';
import { Category } from '../../../../models/Category';

export const CategoriesAndTagsPage: FunctionComponent = () => {
  const auth = useContext(Authcontext);
  const dispatch = useDispatch();

  // categories
  const [categories, setCategories] = useState<Category[]>([]);

  // get categories
  useEffect(() => {
    GETrequest('/stocks/categories').then((categories: Category[]) => {
      console.log(categories);
      setCategories(categories);
    });
  }, []);

  // create category
  async function createCategory(category: string) {
    try {
      const response = await fetch(
        process.env.REACT_APP_BACKEND_URL + '/stocks/categories/create',
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${auth.token}`,
          },
          body: JSON.stringify({ name: category }),
        }
      );
      let createdCategory = await response.json();
      if (response.ok) {
        setCategories([...categories, createdCategory]);
        dispatch(showSuccess('Category created'));
      } else {
        dispatch(showError('Category creation failed'));
      }
    } catch (err) {
      dispatch(showError('Category creation failed'));
    }
  }

  // delete category
  async function deleteCategory(categoryId: string) {
    try {
      const response = await fetch(
        process.env.REACT_APP_BACKEND_URL + `/stocks/categories/${categoryId}`,
        {
          method: 'DELETE',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${auth.token}`,
          },
        }
      );
      if (response.ok) {
        let filteredArray = categories.filter(
          (value) => value._id !== categoryId
        );
        setCategories(filteredArray);
        dispatch(showSuccess('Category delete'));
      } else {
        dispatch(showError('Category deletion failed'));
      }
    } catch (err) {
      dispatch(showError('Category deletion failed'));
    }
  }

  // tags
  const [tags, setTags] = useState<any>([]);

  // get tags
  useEffect(() => {
    GETrequest('/stocks/tags').then((tags: Category[]) => {
      console.log(tags);

      setTags(tags);
    });
  }, []);

  // create category
  async function createTag(tag: string) {
    try {
      const response = await fetch(
        process.env.REACT_APP_BACKEND_URL + '/stocks/tags/create',
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${auth.token}`,
          },
          body: JSON.stringify({ name: tag }),
        }
      );
      let createdTag = await response.json();
      console.log(createdTag);
      if (response.ok) {
        setTags([...tags, createdTag]);
        dispatch(showSuccess('Tag created'));
      } else {
        dispatch(showError('Tag creation failed'));
      }
    } catch (err) {
      dispatch(showError('Tag creation failed'));
    }
  }

  // delete category
  async function deleteTag(tagId: string) {
    try {
      const response = await fetch(
        process.env.REACT_APP_BACKEND_URL + `/stocks/tags/${tagId}`,
        {
          method: 'DELETE',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${auth.token}`,
          },
        }
      );
      if (response.ok) {
        let filteredArray = tags.filter((value: any) => value._id !== tagId);
        setTags(filteredArray);
        dispatch(showSuccess('Tag delete'));
      } else {
        dispatch(showError('Tag deletion failed'));
      }
    } catch (err) {
      dispatch(showError('Tag deletion failed'));
    }
  }

  return (
    <>
      <InputColumn>
        <InputComponent hasTitle={false} sx={{ mt: '20px' }}>
          <TagsComponent
            label="Categories"
            values={categories}
            addValue={createCategory}
            removeValue={deleteCategory}
          />
        </InputComponent>
      </InputColumn>
      <InputColumn sx={{ ml: '20px' }}>
        <InputComponent hasTitle={false} sx={{ mt: '20px' }}>
          <TagsComponent
            label="Tags"
            values={tags}
            addValue={createTag}
            removeValue={deleteTag}
          />
        </InputComponent>
      </InputColumn>
    </>
  );
};
