import { UseFormRegister } from 'react-hook-form';
import { InputComponent } from '../../common/structure/InputComponent';
import { InputV2 } from '../../common/structure/InputV2';
import { CreateUserDto } from '../../../../dtos/CreateUserDto';
import { FunctionComponent } from 'react';

interface CreateUserInformationProps {
  register: UseFormRegister<CreateUserDto>;
}

export const CreateUserInformation: FunctionComponent<
  CreateUserInformationProps
> = ({ register }) => {
  const changeHandler = () => {};
  return (
    <InputComponent hasTitle={true} title="Information" sx={{ mt: '20px' }}>
      <InputV2
        name="name"
        label="Customer name"
        changeHandler={changeHandler}
        register={register as any}
      />
      <InputV2
        name="id"
        label="Customer ID"
        changeHandler={changeHandler}
        register={register as any}
      />
      <InputV2
        name="phoneNumber"
        label="Phone number"
        changeHandler={changeHandler}
        register={register as any}
      />
      <InputV2
        name="discount"
        label="Discount (1-100 , no discount if left empty)"
        changeHandler={changeHandler}
        register={register as any}
      />
    </InputComponent>
  );
};
