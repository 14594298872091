import { Box, Button, TextField } from '@mui/material';
import { useHistory, useParams } from 'react-router-dom';
import { useCheckRecoveryPasswordCode } from '../repositories/auth/useCheckRecoveryPasswordCode';
import logo from '../images/logo.png';
import { useForm } from 'react-hook-form';
import { useRecoverPassword } from '../repositories/auth/useRecoverPassword';
import { showError, showSuccess } from '../redux2/snackbar';
import { useDispatch } from 'react-redux';

export const PasswordRecoveryPage = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const code = useParams<any>().code;

  const { data: isCodeValid, isLoading } = useCheckRecoveryPasswordCode(code);

  const { recoverPassword } = useRecoverPassword();

  const { register, handleSubmit } = useForm({
    defaultValues: { newPassword: '', code },
  });

  async function onSubmit(data: any) {
    const response = await recoverPassword(data);
    if (response.success) {
      dispatch(showSuccess('Password changed'));
      history.push('/');
    } else {
      dispatch(showError('Error : Password not changed'));
      history.push('/');
    }
  }

  if (isLoading) {
    return (
      <Box
        sx={{
          width: '400px',
          height: '500px',
          margin: 'auto',
          marginTop: '20px',
        }}
      >
        <p>Loading...</p>
      </Box>
    );
  } else if (!isLoading && isCodeValid) {
    return (
      <Box
        sx={{
          width: '400px',
          height: '500px',
          margin: 'auto',
          marginTop: '20px',
        }}
      >
        <div className="div-login-img">
          <img src={logo} alt="logo"></img>
        </div>
        <h2
          style={{
            fontFamily: 'Source Code Pro',
            textAlign: 'center',
            marginBottom: '20px',
            fontSize: '20px',
            marginTop: '100px',
          }}
        >
          Define new password
        </h2>
        <Box
          textAlign="center"
          component="form"
          onSubmit={handleSubmit(onSubmit)}
        >
          <TextField
            label="New passord"
            variant="outlined"
            size="small"
            sx={{ width: '250px', marginTop: '20px' }}
            {...register('newPassword')}
          />

          <Button
            className="button"
            variant="contained"
            type="submit"
            sx={{
              margin: 'auto',
              marginTop: '30px',
              backgroundColor: '#424242',
              borderColor: '#424242',
              '&:hover': {
                backgroundColor: '#9E9E9E',
                borderColor: '#9E9E9E',
              },
            }}
          >
            Recover Password
          </Button>
        </Box>
      </Box>
    );
  } else {
  }
  return (
    <Box
      sx={{
        width: '400px',
        height: '500px',
        margin: 'auto',
        marginTop: '20px',
      }}
    >
      <h1 style={{ textAlign: 'center' }}>Code not valid</h1>
    </Box>
  );
};
