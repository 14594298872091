import './Informacao.css';
import { Link } from 'react-router-dom';
import { Box, Button } from '@mui/material';
import { useSelector } from 'react-redux';
import { ProductDto } from '../../../../dtos/ProductDto';
import { FunctionComponent } from 'react';

interface ProductInformationProps {
  product: ProductDto;
  minPrice: number;
  maxPrice: number;
}

export const ProductInformation: FunctionComponent<ProductInformationProps> = ({
  product,
  minPrice,
  maxPrice,
}) => {
  const isPriceVisible = useSelector<any>(
    (state) => state.priceVisiblitiy.value
  );

  return (
    <Box
      style={{
        width: '37%',
        height: '550px',
        float: 'left',
      }}
    >
      <Box sx={{ width: '100%', height: '70px' }}>
        <p className="info-category">{product.category}</p>
        <p className="b">
          {product.title} <span style={{ color: 'grey' }}>{product.color}</span>
        </p>
      </Box>
      {isPriceVisible === true ? (
        <p
          style={{
            fontFamily: 'Source Code Pro',
            fontWeight: 300,
            fontSize: '20.5px',
            marginTop: '0px',
          }}
        >
          {minPrice}€ - {maxPrice}€
        </p>
      ) : (
        <Box height={30}></Box>
      )}
      <Box sx={{ width: '100%', height: '230px' }}>
        <p className="ll">Description</p>
        <div className="informacao-line"></div>
        <p className="info-description-data">{product.description}</p>
      </Box>
      <Box sx={{ width: '100%', height: '115px' }}>
        <p className="ll">Materials</p>
        <div className="informacao-line"></div>
        <p className="info-materials-data">{product.materials}</p>
      </Box>
      {product.productInfo !== '' ? (
        <p
          className="restock-info"
          style={{
            color: 'red',
            fontSize: '14px',
            fontWeight: '600',
            marginTop: '2px',
            marginBottom: '7px',
          }}
        >
          {product.productInfo}
        </p>
      ) : (
        <p className="restock-info">
          For stock information send email to {process.env.REACT_APP_LINK_EMAIL}
        </p>
      )}
      <Link
        to={`/user/catalog/quantity/${product.title}/product/${product.id}`}
        style={{ textDecoration: ' none' }}
      >
        <Button
          variant="contained"
          className="button_save"
          sx={{ width: '100%' }}
        >
          CHOOSE SIZES AND QUANTITIES
        </Button>
      </Link>
    </Box>
  );
};
