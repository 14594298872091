import { Box, Paper, Typography } from '@mui/material';
import { FunctionComponent, useContext, useEffect, useState } from 'react';
import defaultProductImage from '../../../../images/default_image.png';
import { ProductRepository } from '../../../../repositories/ProductRepository';
import { Authcontext } from '../../../../auth/context/auth-context';
import { getTotalCost } from '../../../user/common/functions/getTotalCost';
import { getTotalQuantity } from '../../../user/common/functions/getTotalQuantity';
import { Loading } from '../../../user/common/Loading';
import { ReadOnlyQuantitySelector } from '../../../user/common/components/ReadOnlyQuantitySelector';

interface OrderProductQuantitySelectorProps {
  shopcartProduct: any;
}

export const OrderProductQuantitySelector: FunctionComponent<
  OrderProductQuantitySelectorProps
> = ({ shopcartProduct }) => {
  const auth = useContext(Authcontext);

  const productRepository = new ProductRepository();

  const [product, setProduct] = useState<any>();
  const [totalQuantity, setTotalQuantity] = useState<any>();
  const [totalCost, setTotalCost] = useState<any>();

  useEffect(() => {
    productRepository
      .getProductById(shopcartProduct.id, auth)
      .then((product) => {
        setProduct(product);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shopcartProduct.id]);

  let shopcartProductVariants: any = [];
  if (product) {
    for (let index = 0; index < shopcartProduct.variants.length; index++) {
      shopcartProductVariants.push({
        size: product.variants[index].size,
        stock: product.variants[index].stock,
        price: product.variants[index].price,
        pvp: product.variants[index].pvp,
        quantity: shopcartProduct.variants[index].quantity,
      });
    }
  }

  useEffect(() => {
    setTotalCost(getTotalCost(shopcartProduct.variants));
    setTotalQuantity(getTotalQuantity(shopcartProduct.variants));
  }, [shopcartProduct]);

  return (
    <>
      {!product ? (
        <Loading />
      ) : (
        <Paper
          sx={{
            width: '1150px',
            height: '180px',
            mt: '30px',
            boxShadow: '0px 0px 10px 2px rgb(222, 222, 222)',
          }}
          elevation={0}
        >
          <Box sx={{ width: '180px', float: 'left' }}>
            <img
              src={product.media[0].url ?? defaultProductImage}
              alt="product"
              style={{
                width: '160px',
                height: '160px',
                marginTop: '10px',
                marginLeft: '10px',
                borderRadius: '3px',
              }}
            />
          </Box>
          <Box sx={{ width: '180px', float: 'left' }}>
            <Typography
              sx={{ mt: '10px', ml: '10px', color: 'grey', fontSize: '13px' }}
            >
              {product.id}
            </Typography>
            <Typography
              sx={{
                mt: '10px',
                ml: '10px',
                fontSize: '27px',
              }}
            >
              {product.title}
            </Typography>
            <Typography sx={{ ml: '10px' }}>{product.color}</Typography>
          </Box>
          <Box
            sx={{
              width: '780px',
              mt: '10px',
              float: 'left',
            }}
          >
            <Box
              sx={{
                width: '100%',
                height: '130px',
                float: 'left',
              }}
            >
              {shopcartProductVariants.map((variant: any) => (
                <ReadOnlyQuantitySelector
                  key={variant.size}
                  size={variant.size}
                  stock={variant.stock}
                  price={variant.price}
                  pvp={variant.pvp}
                  quantity={variant.quantity}
                />
              ))}
            </Box>
            <Box
              sx={{
                width: '100%',
                height: '35px',
                float: 'left',
              }}
            >
              <Box
                sx={{
                  width: '120px',
                  height: '30px',
                  float: 'right',
                }}
              >
                <Typography
                  sx={{
                    ml: '5px',
                    mt: '7px',
                    fontSize: '14px',
                    color: 'black',
                  }}
                >
                  Cost: {Number(totalCost).toFixed(2)}€
                </Typography>
              </Box>
              <Box
                sx={{
                  width: '120px',
                  height: '30px',
                  mr: '10px',
                  float: 'right',
                }}
              >
                <Typography
                  sx={{
                    ml: '5px',
                    mt: '7px',
                    fontSize: '14px',
                    color: 'black',
                  }}
                >
                  Quantity: {totalQuantity}
                </Typography>
              </Box>
            </Box>
          </Box>
        </Paper>
      )}
    </>
  );
};
