import { Box, useTheme } from '@mui/material';
import { ProductSyncStatus } from '../../../../models/ProductSyncStatus';

export const ProductStatus = ({ product }: any) => {
  const theme = useTheme();

  function getColor(productSyncStatus: ProductSyncStatus) {
    let color;
    switch (productSyncStatus) {
      case ProductSyncStatus.NotOnShopify:
        color = theme.palette.error.light;
        break;
      case ProductSyncStatus.StockMismatch || ProductSyncStatus.VariantMismatch:
        color = theme.palette.warning.light;
        break;
      default:
        color = theme.palette.success.light;
    }

    return color;
  }

  const color = getColor(product.status);

  return (
    <Box
      sx={{
        width: '370px',
        height: '80px',
        backgroundColor: color,
        borderRadius: '4px',
        boxShadow: '0px 0px 10px 2px rgb(222, 222, 222)',
        marginBottom: '20px',
        float: 'left',
        mr: '20px',
      }}
    >
      <img
        src={product.image}
        width="70px"
        height="70px"
        alt="product"
        style={{
          borderRadius: '5px',
          marginTop: '5px',
          marginLeft: '5px',
          float: 'left',
        }}
      ></img>
      <p
        style={{
          float: 'left',
          width: '70%',
          height: '22px',
          overflow: 'hidden',
          marginLeft: '10px',
          marginTop: '5px',
          marginBottom: '0',
          fontSize: '17px',
          fontFamily: 'Source Code Pro',
          fontWeight: '500',
        }}
      >
        {product.title} {product.color}
      </p>
      <p
        style={{
          float: 'left',
          width: '70%',
          marginLeft: '10px',
          marginTop: '2px',
          fontSize: '12px',
          fontFamily: 'Source Code Pro',
          fontWeight: '400',
          marginBottom: '0',
        }}
      >
        {product.productId}
      </p>
      <p
        style={{
          float: 'left',
          marginLeft: '10px',
          marginTop: '10px',
          fontSize: '13px',
          color: 'black',
          fontFamily: 'Source Code Pro',
          fontWeight: '400',
        }}
      >
        {product.status}
      </p>
    </Box>
  );
};
