import Box from '@mui/material/Box';
import { FunctionComponent } from 'react';

interface PageHeaderV2Props {
  title: string;
}

export const PageHeaderV2: FunctionComponent<PageHeaderV2Props> = ({
  title,
}) => {
  return (
    <Box
      sx={{
        width: '100%',
        height: '70px',
        overflow: 'hidden',
      }}
    >
      <h1
        style={{
          marginTop: '18px',
          marginBottom: '4px',
          fontFamily: 'Roboto',
          fontWeight: '700',
          fontSize: '27px',
          color: '#424242',
        }}
      >
        {title}
      </h1>
    </Box>
  );
};
