import { FunctionComponent } from 'react';
import { Box, Button } from '@mui/material';

interface ShopifyComponentProps {
  startSynchronizationCheck?: any;
  disableButton: boolean;
  icon?: any;
}

export const ShopifyComponent: FunctionComponent<ShopifyComponentProps> = ({
  startSynchronizationCheck,
  disableButton,
  icon,
}) => {
  return (
    <Box
      sx={{
        maxWidth: '1100px',
        minWidth: '250px',
        height: '200px',
        backgroundColor: 'white',
        borderRadius: '20px',
        marginRight: '20px',
        marginBottom: '20px',
        boxShadow: '0px 0px 10px 2px rgb(222, 222, 222)',
        position: 'relative',
        flexGrow: 1,
        display: 'flex',
      }}
    >
      <img
        alt="mandatory alt"
        style={{
          height: '45px',
          position: 'absolute',
          top: '40px',
          left: '50%',
          transform: 'translate(-50%)',
        }}
        src={icon}
      />

      <Button
        variant="contained"
        size="small"
        //disabled={/*disableButton*/ true}
        sx={{
          width: '100px',
          height: '25px',
          position: 'absolute',
          top: '95px',
          left: '50%',
          transform: 'translate(-50%)',
        }}
        onClick={() => startSynchronizationCheck()}
      >
        Run
      </Button>

      <p
        style={{
          fontFamily: 'Roboto',
          fontWeight: 400,
          fontSize: '15px',
          position: 'absolute',
          top: '120px',
          left: '50%',
          transform: 'translate(-50%)',
        }}
      >
        Check inventory
      </p>
    </Box>
  );
};
