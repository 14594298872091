import { useContext } from 'react';
import './styles/ImportUsers.css';
import { Page } from '../common/structure/Page';
import { PageHeader } from '../common/structure/PageHeader';
import { PageTitle } from '../common/structure/PageTitle';
import Papa from 'papaparse';
import { Box, Typography } from '@mui/material';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import { Authcontext } from '../../../auth/context/auth-context';
import { useDispatch } from 'react-redux';
import { showError, showSuccess } from '../../../redux2/snackbar';
import { useHistory } from 'react-router-dom';

interface User {
  id: string;
  name: string;
  email: string;
  password: string;
  discount: number;
  shippingAddresses: string[];
  phoneNumber?: string;
  billingAddress?: string;
}

interface ImportedUser {
  customerId: string;
  email: string;
  password: string;
  discount: string;
  company: string;
  phoneNumber: string;
  billingAddress: string;
  shippingAddress1: string;
  shippingAddress2: string;
  shippingAddress3: string;
}

export const ImportUsersPage = () => {
  const dispatch = useDispatch();
  const auth = useContext(Authcontext);
  const history = useHistory();

  const aggregateImportedUsers = (importedUsers: ImportedUser[]): User[] => {
    let users: User[] = [];

    importedUsers.forEach((importedUser: ImportedUser) => {
      let shippingAddresses = [];

      if (importedUser.shippingAddress1 !== '') {
        shippingAddresses.push(importedUser.shippingAddress1);
      }
      if (importedUser.shippingAddress2 !== '') {
        shippingAddresses.push(importedUser.shippingAddress2);
      }
      if (importedUser.shippingAddress3 !== '') {
        shippingAddresses.push(importedUser.shippingAddress3);
      }

      const discount = +importedUser.discount;

      const user: User = {
        id: importedUser.customerId,
        name: importedUser.company,
        email: importedUser.email,
        password: importedUser.password,
        discount,
        shippingAddresses,
        phoneNumber: importedUser.phoneNumber,
        billingAddress: importedUser.billingAddress,
      };

      users.push(user);
    });
    return users;
  };

  const handleCsvSubmit = (e: any) => {
    const file = e.target.files[0];
    if (file) {
      Papa.parse(file, {
        header: true,
        complete: async function (results: any) {
          const users: User[] = aggregateImportedUsers(results.data);

          try {
            const response = await fetch(
              process.env.REACT_APP_BACKEND_URL + '/users/import',
              {
                method: 'POST',
                headers: {
                  'Content-Type': 'application/json',
                  Authorization: `Bearer ${auth.token}`,
                },
                body: JSON.stringify(users),
              }
            );

            if (response.ok) {
              dispatch(showSuccess('Users imported'));
            } else {
              dispatch(showError('Users import failed'));
            }
            history.push('/admin/users');
          } catch (err) {
            dispatch(showError('Users import failed'));
            history.push('/admin/users');
          }
        },
      });
    }
  };

  return (
    <Page>
      <>
        <PageHeader>
          <PageTitle title="Import users" />
        </PageHeader>
        <Box height={130} mt={3}>
          <Typography
            sx={{
              fontFamily: 'Source Code Pro',
              fontSize: 16,
              fontWeight: 300,
            }}
          >
            - Download an example CSV file to understand the required format.
            You can get it here.
          </Typography>
          <Typography
            sx={{
              fontFamily: 'Source Code Pro',
              fontSize: 16,
              fontWeight: 300,
            }}
          >
            - Finally, import the updated CSV file.
          </Typography>
        </Box>

        <Box width="100%">
          <form>
            <input
              id={'csvFileInput'}
              type={'file'}
              style={{ display: 'none' }}
              accept={'.csv'}
              onChange={handleCsvSubmit}
            />
            <label htmlFor="csvFileInput">
              <div
                className="importProducts_div"
                style={{
                  margin: 'auto',
                  width: '500px',
                  height: '180px',
                  overflow: 'auto',
                  marginTop: '30px',
                  border: '2px dashed #1576d2',
                  borderRadius: '5px',
                }}
              >
                <div
                  style={{ margin: 'auto', width: '70px', marginTop: '30px' }}
                >
                  <UploadFileIcon
                    sx={{
                      color: '#1576d2',
                      fontSize: '70px',
                    }}
                  />
                </div>
                <p
                  style={{
                    fontSize: '18px',
                    textAlign: 'center',
                    fontFamily: 'Source Code Pro',
                  }}
                >
                  Select CSV file
                </p>
              </div>
            </label>
          </form>
        </Box>
      </>
    </Page>
  );
};
