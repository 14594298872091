import { FunctionComponent } from 'react';
import { Box } from '@mui/material';
import { useSelector } from 'react-redux';

interface QuantitySelectorProps {
  size: string;
  stock: number;
  price: number;
  pvp: number;
  updateQuantity: any;
  quantity?: number;
}

export const QuantitySelector: FunctionComponent<QuantitySelectorProps> = ({
  size,
  stock,
  price,
  pvp,
  quantity,
  updateQuantity,
}) => {
  const isPriceVisible = useSelector(
    (state: any) => state.priceVisiblitiy.value
  );

  function validateQuantity(value: any) {
    if (value < 0 || value > stock || isNaN(value)) {
      return false;
    }
    return true;
  }

  function handleChange(event: any) {
    const value = Number(event.target.value);
    const validValue = validateQuantity(value);
    if (validValue) {
      updateQuantity(size, value, price);
    }
  }

  return (
    <div
      style={{
        width: '65px',
        height: '185px',
        float: 'left',
        marginLeft: '5px',
        flex: '0 0 auto',
      }}
    >
      <div
        style={{
          width: '95%',
          height: '20px',
          border: '1px solid #E5E5E5',
          overflow: 'hidden',
          backgroundColor: '#E5E5E5',
          borderRadius: '3px 3px 0 0',
        }}
      >
        <p
          style={{
            margin: '0',
            marginTop: '2px',
            fontSize: '13px',
            textAlign: 'center',
            fontFamily: 'Source Code Pro',
            fontWeight: '500',
          }}
        >
          {size}
        </p>
      </div>
      <div
        style={{
          width: '95%',
          height: '20px',
          borderBottom: '1px solid #E5E5E5',
          borderRight: '1px solid #E5E5E5',
          borderLeft: '1px solid #E5E5E5',
          overflow: 'hidden',
          borderRadius: '0 0 3px 3px',
        }}
      >
        {stock === 0 ? (
          <p
            style={{
              margin: '0',
              marginTop: '2px',
              color: 'red',
              fontSize: '13px',
              textAlign: 'center',
              fontFamily: 'Source Code Pro',
              fontWeight: '500',
            }}
          >
            {stock}
          </p>
        ) : (
          <p
            style={{
              margin: '0',
              marginTop: '2px',
              color: 'green',
              fontSize: '13px',
              textAlign: 'center',
              fontFamily: 'Source Code Pro',
              fontWeight: '500',
            }}
          >
            {Number(stock)}
          </p>
        )}
      </div>
      <div
        style={{
          width: '95%',
          height: '25px',
          border: '1px solid #E5E5E5',
          marginTop: '10px',
          borderRadius: '3px',
        }}
      >
        {stock !== 0 && (
          <>
            <Box sx={{ float: 'left', mt: '2px', ml: '13px' }}>
              <input
                name={`size:${size}`}
                placeholder="0"
                pattern="[0-9]*"
                onChange={handleChange}
                type="number"
                min={0}
                max={stock}
                defaultValue={quantity}
                style={{
                  opacity: 1,
                  width: '100%',
                  border: '0',
                  outline: 'none',
                  fontSize: '13px',
                  fontFamily: 'Source Code Pro',
                  textAlign: 'center',
                  fontWeight: '500',
                }}
              ></input>
            </Box>
          </>
        )}
      </div>
      {isPriceVisible && (
        <div>
          <p
            style={{
              height: '20px',
              marginTop: '5px',
              marginBottom: '0',
              fontSize: '12px',
              textAlign: 'center',
              fontFamily: 'Source Code Pro',
              fontWeight: '300',
            }}
          >
            {Number(price).toFixed(2)}€
          </p>
          <p
            style={{
              height: '20px',
              marginTop: '0px',
              marginBottom: '0',
              fontSize: '12px',
              textAlign: 'center',
              fontFamily: 'Source Code Pro',
              fontWeight: '300',
            }}
          >
            {Number(pvp).toFixed(2)}€
          </p>
        </div>
      )}
    </div>
  );
};
