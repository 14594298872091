interface PageSubTitleProps {
  title: string;
}

export const PageSubTitle = ({ title }: PageSubTitleProps) => {
  return (
    <h2
      style={{
        marginTop: '0px',
        fontFamily: 'Roboto',
        fontWeight: '450',
        fontSize: '15px',
        color: '#9E9E9E',
      }}
    >
      {title}
    </h2>
  );
};
