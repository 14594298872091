import { Box, Button, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { GETrequest } from '../../../common/functions/requests';
import { useHistory } from 'react-router-dom';

export const LandingPage = () => {
  const history = useHistory();

  const [landingPageInfo, setLandingPageInfo] = useState<any>();
  useEffect(() => {
    GETrequest('/stocks/get/landingPage').then((info) => {
      setLandingPageInfo(info[0]);
    });
  }, []);

  if (!landingPageInfo) return null;

  return (
    <Box
      sx={{
        width: '800px',
        margin: 'auto',
        marginTop: '120px',
        textAlign: 'center',
      }}
    >
      <Typography variant="h2">{landingPageInfo.title}</Typography>
      <Typography variant="h4">{landingPageInfo.subtitle}</Typography>
      <Button
        variant="contained"
        sx={{
          marginTop: '20px',
        }}
        type="submit"
        onClick={() => history.push('/user/catalog')}
      >
        Catalog
      </Button>
    </Box>
  );
};
