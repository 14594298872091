import { FunctionComponent } from 'react';
import { InputComponent } from '../../common/structure/InputComponent';
import { InputV2 } from '../../common/structure/InputV2';
import { UseFormRegister } from 'react-hook-form';
import { CreateUserDto } from '../../../../dtos/CreateUserDto';

interface CreateUserAddressesProps {
  register: UseFormRegister<CreateUserDto>;
}

export const CreateUserAddresses: FunctionComponent<
  CreateUserAddressesProps
> = ({ register }) => {
  return (
    <InputComponent hasTitle={true} title="Addresses">
      <InputV2
        name="billingAddress"
        label="Billing address"
        register={register as any}
      />
      <InputV2
        name="shippingAddress1"
        label="Shipping address 1"
        register={register as any}
      />
      <InputV2
        name="shippingAddress2"
        label="Shipping address 2"
        register={register as any}
      />
      <InputV2
        name="shippingAddress3"
        label="Shipping address 3"
        register={register as any}
      />
    </InputComponent>
  );
};
