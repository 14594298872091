import './styles/AdminEditUser.css';
import { useState, useContext, useEffect } from 'react';
import { Authcontext } from '../../../auth/context/auth-context';
import { useParams } from 'react-router';
import { GETauthResourceRequest } from '../../../common/functions/requests';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { Page } from '../common/structure/Page';
import { PageHeader } from '../common/structure/PageHeader';
import { Box } from '@mui/material';
import { PageTitle } from '../common/structure/PageTitle';
import { PageContent } from '../common/structure/PageContent';
import { useHistory } from 'react-router-dom';
import { Dialog } from '../../../common/dialog/Dialog';
import { showError, showSuccess } from '../../../redux2/snackbar';
import { useDispatch } from 'react-redux';
import { AddressComponent } from './components/AddressComponent';
import { PageSubTitle } from '../common/structure/PageSubTitle';

export const UserDetailPage = () => {
  const auth = useContext(Authcontext);
  const history = useHistory();
  const dispatch = useDispatch();

  const email = useParams<any>().email;

  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState<boolean>(false);

  const [formValues, setFormValues] = useState<any>();

  useEffect(() => {
    GETauthResourceRequest(auth, '/users/', email).then((user) => {
      setFormValues(user[0]);
      setBillingAddress(user[0].billingAddress);
      setShippingAddress1(user[0].shippingAddresses[0] ?? '');
      setShippingAddress2(user[0].shippingAddresses[1] ?? '');
      setShippingAddress3(user[0].shippingAddresses[2] ?? '');
    });
  }, [auth, email]);

  const changeHandler = (e: any) => {
    setFormValues({ ...formValues, [e.target.name]: e.target.value });
  };

  const [loading, setLoading] = useState(false);

  const onUpdate = async () => {
    setLoading(true);
    const url = `${process.env.REACT_APP_BACKEND_URL}/users`;

    const updateShippingAddresses = [];

    if (shippingAddress1 && shippingAddress1 !== '') {
      updateShippingAddresses.push(shippingAddress1);
    }

    if (shippingAddress2 && shippingAddress2 !== '') {
      updateShippingAddresses.push(shippingAddress2);
    }

    if (shippingAddress3 && shippingAddress3 !== '') {
      updateShippingAddresses.push(shippingAddress3);
    }

    const updateUser = {
      email: formValues.email,
      id: formValues.id,
      name: formValues.name,
      discount: Number(formValues.discount),
      phoneNumber: formValues.phoneNumber,
      billingAddress,
      shippingAddresses: updateShippingAddresses,
    };

    try {
      const response = await fetch(url, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${auth.token}`,
        },
        body: JSON.stringify(updateUser),
      });
      await response.json();
    } catch (err) {
      setIsDialogOpen(false);
      dispatch(showError('User not updated'));
      setLoading(false);
      history.push('/admin/users');
    } finally {
      setIsDialogOpen(false);
      dispatch(showSuccess('User updated'));
      setLoading(false);
      history.push('/admin/users');
    }
  };

  const deleteUser = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/users/${formValues.email}`,
        {
          method: 'DELETE',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${auth.token}`,
          },
        }
      );
      await response.json();
    } catch (err) {
    } finally {
      setIsDeleteDialogOpen(false);
      dispatch(showSuccess('User deleted'));
      history.push('/admin/users');
    }
  };

  const [billingAddress, setBillingAddress] = useState<string>('');
  const [shippingAddress1, setShippingAddress1] = useState<string>('');
  const [shippingAddress2, setShippingAddress2] = useState<string>('');
  const [shippingAddress3, setShippingAddress3] = useState<string>('');

  const changeHandlerShippingAddresses = (e: any) => {
    if (e.target.name === 'billingAddress') {
      setBillingAddress(e.target.value);
    }
    if (e.target.name === 'shippingAddress1') {
      setShippingAddress1(e.target.value);
    }
    if (e.target.name === 'shippingAddress2') {
      setShippingAddress2(e.target.value);
    }
    if (e.target.name === 'shippingAddress3') {
      setShippingAddress3(e.target.value);
    }
  };

  if (formValues && !loading) {
    return (
      <>
        <Page>
          <>
            <PageHeader>
              <PageTitle title="User detail" />
              <PageSubTitle title="Update user details" />
            </PageHeader>
            <Box sx={{ height: '60px', width: '100%', marginRight: '20px' }}>
              <Button
                variant="contained"
                sx={{
                  float: 'left',
                  mr: '10px',
                  backgroundColor: '#424242',
                  borderColor: '#424242',
                  '&:hover': {
                    backgroundColor: '#9E9E9E',
                    borderColor: '#9E9E9E',
                  },
                }}
                onClick={() => setIsDialogOpen(true)}
              >
                Update
              </Button>
              <Button
                variant="outlined"
                sx={{
                  float: 'left',
                }}
                color="error"
                onClick={() => setIsDeleteDialogOpen(true)}
              >
                Delete
              </Button>
            </Box>
            <PageContent>
              <div className="div_main_principal_tipo">
                <form>
                  <div className="editUser_email">
                    <TextField
                      label="Email"
                      variant="outlined"
                      name="email"
                      defaultValue={formValues.email}
                      onChange={changeHandler}
                      size="small"
                      sx={{
                        width: '525px',
                        marginLeft: '20px',
                        marginTop: '20px',
                      }}
                      inputProps={{ readOnly: true }}
                    />
                  </div>
                  <div
                    className="editUser_email"
                    style={{ width: '220px', marginLeft: '20px' }}
                  >
                    <TextField
                      label="Discount"
                      variant="outlined"
                      name="discount"
                      defaultValue={formValues.discount}
                      onChange={changeHandler}
                      size="small"
                      sx={{
                        width: '150px',
                        marginLeft: '20px',
                        marginTop: '20px',
                      }}
                    />
                    <p
                      style={{
                        display: 'inline-block',
                        marginTop: '30px',
                        fontSize: '17px',
                      }}
                    >
                      %
                    </p>
                  </div>
                  <div className="company_details_div">
                    <div className="company_details_div_1">
                      <p>Company Details</p>
                    </div>
                    <div className="company_details_div_companyName">
                      <TextField
                        label="Company Name"
                        variant="outlined"
                        name="name"
                        defaultValue={formValues.name}
                        onChange={changeHandler}
                        size="small"
                        sx={{
                          width: '318px',
                          marginLeft: '20px',
                          marginTop: '20px',
                        }}
                      />
                    </div>
                    <div className="company_details_div_companyName">
                      <TextField
                        label="Customer ID"
                        variant="outlined"
                        name="id"
                        defaultValue={formValues.id}
                        onChange={changeHandler}
                        size="small"
                        sx={{
                          width: '318px',
                          marginLeft: '20px',
                          marginTop: '20px',
                        }}
                      />
                    </div>
                    <div className="company_details_div_companyName">
                      <TextField
                        label="Phone number"
                        variant="outlined"
                        name="phoneNumber"
                        defaultValue={formValues.phoneNumber}
                        onChange={changeHandler}
                        size="small"
                        sx={{
                          width: '318px',
                          marginLeft: '20px',
                          marginTop: '20px',
                        }}
                      />
                    </div>
                  </div>
                  <AddressComponent
                    name="billingAddress"
                    changeHandler={changeHandlerShippingAddresses}
                    label="Billing Address"
                    defaultValue={billingAddress}
                    sx={{ mr: '20px' }}
                  />
                  <AddressComponent
                    name="shippingAddress1"
                    changeHandler={changeHandlerShippingAddresses}
                    defaultValue={shippingAddress1}
                    label="Delivery Address 1"
                  />
                  <AddressComponent
                    name="shippingAddress2"
                    changeHandler={changeHandlerShippingAddresses}
                    label="Delivery Address 2"
                    defaultValue={shippingAddress2}
                    sx={{ mr: '20px' }}
                  />
                  <AddressComponent
                    name="shippingAddress3"
                    changeHandler={changeHandlerShippingAddresses}
                    defaultValue={shippingAddress3}
                    label="Delivery Address 3"
                  />

                  {/*<div className="shippingAdress_div">
                    <div className="company_details_div_1">
                      <p>Shipping Address 02</p>
                    </div>
                    <div className="company_details_div_companyName">
                      <TextField
                        label="Address"
                        variant="outlined"
                        name="shippingAdress2"
                        defaultValue={formValues.shippingAdress2}
                        onChange={changeHandler}
                        size="small"
                        sx={{
                          width: '318px',
                          marginLeft: '20px',
                          marginTop: '20px',
                        }}
                      />
                    </div>
                    <div className="company_details_div_cityCountry">
                      <TextField
                        label="City"
                        variant="outlined"
                        name="shippingAdress2City"
                        defaultValue={formValues.shippingAdress2City}
                        onChange={changeHandler}
                        size="small"
                        sx={{
                          width: '130px',
                          marginLeft: '20px',
                          marginTop: '20px',
                        }}
                      />
                    </div>
                    <div className="company_details_div_cityCountry">
                      <TextField
                        label="Country"
                        variant="outlined"
                        name="shippingAdress2Country"
                        defaultValue={formValues.shippingAdress2Country}
                        onChange={changeHandler}
                        size="small"
                        sx={{
                          width: '130px',
                          marginLeft: '20px',
                          marginTop: '20px',
                        }}
                      />
                    </div>
                    <div className="company_details_div_companyName">
                      <TextField
                        label="Zip code"
                        variant="outlined"
                        name="shippingAdress2ZipCode"
                        defaultValue={formValues.shippingAdress2ZipCode}
                        onChange={changeHandler}
                        size="small"
                        sx={{
                          width: '318px',
                          marginLeft: '20px',
                          marginTop: '20px',
                        }}
                      />
                    </div>
                  </div>*/}
                </form>
              </div>
            </PageContent>
          </>
        </Page>
        <Dialog
          isDialogOpen={isDialogOpen}
          setIsDialogOpen={setIsDialogOpen}
          title="Update user"
          description="Are you sure you want to update user?"
          buttonLabel="Update"
          action={onUpdate}
        />
        <Dialog
          isDialogOpen={isDeleteDialogOpen}
          setIsDialogOpen={setIsDeleteDialogOpen}
          title="Delete user"
          description="Are you sure you want to delete user?"
          buttonLabel="Delete"
          action={deleteUser}
        />
      </>
    );
  } else return <h1> Loading </h1>;
};
