import { PageHeaderV2 } from '../common/structure/PageHeaderV2';
import { PageV2 } from '../common/structure/PageV2';
import { InputColumn } from '../common/structure/InputColumn';
import { useState } from 'react';
import { MediaComponentV2 } from './media/MediaComponentV2';
import { VariantsComponent } from './components/VariantsComponent';
import { GeneralInformation } from './componentsV2/GeneralInformation';
import { ProductDetails } from './componentsV2/ProductDetails';
import { ProductStatus } from './componentsV2/ProductStatus';
import { Box, Button } from '@mui/material';

export const CreateProductPage = () => {
  const [variants, setVariants] = useState<any>([]);

  function createProduct() {}

  return (
    <PageV2>
      <PageHeaderV2 title="Create product" />
      <Box sx={{ mb: '20px' }}>
        <Button
          variant="contained"
          sx={{ width: '150px' }}
          onClick={createProduct}
        >
          Create
        </Button>
      </Box>
      <InputColumn>
        <GeneralInformation />
        <ProductDetails />
      </InputColumn>
      <InputColumn sx={{ ml: '20px' }}>
        <ProductStatus />
        <MediaComponentV2 />
        <VariantsComponent variants={variants} setVariants={setVariants} />
      </InputColumn>
    </PageV2>
  );
};
