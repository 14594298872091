import { Box, Typography } from '@mui/material';
import { FunctionComponent, ReactNode } from 'react';

interface InputComponentProps {
  hasTitle: boolean;
  title?: string;
  children: ReactNode;
  sx?: any;
}

export const InputComponent: FunctionComponent<InputComponentProps> = ({
  hasTitle,
  title,
  children,
  sx,
}) => {
  return (
    <Box
      sx={{
        width: '590px',
        backgroundColor: 'white',
        overflow: 'auto',
        borderRadius: '4px',
        boxShadow: '0px 0px 10px 2px rgb(222, 222, 222)',
        pb: '15px',
        ...sx,
      }}
    >
      {hasTitle && (
        <Typography
          sx={{
            fontFamily: 'Source Code Pro',
            fontSize: 16,
            fontWeight: 400,
            ml: '15px',
            mt: '15px',
          }}
        >
          {title}
        </Typography>
      )}
      {children}
    </Box>
  );
};
