import { FunctionComponent } from 'react';
import { InputComponent } from '../../common/structure/InputComponent';
import { InputV2 } from '../../common/structure/InputV2';
import { UseFormRegister } from 'react-hook-form';
import { CreateUserDto } from '../../../../dtos/CreateUserDto';

interface CreateUserAuthenticationProps {
  register: UseFormRegister<CreateUserDto>;
}

export const CreateUserAuthentication: FunctionComponent<
  CreateUserAuthenticationProps
> = ({ register }) => {
  const changeHandler = () => {};

  return (
    <InputComponent hasTitle={true} title="Authentication">
      <InputV2
        name="email"
        label="Email"
        changeHandler={changeHandler}
        register={register as any}
      />
      <InputV2
        name="password"
        label="Password"
        changeHandler={changeHandler}
        register={register as any}
      />
    </InputComponent>
  );
};
