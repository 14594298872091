import { useContext, useEffect, useState } from 'react';
import { apiRequest } from '../common/apiRequest';
import { Method } from '../common/Method';
import { Authcontext } from '../../auth/context/auth-context';
import { ProductDto } from '../../dtos/ProductDto';

export const useGetAllProducts = () => {
  const auth = useContext(Authcontext);

  const [data, setData] = useState<ProductDto[]>();
  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    const getAllProducts = async () => {
      const productsDtos: ProductDto[] = await apiRequest(
        '/products',
        Method.GET,
        auth
      );
      const sortedProductsDtos = productsDtos.sort((a: any, b: any) => {
        // Extract the numerical part of the IDs (e.g., "4172" from "G4172_A26D16")
        const idA = parseInt(a.id.match(/\d+/)[0], 10);
        const idB = parseInt(b.id.match(/\d+/)[0], 10);

        if (idA < idB) {
          return 1; // 'a' should come before 'b'
        } else if (idA > idB) {
          return -1; // 'a' should come after 'b'
        } else {
          return 0; // IDs are equal, order doesn't matter
        }
      });
      setData(sortedProductsDtos);
      setIsLoading(false);
    };
    getAllProducts();
  }, [auth]);

  return { data, isLoading };
};
