import { Box, FormControlLabel, Switch } from '@mui/material';
import { InputComponent } from '../../common/structure/InputComponent';
import { FunctionComponent } from 'react';

interface UpdateProductStatusProps {
  register: (name: string) => any;
  watch: (name: string) => any;
}

export const UpdateProductStatus: FunctionComponent<
  UpdateProductStatusProps
> = ({ register, watch }) => {
  const isActive = watch('active');
  const isSynchronized = watch('shopifySynchronized');

  return (
    <InputComponent
      hasTitle={true}
      title="Status"
      sx={{ ml: '20px', float: 'right' }}
    >
      <Box sx={{ width: '50%', float: 'left' }}>
        <p
          style={{
            marginBottom: '10px',
            marginLeft: '15px',
            fontSize: '15px',
          }}
        >
          B2B
        </p>
        <FormControlLabel
          control={
            <Switch
              inputProps={{ 'aria-label': 'controlled' }}
              checked={isActive ?? false}
              name="active"
              sx={{ ml: '10px' }}
              {...register('active')}
            />
          }
          label={isActive ? 'Active' : 'Draft'}
        />
      </Box>
      <Box sx={{ width: '50%', float: 'left' }}>
        <p
          style={{
            marginBottom: '10px',
            marginLeft: '15px',
            fontSize: '15px',
          }}
        >
          Shopify
        </p>
        <FormControlLabel
          control={
            <Switch
              inputProps={{ 'aria-label': 'controlled' }}
              checked={isSynchronized ?? false}
              name="shopifySynchronized"
              sx={{ ml: '10px' }}
              {...register('shopifySynchronized')}
            />
          }
          label={isSynchronized ? 'Synchronized' : 'Not Synchronized'}
        />
      </Box>
    </InputComponent>
  );
};
