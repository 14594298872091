import { FunctionComponent } from 'react';
import { Box } from '@mui/material';

interface DashboardComponentProps {
  label: string;
  value: number | string;
  sx?: any;
  icon?: any;
}

export const DashboardComponent: FunctionComponent<DashboardComponentProps> = ({
  label,
  value,
  sx,
  icon,
}) => {
  return (
    <Box
      sx={{
        maxWidth: '1100px',
        minWidth: '250px',
        height: '200px',
        backgroundColor: 'white',
        borderRadius: '20px',
        marginRight: '20px',
        marginBottom: '20px',
        boxShadow: '0px 0px 10px 2px rgb(222, 222, 222)',
        position: 'relative',
        flexGrow: 1,
        ...sx,
      }}
    >
      <img
        alt="necessary"
        style={{
          height: '45px',
          position: 'absolute',
          top: '47px',
          left: '50%',
          transform: 'translate(-50%)',
        }}
        src={icon}
      />

      <p
        style={{
          fontFamily: 'Roboto',
          fontWeight: 800,
          fontSize: '20px',
          position: 'absolute',
          top: '75px',
          left: '50%',
          transform: 'translate(-50%)',
        }}
      >
        {value}
      </p>

      <p
        style={{
          fontFamily: 'Roboto',
          fontWeight: 400,
          fontSize: '15px',
          position: 'absolute',
          top: '110px',
          left: '50%',
          transform: 'translate(-50%)',
        }}
      >
        {label}
      </p>
    </Box>
  );
};
