export function sortByProductIdFirst(array: any, productId: string) {
  // Find the index of the object with the specified productId
  const index = array.findIndex((obj: any) => obj.id === productId);

  // If the productId is found, move the object to the beginning of the array
  if (index !== -1) {
    const obj = array[index];
    array.splice(index, 1); // Remove the object from its original position
    array.unshift(obj); // Insert the object at the beginning of the array
  }

  return array;
}
