import { FunctionComponent, useContext, useEffect, useState } from 'react';
import { Box } from '@mui/material';
import { Loading } from '../common/Loading';
import { FilterSection } from './components/FilterSection';
import { ProductCard } from './componentsV2/ProductCard';
import { sortProducts } from './functions/sortProducts';
import { filterActiveProducts } from './functions/filterActiveProducts';
import { ProductRepository } from '../../../repositories/ProductRepository';
import { Authcontext } from '../../../auth/context/auth-context';
import ReorderIcon from '@mui/icons-material/Reorder';
import AppsIcon from '@mui/icons-material/Apps';
import { ProductQuantitySelectorCatalog } from './components/ProductQuantitySelectorCatalog';

enum ProductsView {
  Card = 'card',
  List = 'list',
}

export const ProductsListPage: FunctionComponent = () => {
  const auth = useContext(Authcontext);

  const productRepository = new ProductRepository();

  const [isProductCardView, setIsProductCardView] = useState(true);

  const [products, setProducts] = useState([]);
  const [stateProducts, setStateProducts] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  function changeProductView(view: ProductsView) {
    if (view === ProductsView.Card) {
      setIsProductCardView(true);
    } else {
      setIsProductCardView(false);
    }
  }

  useEffect(() => {
    productRepository.getAllProducts(auth).then((products) => {
      const filteredProducts = filterActiveProducts(products);
      const sortedProducts = sortProducts(filteredProducts);
      setStateProducts(sortedProducts);
      setProducts(sortedProducts);
      setIsLoading(false);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {isLoading ? (
        <Loading />
      ) : (
        <Box
          sx={{
            width: '1200px',
            margin: 'auto',
            mt: '20px',
            mb: '20px',
            overflow: 'auto',
            pt: '10px',
          }}
        >
          <FilterSection
            stateProducts={stateProducts}
            products={products}
            setProducts={setProducts}
          />
          <Box
            sx={{
              width: '930px',
              float: 'left',
              ml: '270px',
              overflow: 'hidden',
            }}
          >
            <Box
              sx={{
                width: '100%',
                height: '50px',
              }}
            >
              <ReorderIcon
                onClick={() => changeProductView(ProductsView.List)}
                sx={{
                  float: 'right',
                  mr: '10px',
                  fontSize: '30px',
                  color: isProductCardView ? 'grey' : 'black',
                  cursor: 'pointer',
                }}
              />
              <AppsIcon
                onClick={() => changeProductView(ProductsView.Card)}
                sx={{
                  float: 'right',
                  mr: '5px',
                  fontSize: '30px',
                  color: isProductCardView ? 'black' : 'grey',
                  cursor: 'pointer',
                }}
              />
            </Box>
            {isProductCardView &&
              products.map((product: any) => {
                return (
                  product && <ProductCard key={product.id} product={product} />
                );
              })}
            {!isProductCardView &&
              products.map((product: any) => (
                <ProductQuantitySelectorCatalog
                  product={product}
                  key={product.id}
                />
              ))}
          </Box>
        </Box>
      )}
    </>
  );
};
