import { Box, Button, Paper, TextField } from '@mui/material';
import { FunctionComponent, useEffect, useState } from 'react';
import { getTotalShopcartValue } from '../../shopcartV2/functions/getTotalShopcartValue';
import { getTotalShopcartQuantity } from '../../shopcartV2/functions/getTotalShopcartQuantity';
import { CSVLink } from 'react-csv';
import DownloadIcon from '@mui/icons-material/Download';

interface OrderInformationProps {
  order: any;
}

export const OrderInformation: FunctionComponent<OrderInformationProps> = ({
  order,
}) => {
  const totalShopcartQuantity = getTotalShopcartQuantity(
    order.shopcartProducts
  );
  const totalShopcartValue = getTotalShopcartValue(order.shopcartProducts);

  const headers = [
    { label: 'sku', key: 'sku' },
    { label: 'size', key: 'size' },
    { label: 'price', key: 'price' },
    { label: 'quantity', key: 'quantity' },
  ];

  const [data, setData] = useState<any>([]);

  const [isDownloadAvailable, setIsDownloadAvailable] =
    useState<boolean>(false);

  let count = 0;
  let numProducts = order.shopcartProducts.length;
  useEffect(() => {
    const promise = new Promise((resolve, reject) => {
      const csvFormatProducts: any = [];

      order.shopcartProducts.forEach((product: any) => {
        count++;
        let isFirstVariant = true;

        product.variants.forEach((variant: any) => {
          const csvFormatVariant = {
            sku: isFirstVariant ? product.id : '',
            size: variant.size,
            price: `${variant.price} €`,
            quantity: variant.quantity,
          };
          isFirstVariant = false;

          csvFormatProducts.push(csvFormatVariant);
        });

        if (count === numProducts) {
          setData(csvFormatProducts);
          resolve('all data stored in csv');
        }
      });
    });

    promise.then(() => {
      setIsDownloadAvailable(true);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [order]);

  return (
    <Paper
      sx={{
        width: '700px',
        height: '205px',
        float: 'right',
        marginTop: '30px',
        marginBottom: '30px',
        boxShadow: '0px 0px 10px 2px rgb(222, 222, 222)',
      }}
      elevation={0}
    >
      <Box
        sx={{
          width: '335px',
          mt: '10px',
          ml: '10px',
          float: 'left',
        }}
      >
        <TextField
          id="outlined-basic"
          label="Order ID"
          variant="outlined"
          size="small"
          sx={{ width: '100%', mb: '10px' }}
          inputProps={{ readOnly: true }}
          value={order.id}
        />
        <TextField
          id="outlined-basic"
          label="Store"
          variant="outlined"
          size="small"
          sx={{ width: '100%' }}
          inputProps={{ readOnly: true }}
          value={order.store}
        />
        <TextField
          id="outlined-basic"
          label="Notes"
          variant="outlined"
          multiline
          rows={3}
          size="small"
          sx={{ width: '100%', mt: '10px' }}
          inputProps={{ readOnly: true }}
          value={order.note}
        />
      </Box>
      <Box
        sx={{
          width: '335px',
          mt: '10px',
          ml: '10px',
          float: 'left',
        }}
      >
        <TextField
          id="outlined-basic"
          label="Date"
          variant="outlined"
          size="small"
          sx={{ width: '100%', mb: '10px' }}
          inputProps={{ readOnly: true }}
          value={order.date}
        />
        <TextField
          id="outlined-basic"
          label="Quantity"
          variant="outlined"
          size="small"
          sx={{ width: '100%', mb: '10px' }}
          inputProps={{ readOnly: true }}
          value={totalShopcartQuantity}
        />
        <TextField
          id="outlined-basic"
          label="Cost"
          variant="outlined"
          size="small"
          sx={{ width: '100%', mb: '10px' }}
          inputProps={{ readOnly: true }}
          value={`${totalShopcartValue} €`}
        />
        {isDownloadAvailable ? (
          <CSVLink data={data} headers={headers} filename={'order.csv'}>
            <Button
              variant="contained"
              startIcon={<DownloadIcon />}
              sx={{ width: '100%' }}
              size="small"
            >
              DOWNLOAD STOCK CSV
            </Button>
          </CSVLink>
        ) : (
          <Button
            variant="contained"
            startIcon={<DownloadIcon />}
            disabled
            sx={{ width: '100%' }}
            size="small"
          >
            DOWNLOAD STOCK CSV
          </Button>
        )}
      </Box>
    </Paper>
  );
};
