import './Images.css';
import React, { useEffect, useState } from 'react';

const Images = (props) => {
  const image1 = props.img1;
  const image2 = props.img2;
  const image3 = props.img3;
  const image4 = props.img4;

  console.log(image1);

  const [priImg, setPriImg] = useState(image1);

  useEffect(() => {
    setPriImg(image1);
  }, [image1]);

  const changeImage1 = () => {
    if (image1) setPriImg(image1);
  };

  const changeImage2 = () => {
    if (image2) setPriImg(image2);
  };

  const changeImage3 = () => {
    if (image3) setPriImg(image3);
  };

  const changeImage4 = () => {
    if (image4) setPriImg(image4);
  };

  return (
    <div className="div_produto_imagens">
      <div className="div_produto_imagens-img_sec">
        <div
          onClick={changeImage1}
          className="div_produto_imagens-img_sec-div_img"
        >
          <img src={image1} alt="logo" />
        </div>
        <div
          onClick={changeImage2}
          className="div_produto_imagens-img_sec-div_img"
        >
          <img src={image2} alt="logo" />
        </div>
        <div
          onClick={changeImage3}
          className="div_produto_imagens-img_sec-div_img"
        >
          <img src={image3} alt="logo" />
        </div>
        <div
          onClick={changeImage4}
          className="div_produto_imagens-img_sec-div_img"
        >
          <img src={image4} alt="logo" />
        </div>
      </div>
      <div className="div_produto_imagens-img_pri">
        <div className="div_produto_imagens-img_pri-div_img">
          <img src={priImg} alt="logo" />
        </div>
      </div>
    </div>
  );
};

export default Images;
