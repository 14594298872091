import { InputComponent } from '../../common/structure/InputComponent';
import { InputV2 } from '../../common/structure/InputV2';

export const GeneralInformation = () => {
  const changeHandler = (e: any) => {};

  return (
    <InputComponent hasTitle={true} title="General Information">
      <InputV2 name="title" label="Title" changeHandler={changeHandler} />
      <InputV2 name="color" label="Color" changeHandler={changeHandler} />
      <InputV2 name="sku" label="Sku" changeHandler={changeHandler} />
    </InputComponent>
  );
};
