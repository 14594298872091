import { Box, IconButton, Paper, Typography } from '@mui/material';
import { FunctionComponent, useContext, useEffect, useState } from 'react';
import defaultProductImage from '../../../../images/default_image.png';
import { QuantitySelector } from './QuantitySelector';
import { FiTrash2 } from 'react-icons/fi';
import { getTotalCost } from '../functions/getTotalCost';
import { getTotalQuantity } from '../functions/getTotalQuantity';
import { useDispatch } from 'react-redux';
import {
  addProductToShopcart,
  removeProductFromShopcart,
} from '../../../../redux2/shopcart';
import { ProductRepository } from '../../../../repositories/ProductRepository';
import { Loading } from '../Loading';
import { Authcontext } from '../../../../auth/context/auth-context';

interface ShopcartProductQuantitySelectorProps {
  shopcartProduct: any;
}

export const ShopcartProductQuantitySelector: FunctionComponent<
  ShopcartProductQuantitySelectorProps
> = ({ shopcartProduct }) => {
  const auth = useContext(Authcontext);
  const dispatch = useDispatch();

  const productRepository = new ProductRepository();

  const [product, setProduct] = useState<any>();
  const [totalQuantity, setTotalQuantity] = useState<any>();
  const [totalCost, setTotalCost] = useState<any>();

  useEffect(() => {
    productRepository
      .getProductById(shopcartProduct.id, auth)
      .then((product) => {
        setProduct(product);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shopcartProduct.id]);

  let shopcartProductVariants: any = [];
  if (product) {
    for (let index = 0; index < shopcartProduct.variants.length; index++) {
      shopcartProductVariants.push({
        size: product.variants[index].size,
        stock: product.variants[index].stock,
        price: product.variants[index].price,
        pvp: product.variants[index].pvp,
        quantity: shopcartProduct.variants[index].quantity,
      });
    }
  }

  useEffect(() => {
    setTotalCost(getTotalCost(shopcartProduct.variants));
    setTotalQuantity(getTotalQuantity(shopcartProduct.variants));
  }, [shopcartProduct]);

  function updateQuantity(size: any, qty: number, price?: number) {
    const variantIndex = shopcartProduct.variants.findIndex(
      (variant: any) => variant.size === size
    );

    const variants = shopcartProduct.variants.map(
      (variant: any, index: number) => {
        if (index === variantIndex) {
          return { ...variant, quantity: qty };
        }
        return variant;
      }
    );

    const updatedProduct = {
      id: shopcartProduct.id,
      variants,
    };

    dispatch(addProductToShopcart(updatedProduct));
  }

  return (
    <>
      {!product ? (
        <Loading />
      ) : (
        <Paper
          sx={{
            width: '1200px',
            height: '180px',
            mt: '30px',
            boxShadow: '0px 0px 10px 2px rgb(222, 222, 222)',
          }}
          elevation={0}
        >
          <Box sx={{ width: '180px', float: 'left' }}>
            <img
              src={product.media[0].url ?? defaultProductImage}
              alt="product"
              style={{
                width: '160px',
                height: '160px',
                marginTop: '10px',
                marginLeft: '10px',
                borderRadius: '3px',
              }}
            />
          </Box>
          <Box sx={{ width: '180px', float: 'left' }}>
            <Typography
              sx={{ mt: '10px', ml: '10px', color: 'grey', fontSize: '13px' }}
            >
              {product.id}
            </Typography>
            <Typography
              sx={{
                mt: '10px',
                ml: '10px',
                fontSize: '27px',
              }}
            >
              {product.title}
            </Typography>
            <Typography sx={{ ml: '10px' }}>{product.color}</Typography>
          </Box>
          <Box
            sx={{
              width: '830px',
              mt: '10px',
              float: 'left',
            }}
          >
            <Box
              sx={{
                width: '100%',
                height: '130px',
                float: 'left',
              }}
              className="scrolling-container"
            >
              {shopcartProductVariants.map((variant: any) => (
                <QuantitySelector
                  key={variant.size}
                  size={variant.size}
                  stock={variant.stock}
                  price={variant.price}
                  pvp={variant.pvp}
                  quantity={variant.quantity}
                  updateQuantity={updateQuantity}
                />
              ))}
            </Box>
            <Box
              sx={{
                width: '100%',
                height: '35px',
                float: 'left',
              }}
            >
              <Box
                sx={{
                  width: '50px',
                  height: '30px',
                  float: 'right',
                  mr: '20px',
                }}
              >
                <IconButton
                  aria-label="delete"
                  size="medium"
                  style={{
                    marginTop: '0px',
                    color: 'red',
                    float: 'right',
                    fontSize: '19px',
                  }}
                  onClick={() =>
                    dispatch(removeProductFromShopcart(product.id))
                  }
                >
                  <FiTrash2
                    style={{
                      color: 'red',
                    }}
                  />
                </IconButton>
              </Box>
              <Box
                sx={{
                  width: '120px',
                  height: '30px',
                  float: 'right',
                }}
              >
                <Typography
                  sx={{
                    ml: '5px',
                    mt: '7px',
                    fontSize: '14px',
                    color: 'black',
                  }}
                >
                  Cost: {Number(totalCost).toFixed(2)}€
                </Typography>
              </Box>
              <Box
                sx={{
                  width: '120px',
                  height: '30px',
                  mr: '10px',
                  float: 'right',
                }}
              >
                <Typography
                  sx={{
                    ml: '5px',
                    mt: '7px',
                    fontSize: '14px',
                    color: 'black',
                  }}
                >
                  Quantity: {totalQuantity}
                </Typography>
              </Box>
            </Box>
          </Box>
        </Paper>
      )}
    </>
  );
};
