import { useEffect, useState, useContext } from 'react';
import { Authcontext } from '../../../auth/context/auth-context';
import TextField from '@mui/material/TextField';
import { Page } from '../common/structure/Page';
import { PageHeader } from '../common/structure/PageHeader';
import { PageTitle } from '../common/structure/PageTitle';
import { Button, Paper } from '@mui/material';
import { Dialog } from '../../../common/dialog/Dialog';
import { showError, showSuccess } from '../../../redux2/snackbar';
import { useDispatch } from 'react-redux';

export const AccountPage = () => {
  const dispatch = useDispatch();
  const auth = useContext(Authcontext);

  const [currentpassword, setCurrentPassword] = useState<string>('');
  const [newPassword, setnewPassword] = useState<string>('');
  const [newPasswordRepeated, setnewPasswordRepeated] = useState<string>('');
  const [isPasswordMatching, setIsPasswordMatching] = useState<boolean>(false);
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  useEffect(() => {
    if (
      currentpassword !== '' &&
      newPassword !== '' &&
      newPassword === newPasswordRepeated
    ) {
      setIsPasswordMatching(true);
    } else if (newPassword !== newPasswordRepeated) {
      setIsPasswordMatching(false);
    } else if (
      newPassword === '' ||
      currentpassword === '' ||
      newPasswordRepeated === ''
    ) {
      setIsPasswordMatching(false);
    }
  }, [newPassword, newPasswordRepeated, currentpassword]);

  const UpdatePassword = async () => {
    if (isPasswordMatching) {
      const url = process.env.REACT_APP_BACKEND_URL + '/users/changePassword';
      try {
        await fetch(url, {
          method: 'PATCH',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${auth.token}`,
          },
          body: JSON.stringify({
            email: auth.email,
            password: currentpassword,
            newPassword: newPassword,
          }),
        });
        setIsPasswordMatching(false);
      } catch (err) {
        setIsDialogOpen(false);
        dispatch(showError('Password not changed'));
      } finally {
        setIsDialogOpen(false);
        dispatch(showSuccess('Password changed'));
      }
    }
  };

  return (
    <>
      <Page>
        <>
          <PageHeader>
            <PageTitle title="Account" />
          </PageHeader>
          <Paper
            className="admin_account_div"
            style={{
              width: '379px',
              height: '270px',
              margin: 'auto',
              backgroundColor: 'white',
              paddingLeft: '30px',
              paddingTop: '14px',
              borderRadius: '4px',
              marginTop: '120px',
            }}
          >
            <TextField
              label="Current Password"
              variant="outlined"
              onChange={(e) => {
                setCurrentPassword(e.target.value);
              }}
              size="small"
              sx={{ width: '350px', marginTop: '20px' }}
            />
            <TextField
              label="New Password"
              variant="outlined"
              onChange={(e) => {
                setnewPassword(e.target.value);
              }}
              size="small"
              sx={{ width: '350px', marginTop: '20px' }}
            />
            <TextField
              label="Repeat New Password"
              variant="outlined"
              onChange={(e) => {
                setnewPasswordRepeated(e.target.value);
              }}
              size="small"
              sx={{ width: '350px', marginTop: '20px', marginBottom: '20px' }}
            />
            <div
              style={{ width: '92%', textAlign: 'center', marginTop: '10px' }}
            >
              <Button
                sx={{
                  backgroundColor: '#424242',
                  '&:hover': {
                    backgroundColor: '#9E9E9E',
                  },
                }}
                variant="contained"
                onClick={() => setIsDialogOpen(true)}
              >
                Change password
              </Button>
            </div>
          </Paper>
        </>
      </Page>
      <Dialog
        isDialogOpen={isDialogOpen}
        setIsDialogOpen={setIsDialogOpen}
        title="Change password"
        description="Are you sure you want to change password?"
        buttonLabel="Change"
        action={UpdatePassword}
      />
    </>
  );
};
