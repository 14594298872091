import { Box, Button, Typography } from '@mui/material';
import { FunctionComponent, useEffect, useState } from 'react';
import DownloadIcon from '@mui/icons-material/Download';
import { GETrequest } from '../../../../common/functions/requests';
import { CSVLink } from 'react-csv';

export const DownloadOmodaStock: FunctionComponent = () => {
  const headers = [{ label: 'Delete this row', key: 'barcode' }];

  const [data, setData] = useState<any>([]);
  const [isDownloadAvailable, setIsDownloadAvailable] =
    useState<boolean>(false);

  useEffect(() => {
    GETrequest('/stock').then((products) => {
      let count = 0;
      let numProducts = products.length;
      const promise = new Promise((resolve, reject) => {
        const csvFormatProducts: any = [];

        products.forEach((product: any) => {
          count++;

          product.variants.forEach((variant: any) => {
            if (variant.barcode) {
              const csvFormatVariant = {
                barcode:
                  variant.barcode +
                  ';Omoda' +
                  ';' +
                  variant.size +
                  ';' +
                  variant.stock +
                  ';',
              };

              csvFormatProducts.push(csvFormatVariant);
            }
          });

          if (count === numProducts) {
            setData(csvFormatProducts);
            resolve('all data stored in csv');
          }
        });
      });

      promise.then(() => {
        setIsDownloadAvailable(true);
      });
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box ml={0}>
      <Typography
        sx={{
          fontFamily: 'Roboto',
          fontSize: 16,
          fontWeight: 300,
          mt: 3,
        }}
      >
        - Download a CSV file with product stock information ready to import
        directly on OMODA Plataform.
      </Typography>
      {isDownloadAvailable ? (
        <CSVLink
          data={data}
          headers={headers}
          filename={'omodaStocks.csv'}
          style={{ textDecoration: ' none' }}
        >
          <Button
            variant="outlined"
            startIcon={<DownloadIcon />}
            sx={{
              mt: '30px',
              color: '#424242',
              borderColor: '#424242',
              '&:hover': {
                backgroundColor: '#9E9E9E',
                color: 'white',
                borderColor: '#9E9E9E',
              },
            }}
          >
            DOWNLOAD OMODA CSV
          </Button>
        </CSVLink>
      ) : (
        <Button
          variant="outlined"
          startIcon={<DownloadIcon />}
          disabled
          sx={{ mt: '30px' }}
        >
          DOWNLOAD OMODA CSV
        </Button>
      )}
    </Box>
  );
};
