import { useParams } from 'react-router-dom';
import { useState, useEffect, useContext } from 'react';
import { Authcontext } from '../../../auth/context/auth-context';
import { Loading } from '../common/Loading';
import { Box } from '@mui/material';
import { OrderProductQuantitySelector } from '../common/components/OrderProductQuantitySelector';
import { OrderInformation } from './componentsV2/OrderInformation';

export const OrderDetailPage = () => {
  const auth = useContext(Authcontext);
  const orderId = useParams<any>().orderId;

  const [isLoading, setIsLoading] = useState(true);

  const [loadedData, setLoadedData] = useState<any>();
  let url = process.env.REACT_APP_BACKEND_URL + '/orders/' + orderId;

  useEffect(() => {
    const sendRequest = async () => {
      try {
        const response = await fetch(url, {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${auth.token}`,
          },
        });
        const order = await response.json();
        setLoadedData(order);
        setIsLoading(false);
      } catch (err) {}
    };
    sendRequest();
  }, [auth.token, url]);

  return (
    <>
      {isLoading ? (
        <Loading />
      ) : (
        <>
          <Box sx={{ width: '1200px', margin: 'auto', paddingTop: '20px' }}>
            {loadedData &&
              loadedData.shopcartProducts.map((shopcartProduct: any) => (
                <OrderProductQuantitySelector
                  key={shopcartProduct.id}
                  shopcartProduct={shopcartProduct}
                />
              ))}
            {loadedData && <OrderInformation order={loadedData} />}
          </Box>
        </>
      )}
    </>
  );

  /*
  if (loadedData) {
    return (
      <Page>
        <h1>Order</h1>
        <p
          style={{
            fontSize: '13px',
            fontFamily: 'Source Code Pro',
            fontWeight: '500',
          }}
        >
          ID : {loadedData[0].orderId}
        </p>
        <p
          style={{
            fontSize: '13px',
            fontFamily: 'Source Code Pro',
            fontWeight: '500',
          }}
        >
          Email : {loadedData[0].email}
        </p>
        {loadedData[0].productsList.map((product) => (
          <UserOrderProductsSpecific product={product} />
        ))}
        <div
          style={{
            width: '350px',
            height: '250px',
            float: 'right',
            marginLeft: '15px',
          }}
        >
          <div
            style={{
              width: '348px',
              height: '50px',
              border: '1px solid #c2c2c2',
              marginBottom: '15px',
            }}
          >
            <p className="shopcart-form-p">
              Quantity: {loadedData[0].quantity}
            </p>
          </div>
          <div
            style={{
              width: '348px',
              height: '50px',
              border: '1px solid #c2c2c2',
              marginBottom: '15px',
            }}
          >
            <p className="shopcart-form-p">Total: {loadedData[0].cost} €</p>
          </div>
        </div>
        <div
          style={{
            width: '400px',
            height: '250px',
            float: 'right',
            marginLeft: '15px',
          }}
        >
          <div
            width="398px"
            height="172px"
            border="1px solid #c2c2c2"
            marginBottom="15px"
          >
            <p className="shopcart-form-p"> {loadedData[0].shippingAdress} </p>
          </div>
        </div>
        <div style={{ width: '300px', height: '200px', float: 'right' }}>
          <div width="298px" height="172px" border="1px solid #c2c2c2">
            <p className="shopcart-form-p">
              Order note: <br /> {loadedData[0].orderNote}
            </p>
          </div>
        </div>
      </Page>
    );
  } else {
    return <h1> Loading </h1>;
  }
  */
};
