import { FunctionComponent } from 'react';
import { InputComponent } from '../../common/structure/InputComponent';
import { UpdateImageComponentV2 } from './UpdateImageComponentV2';

interface UpdateMediaComponentProps {
  product: any;
}

export const UpdateMediaComponent: FunctionComponent<
  UpdateMediaComponentProps
> = ({ product }) => {
  return (
    <InputComponent
      hasTitle={true}
      title="Media"
      sx={{ mt: '20px', float: 'right' }}
    >
      <UpdateImageComponentV2 product={product} imagePosition={0} />
      <UpdateImageComponentV2 product={product} imagePosition={1} />
      <UpdateImageComponentV2 product={product} imagePosition={2} />
      <UpdateImageComponentV2 product={product} imagePosition={3} />
    </InputComponent>
  );
};
