import { useState, useEffect, useContext } from 'react';
import { Authcontext } from '../../../auth/context/auth-context';
import { GETauthrequest } from '../../../common/functions/requests';
import { OrdersTable } from './components/OrdersTable';
import Loading2 from '../common/loading/Loading2';
import { Order } from '../../../models/Order';
import { Page } from '../common/structure/Page';
import { PageHeader } from '../common/structure/PageHeader';
import { Box, Button } from '@mui/material';
import { PageTitle } from '../common/structure/PageTitle';
import { SearchBar } from '../common/SearchBar';
import { useHistory } from 'react-router-dom';
import { PageSubTitle } from '../common/structure/PageSubTitle';

export const OrdersListPage = () => {
  const auth = useContext(Authcontext);
  const history = useHistory();

  const [loadedData, setLoadedData] = useState<Order[]>([]);
  const [orders, setOrders] = useState<Order[]>([]);

  useEffect(() => {
    GETauthrequest(auth, '/orders').then((orders) => {
      const reversedOrders = orders.reverse();
      setOrders(reversedOrders);
      setLoadedData(reversedOrders);
    });
  }, [auth]);

  function handleFilterSearch(e: { target: { value: string } }) {
    setLoadedData(
      orders.filter((order) =>
        order.email.toUpperCase().startsWith(e.target.value.toUpperCase())
      )
    );
  }

  return (
    <Page>
      <>
        {!loadedData && <Loading2 />}
        <PageHeader>
          <PageTitle title="Orders" />
          <PageSubTitle title="Order processing and tracking" />
        </PageHeader>
        <Box sx={{ height: '60px', width: '100%', marginRight: '20px' }}>
          <Button
            variant="outlined"
            sx={{ float: 'left', mr: '20px' }}
            onClick={() => history.push('orders/createOrder')}
          >
            CREATE ORDER
          </Button>
          <SearchBar onChange={handleFilterSearch} />
        </Box>
        {loadedData.length > 0 && (
          <Box
            style={{
              width: '1150px',
              backgroundColor: 'white',
              marginLeft: '20px',
              borderRadius: '4px',
              boxShadow: '0px 0px 10px 2px rgb(222, 222, 222)',
              margin: 'auto',
            }}
          >
            <OrdersTable orders={loadedData} />
          </Box>
        )}
      </>
    </Page>
  );
};
