import Box from '@mui/material/Box';
import { ReactNode } from 'react';

export const PageHeader = (props: { children: ReactNode }) => {
  return (
    <Box
      sx={{
        width: '100%',
        height: '92px',
        overflow: 'hidden',
        display: 'flex',
        alignContent: 'flex-start',
        flexDirection: 'column',
        pr: '20px',
      }}
    >
      {props.children}
    </Box>
  );
};
