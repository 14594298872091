import { Box } from '@mui/material';
import { Navbar } from '../common/header/Navbar';

export const StockErrorPage = () => {
  return (
    <div>
      <Navbar />
      <Box
        sx={{
          width: '650px',
          height: '400px',
          margin: 'auto',
          marginTop: '50px',
        }}
      >
        <p
          style={{
            fontSize: '30px',
            fontWeight: '600',
            textAlign: 'center',
          }}
        >
          Lack of stock
        </p>
        <p
          style={{
            textAlign: 'center',
          }}
        >
          Some items in your cart have run out of stock during the time you
          placed your order.
        </p>
        <p
          style={{
            textAlign: 'center',
            marginTop: '40px',
          }}
        >
          We apologize for any inconvenience and appreciate your understanding.
        </p>
        <p
          style={{
            textAlign: 'center',
          }}
        >
          Please try making a new order or contact our team.
        </p>
      </Box>
    </div>
  );
};
