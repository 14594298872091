import { Box, Typography } from '@mui/material';
import Papa from 'papaparse';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import { FunctionComponent } from 'react';

export const UpdateProductsStock: FunctionComponent = () => {
  const handleCsvSubmit = (e: any) => {
    const file = e.target.files[0];
    if (file) {
      Papa.parse(file, {
        complete: function (results: any) {},
      });
    }
  };
  return (
    <Box>
      <Box height={130} mt={3}>
        <Typography
          sx={{ fontFamily: 'Roboto', fontSize: 16, fontWeight: 300 }}
        >
          - Download an example CSV file to understand the required format. You
          can get it here.
        </Typography>
        <Typography
          sx={{ fontFamily: 'Roboto', fontSize: 16, fontWeight: 300 }}
        >
          - Respect precisely the format requested or your uploades CSV can
          create stock errors.
        </Typography>
        <Typography
          sx={{ fontFamily: 'Roboto', fontSize: 16, fontWeight: 300 }}
        >
          - Finally, import the updated CSV file.
        </Typography>
      </Box>

      <form>
        <input
          id={'csvFileInput'}
          type={'file'}
          style={{ display: 'none' }}
          accept={'.csv'}
          onChange={handleCsvSubmit}
        />
        <label htmlFor="csvFileInput">
          <div
            className="importProducts_div"
            style={{
              margin: 'auto',
              width: '500px',
              height: '180px',
              overflow: 'auto',
              marginTop: '50px',
              border: '2px dashed #424242',
              borderRadius: '5px',
            }}
          >
            <div style={{ margin: 'auto', width: '70px', marginTop: '30px' }}>
              <UploadFileIcon
                sx={{
                  color: '#424242',
                  fontSize: '70px',
                }}
              />
            </div>
            <p
              style={{
                fontSize: '18px',
                textAlign: 'center',
                fontFamily: 'Roboto',
              }}
            >
              Select CSV file
            </p>
          </div>
        </label>
      </form>
    </Box>
  );
};
