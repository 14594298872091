import { Box, Button } from '@mui/material';
import { FunctionComponent } from 'react';

interface PageTitleV2Props {
  title: string;
}

export const PageSubmit: FunctionComponent<PageTitleV2Props> = ({ title }) => {
  return (
    <Box sx={{ mb: '20px' }}>
      <Button type="submit" variant="contained" sx={{ width: '150px' }}>
        {title}
      </Button>
    </Box>
  );
};
