import './styles/AdminUsers.css';
import { useEffect, useState, useContext } from 'react';
import { Authcontext } from '../../../auth/context/auth-context';
import Loading2 from '../common/loading/Loading2';
import { GETauthrequest } from '../../../common/functions/requests';
import { changeDialogVisibility } from '../../../redux/actions/dialogActions';
import { useDispatch } from 'react-redux';
import { UsersTable } from './components/UsersTable';
import { Page } from '../common/structure/Page';
import { PageHeader } from '../common/structure/PageHeader';
import { Box, Button } from '@mui/material';
import { PageTitle } from '../common/structure/PageTitle';
import { SearchBar } from '../common/SearchBar';
import { useHistory } from 'react-router-dom';
import { PageSubTitle } from '../common/structure/PageSubTitle';

export const UsersListPage = () => {
  const auth = useContext(Authcontext);
  const history = useHistory();
  const dispatch = useDispatch();
  const [loadedData, setLoadedData] = useState();
  const [users, setUsers] = useState<any>();

  useEffect(() => {
    dispatch(changeDialogVisibility(true));

    GETauthrequest(auth, '/users').then((res) => {
      setUsers(res);
      setLoadedData(res);
    });
  }, [auth, dispatch]);

  function search(e: any) {
    setLoadedData(
      users &&
        users.filter(
          (user: any) =>
            user.name.toUpperCase().startsWith(e.target.value.toUpperCase()) ||
            user.email.toUpperCase().startsWith(e.target.value.toUpperCase())
        )
    );
  }

  return (
    <>
      {!loadedData && <Loading2 />}
      <Page>
        <PageHeader>
          <PageTitle title="Users" />
          <PageSubTitle title="Manage your user accounts" />
        </PageHeader>
        <Box sx={{ height: '60px', width: '100%', marginRight: '20px' }}>
          <Button
            variant="contained"
            sx={{
              float: 'left',
              mr: '10px',
              backgroundColor: '#424242',
              borderColor: '#424242',
              '&:hover': {
                backgroundColor: '#9E9E9E',
                borderColor: '#9E9E9E',
              },
            }}
            onClick={() => history.push('/admin/users/adduser')}
          >
            Create User
          </Button>
          <Button
            variant="outlined"
            sx={{
              float: 'left',
              color: 'white',
              backgroundColor: '#424242',
              borderColor: '#424242',
              '&:hover': {
                backgroundColor: '#9E9E9E',
                borderColor: '#9E9E9E',
              },
            }}
            onClick={() => history.push('/admin/users/importUsers')}
          >
            Import Users
          </Button>
          <SearchBar onChange={search} />
        </Box>
        {loadedData && (
          <div className="admin_users_div">
            <UsersTable users={loadedData} />
          </div>
        )}
      </Page>
    </>
  );
};
