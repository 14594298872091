import { Navbar } from '../common/header/Navbar';
import { Box } from '@mui/material';

export const PurchaseConfirmationPage = () => {
  return (
    <Box>
      <Navbar />
      <Box
        sx={{
          width: '500px',
          height: '400px',
          margin: 'auto',
          marginTop: '50px',
        }}
      >
        <p
          style={{
            fontSize: '30px',
            fontWeight: '600',
            textAlign: 'center',
          }}
        >
          Thank you
        </p>
        <p
          style={{
            textAlign: 'center',
          }}
        >
          Your order will be reviewed and we will keep in touch with you
        </p>
      </Box>
    </Box>
  );
};
