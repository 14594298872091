import { Box, Button, Paper, Typography } from '@mui/material';
import { FunctionComponent, useEffect, useState } from 'react';
import defaultProductImage from '../../../../images/default_image.png';
import { useDispatch, useSelector } from 'react-redux';
import { addProductToShopcart } from '../../../../redux2/shopcart';
import { getTotalCost } from '../../common/functions/getTotalCost';
import { getTotalQuantity } from '../../common/functions/getTotalQuantity';
import { QuantitySelector } from '../../common/components/QuantitySelector';

interface ProductQuantitySelectorProps {
  product: any;
}

export const ProductQuantitySelectorCatalog: FunctionComponent<
  ProductQuantitySelectorProps
> = ({ product }) => {
  const [totalQuantity, setTotalQuantity] = useState<any>(0);
  const [totalCost, setTotalCost] = useState<any>(0);

  const cart = useSelector((state: any) => state.shopcart.cart);

  const isProductInCart = cart.some(
    (shopcartProduct: any) => shopcartProduct.id === product.id
  );

  const dispatch = useDispatch();

  // prepare body of shopcart product
  let shopcartProductVariants: any = product.variants.map((variant: any) => {
    return {
      size: variant.size,
      price: variant.price,
      quantity: 0,
    };
  });

  const [shopcartProduct, setShopcartProduct] = useState<any>({
    id: product.id,
    variants: shopcartProductVariants,
  });

  function updateQuantity(size: any, qty: number, price?: number) {
    const variantIndex = shopcartProduct.variants.findIndex(
      (variant: any) => variant.size === size
    );
    let variants = [...shopcartProduct.variants];
    variants[variantIndex].quantity = qty;
    setShopcartProduct({
      id: shopcartProduct.id,
      variants,
    });
  }

  useEffect(() => {
    setTotalCost(getTotalCost(shopcartProduct.variants));
    setTotalQuantity(getTotalQuantity(shopcartProduct.variants));
  }, [shopcartProduct]);

  return (
    <Paper
      sx={{
        width: '910px',
        height: '180px',
        mb: '20px',
        ml: '10px',
        boxShadow: '0px 0px 10px 2px rgb(222, 222, 222)',
      }}
      elevation={0}
    >
      <Box sx={{ width: '180px', float: 'left' }}>
        <img
          src={product.media[0].url ?? defaultProductImage}
          alt="product"
          style={{
            width: '160px',
            height: '160px',
            marginTop: '10px',
            marginLeft: '10px',
            borderRadius: '3px',
          }}
        />
      </Box>
      <Box sx={{ width: '150px', float: 'left' }}>
        <Typography
          sx={{ mt: '10px', ml: '10px', color: 'grey', fontSize: '13px' }}
        >
          {product.id}
        </Typography>
        <Typography
          sx={{
            mt: '10px',
            ml: '10px',
            fontSize: '23px',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          }}
        >
          {product.title}
        </Typography>
        <Typography sx={{ ml: '10px' }}>{product.color}</Typography>
      </Box>
      <Box
        sx={{
          width: '560px',
          mt: '10px',
          float: 'left',
        }}
      >
        <div
          style={{
            height: '130px',
            width: '100%',
          }}
          className="scrolling-container"
        >
          {!isProductInCart &&
            product.variants.map((variant: any) => (
              <QuantitySelector
                key={variant.size}
                size={variant.size}
                stock={variant.stock}
                price={variant.price}
                pvp={variant.pvp}
                updateQuantity={updateQuantity}
              />
            ))}
        </div>
        <Box
          sx={{
            width: '100%',
            height: '35px',
            float: 'left',
          }}
        >
          <Box
            sx={{
              width: '120px',
              height: '20px',
              float: 'right',
              ml: '30px',
            }}
          >
            {isProductInCart ? (
              <Typography sx={{ fontSize: '17px', mt: '4px' }}>
                Product in cart
              </Typography>
            ) : (
              <Button
                variant="contained"
                className="button"
                size="small"
                disabled={totalQuantity === 0 || isProductInCart}
                style={{ width: '100%', marginBottom: '30px' }}
                onClick={() => dispatch(addProductToShopcart(shopcartProduct))}
              >
                ADD TO CART
              </Button>
            )}
          </Box>
          <Box
            sx={{
              width: '120px',
              height: '30px',
              float: 'right',
            }}
          >
            <Typography
              sx={{
                ml: '5px',
                mt: '7px',
                fontSize: '14px',
                color: 'black',
              }}
            >
              Cost: {Number(totalCost).toFixed(2)}€
            </Typography>
          </Box>
          <Box
            sx={{
              width: '120px',
              height: '30px',
              mr: '10px',
              float: 'right',
            }}
          >
            <Typography
              sx={{
                ml: '5px',
                mt: '7px',
                fontSize: '14px',
                color: 'black',
              }}
            >
              Quantity: {totalQuantity}
            </Typography>
          </Box>
        </Box>
      </Box>
    </Paper>
  );
};
