import { Image } from './models/Image';
import { Product } from './models/Product';

/*
index 0 - title
index 1 - color
index 2 - id
index 3 - syncShopify
index 4 - tags
index 5 - category
index 6 - description
index 7 - materials
index 8 - images
index 9 - size
index 10 - stock
index 11 - price
index 12 - pvp
index 13 - barcode
*/

export function aggregateProducts(rows: any): Product[] {
  // validate header = index 0

  let products: Product[] = [];

  let product: any;
  let variantPosition = 1;
  for (let rowIndex = 1; rowIndex < rows.length; rowIndex++) {
    // if it's product info row
    if (rows[rowIndex][0] !== '') {
      // if it's not first row, add mapped product to array
      if (rowIndex !== 1) {
        products.push(product);
        variantPosition = 1;
      }
      product = {
        title: rows[rowIndex][0].trim(),
        color: rows[rowIndex][1].trim(),
        id: rows[rowIndex][2].trim(),
        shopifySynchronized: rows[rowIndex][3] === 'Yes' ? true : false,
        tags: getTags(rows[rowIndex][4]),
        category: rows[rowIndex][5].trim(),
        description: rows[rowIndex][6],
        materials: rows[rowIndex][7],
        media: getImages(rows[rowIndex][8]),
        variants: [
          {
            size: rows[rowIndex][9],
            position: variantPosition,
            stock: Number(rows[rowIndex][10]),
            price: Number(rows[rowIndex][11]),
            pvp: Number(rows[rowIndex][12]),
            barcode: Number(rows[rowIndex][13]),
          },
        ],
      };
    }
    // if it's product info row
    else {
      variantPosition++;
      product.variants.push({
        size: rows[rowIndex][9],
        position: variantPosition,
        stock: Number(rows[rowIndex][10]),
        price: Number(rows[rowIndex][11]),
        pvp: Number(rows[rowIndex][12]),
        barcode: Number(rows[rowIndex][13]),
      });
    }
  }
  // Add last product to array of products
  products.push(product);

  return products;
}

function getTags(tags: string) {
  const arrayOfTags = tags.split(',');
  const arrayOfTagsWithoutSpaces = arrayOfTags.map((tag) => {
    return tag.trim();
  });
  return arrayOfTagsWithoutSpaces;
}

function getImages(images: string): Image[] {
  console.log('IMAGES');
  console.log(images);
  const imagesExist = images !== '';
  const arrayOfImages = images.split(',');
  let position = 0;
  const arrayOfImagesWithoutSpaces = arrayOfImages.slice(0, 4).map((image) => {
    position++;
    let imageUrl =
      'https://storage.googleapis.com/react-hosting-73546.appspot.com/images/' +
      image.trim() +
      '.jpg';
    return {
      position,
      isMainImage: position === 1 ? true : false,
      url: imageUrl,
    };
  });
  return imagesExist ? arrayOfImagesWithoutSpaces : [];
}
