export function sortProducts(products: any) {
  return products.sort((a: any, b: any) => {
    // Extract the numerical part of the IDs (e.g., "4172" from "G4172_A26D16")
    const idA = parseInt(a.id.match(/\d+/)[0], 10);
    const idB = parseInt(b.id.match(/\d+/)[0], 10);

    if (idA < idB) {
      return 1; // 'a' should come before 'b'
    } else if (idA > idB) {
      return -1; // 'a' should come after 'b'
    } else {
      return 0; // IDs are equal, order doesn't matter
    }
  });
}
