import { Box, Button } from '@mui/material';
import { PageTitle } from '../common/structure/PageTitle';
import { SearchBar } from '../common/SearchBar';
import { useContext, useEffect, useState } from 'react';
import { useGetAllProducts } from '../../../repositories/products/useGetAllProducts';
import { CreateOrderSelector } from '../../user/common/components/CreateOrderSelector';
import { getTotalShopcartQuantity } from '../../user/shopcartV2/functions/getTotalShopcartQuantity';
import { getTotalShopcartValue } from '../../user/shopcartV2/functions/getTotalShopcartValue';
import { InputColumn } from '../common/structure/InputColumn';
import { InputComponent } from '../common/structure/InputComponent';
import { InputV2 } from '../common/structure/InputV2';
import { PageV2 } from '../common/structure/PageV2';
import { ProductDto } from '../../../dtos/ProductDto';
import { useHistory } from 'react-router-dom';
import { Authcontext } from '../../../auth/context/auth-context';

export const CreateOrderPage = () => {
  const auth = useContext(Authcontext);
  const history = useHistory();

  const [filteredProducts, setFilteredProducts] = useState<ProductDto[]>([]);

  const { data: products, isLoading } = useGetAllProducts();

  useEffect(() => {
    if (!isLoading) {
      if (products) {
        setFilteredProducts(products);
      }
    }
  }, [isLoading, products]);

  function handleFilterSearch(e: { target: { value: string } }) {
    if (products) {
      setFilteredProducts(
        products.filter(
          (product: any) =>
            product.title
              .toUpperCase()
              .startsWith(e.target.value.toUpperCase()) ||
            product.color
              .toUpperCase()
              .startsWith(e.target.value.toUpperCase()) ||
            product.id.toUpperCase().startsWith(e.target.value.toUpperCase())
        )
      );
    }
  }

  const [cart, setCart] = useState<any>([]);

  function updateCart(newProduct: any) {
    // if product is already in the cart
    let isProductInCart = false;
    cart.forEach((cartProduct: any) => {
      if (cartProduct.id === newProduct.id) {
        isProductInCart = true;
      }
    });

    // if product is already in cart
    if (isProductInCart) {
      const existentProduct = cart.find((product: any) => {
        return product.id === newProduct.id;
      });

      let isVariantExistent = false;

      existentProduct.variants.forEach((variant: any) => {
        if (variant.size === newProduct.variants[0].size) {
          isVariantExistent = true;
        }
      });

      // if variant already exists
      if (isVariantExistent) {
        const newVariants = existentProduct.variants.map((variant: any) => {
          if (variant.size === newProduct.variants[0].size) {
            if (newProduct.variants[0].quantity !== 0) {
              return newProduct.variants[0];
            } else {
              return undefined;
            }
          }
          return variant;
        });

        const finalArray = newVariants.filter(
          (item: any) => item !== undefined
        );

        existentProduct.variants = finalArray;

        const newCart = cart.map((p: any) => {
          if (p.id === existentProduct.id) {
            if (existentProduct.variants.length !== 0) {
              return existentProduct;
            } else {
              return undefined;
            }
          }
          return p;
        });

        const newCart2 = newCart.filter((item: any) => item !== undefined);

        setCart(newCart2);
      }
      // if variant does not exist
      else {
        existentProduct.variants = [
          ...existentProduct.variants,
          newProduct.variants[0],
        ];

        const newCart = cart.map((p: any) => {
          if (p.id === existentProduct.id) {
            return existentProduct;
          }
          return p;
        });

        setCart(newCart);
      }
    }
    // if product is not in cart
    else {
      setCart([...cart, newProduct]);
    }
  }

  const totalShopcartQuantity = getTotalShopcartQuantity(cart);
  const totalShopcartValue = getTotalShopcartValue(cart);

  const [email, setEmail] = useState<string>('');
  const [name, setName] = useState<string>('');

  //const [errorVisible, setErrorVisible] = useState<boolean>(false);

  async function createOrder() {
    if (email === '' || name === '') {
      //setErrorVisible(true);
    }
    // send order to backend
    else {
      const order = {
        isAdminOrder: true,
        userInformation: {
          discount: 0,
          email,
          name,
          shippingAddresses: [],
        },
        note: undefined,
        store: undefined,
        shopcartProducts: cart,
        quantity: totalShopcartQuantity,
        cost: totalShopcartValue,
      };

      try {
        const response = await fetch(
          process.env.REACT_APP_BACKEND_URL + '/orders',
          {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${auth.token}`,
            },
            body: JSON.stringify(order),
          }
        );

        await response.json();
        history.push('/admin/orders');
      } catch (err) {}
    }
  }

  return (
    <PageV2>
      <>
        <>
          <Box
            sx={{
              width: '49%',
              float: 'left',
            }}
          >
            <PageTitle title="Create order" />
          </Box>
          <Box
            sx={{
              width: '49%',
              float: 'left',
              paddingTop: '15px',
              paddingRight: '20px',
            }}
          >
            <SearchBar onChange={handleFilterSearch} />
          </Box>
        </>
        <Box sx={{ float: 'left' }}>
          <InputColumn>
            <Button
              variant="contained"
              sx={{ mt: '11px' }}
              onClick={createOrder}
            >
              Create Order
            </Button>
            <InputComponent
              hasTitle={true}
              title="Client information"
              sx={{ mt: '20px' }}
            >
              <InputV2
                name="name"
                label="Name"
                changeHandler={(event: any) => setName(event.value)}
              />
              <InputV2
                name="email"
                label="Email"
                changeHandler={(event: any) => setEmail(event.value)}
              />
            </InputComponent>
          </InputColumn>
          <InputColumn>
            <InputComponent
              hasTitle={true}
              title="Cart"
              sx={{ mt: '68px', ml: '20px' }}
            >
              <>
                <div
                  style={{
                    width: '560px',
                    marginLeft: '15px',
                    marginTop: '15px',
                    height: '38px',
                    border: '1px solid #CCCCCC',
                    borderRadius: '4px',
                    marginBottom: '10px',
                  }}
                >
                  <p
                    style={{
                      fontSize: '13px',
                      fontFamily: 'Source Code Pro',
                      fontWeight: '500',
                      marginTop: '10px',
                      marginLeft: '15px',
                    }}
                  >
                    Quantity: {totalShopcartQuantity}
                  </p>
                </div>
                <div
                  style={{
                    width: '560px',
                    marginLeft: '15px',
                    marginTop: '15px',
                    height: '38px',
                    border: '1px solid #CCCCCC',
                    borderRadius: '4px',
                  }}
                >
                  <p
                    style={{
                      fontSize: '13px',
                      fontFamily: 'Source Code Pro',
                      fontWeight: '500',
                      marginTop: '10px',
                      marginLeft: '15px',
                    }}
                  >
                    Cost: {totalShopcartValue}€
                  </p>
                </div>
              </>
            </InputComponent>
          </InputColumn>
        </Box>
        <Box
          style={{
            width: '1200px',
            float: 'left',
          }}
        >
          {filteredProducts.map((product: any) => (
            <CreateOrderSelector
              shopcartProduct={product}
              key={product.id}
              updateCart={updateCart}
            />
          ))}
        </Box>
      </>
    </PageV2>
  );
};
