import Box from '@mui/material/Box';
import { DataGrid } from '@mui/x-data-grid';
import { useHistory } from 'react-router-dom';

const columns = [
  { field: 'id', headerName: 'Order ID', width: 180 },
  {
    field: 'date',
    headerName: 'Date',
    width: 150,
  },
  {
    field: 'clientId',
    headerName: 'Client',
    width: 250,
  },
  {
    field: 'email',
    headerName: 'Email',
    width: 290,
  },
  {
    field: 'quantity',
    headerName: 'Quantity',
    width: 115,
    type: 'number',
  },
  {
    field: 'cost',
    headerName: 'Cost',
    description: 'This column has a value getter and is not sortable.',
    width: 135,
    type: 'number',
    valueGetter: (params: any) => parseFloat(params.row.cost).toFixed(2),
    renderCell: (params: any) => `${parseFloat(params.row.cost).toFixed(2)} €`,
  },
];

export const OrdersTable = ({ orders }: any) => {
  const history = useHistory();

  const rows = orders.map((order: any) => {
    return {
      id: order.id,
      date: order.date,
      clientId: order.userInformation.name ? order.userInformation.name : '',
      email: order.userInformation.email ? order.userInformation.email : '',
      quantity: order.quantity,
      cost: order.cost,
    };
  });

  return (
    <Box sx={{ height: '75vh', width: '100%' }}>
      <DataGrid
        columns={columns}
        rows={rows}
        pageSize={10}
        rowsPerPageOptions={[10]}
        getRowId={(row) => `${row.id}`}
        disableSelectionOnClick
        onRowClick={(row) => {
          history.push(`/admin/orders/${row.id}`);
        }}
        sx={{ cursor: 'pointer' }}
      />
    </Box>
  );
};
