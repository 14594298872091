export function clearEmptySizes(shopcartProducts: any) {
  const clearedShopcartProducts = shopcartProducts.map(
    (shopcartProduct: any) => {
      const clearedVariants = shopcartProduct.variants.filter(
        (variant: any) => {
          return variant.quantity !== 0;
        }
      );
      return { id: shopcartProduct.id, variants: clearedVariants };
    }
  );

  return clearedShopcartProducts;
}
