import { Box, Button, TextField } from '@mui/material';
import { useForm } from 'react-hook-form';
import logo from '../images/logo.png';
import { Link, useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { showError, showSuccess } from '../redux2/snackbar';

export const RequestPasswordRecoveryPage = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { register, handleSubmit } = useForm({
    defaultValues: { email: '' },
  });

  async function onSubmit(data: any) {
    const url =
      process.env.REACT_APP_BACKEND_URL + '/auth/recover-password-request';

    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    });
    let responseData = await response.json();

    if (responseData.success) {
      dispatch(showSuccess(responseData.message));
      history.push('/admin/catalog');
    } else {
      dispatch(showError(responseData.message));
    }
  }

  return (
    <Box
      sx={{
        width: '400px',
        height: '500px',
        margin: 'auto',
        marginTop: '20px',
      }}
    >
      <div className="div-login-img">
        <img src={logo} alt="logo"></img>
      </div>
      <h2
        style={{
          fontFamily: 'Source Code Pro',
          textAlign: 'center',
          marginBottom: '20px',
          fontSize: '20px',
          marginTop: '100px',
        }}
      >
        Password Recovery
      </h2>
      <Box component="form" onSubmit={handleSubmit(onSubmit)}>
        <TextField
          label="Email"
          variant="outlined"
          size="small"
          sx={{ width: '100%', marginTop: '20px' }}
          {...register('email')}
        />
        <Box textAlign="center">
          <Button
            className="button"
            variant="contained"
            type="submit"
            sx={{
              margin: 'auto',
              marginTop: '30px',
              backgroundColor: '#424242',
              borderColor: '#424242',
              '&:hover': {
                backgroundColor: '#9E9E9E',
                borderColor: '#9E9E9E',
              },
            }}
          >
            Recover password
          </Button>
          <Link to="/">
            <Button
              variant="outlined"
              sx={{
                ml: '20px',
                marginTop: '30px',
                color: 'black',
                borderColor: '#424242',
                '&:hover': {
                  borderColor: '#9E9E9E',
                },
              }}
            >
              Back to Login
            </Button>
          </Link>
        </Box>
      </Box>
    </Box>
  );
};
