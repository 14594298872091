import Paper from '@mui/material/Paper';
import InputBase from '@mui/material/InputBase';
import IconButton from '@mui/material/IconButton';
import SearchIcon from '@mui/icons-material/Search';

export const SearchBar = ({ onChange }: any) => {
  return (
    <Paper
      component="form"
      sx={{
        display: 'flex',
        width: 300,
        float: 'right',
        marginTop: '0px',
        height: '35px',
      }}
    >
      <InputBase
        size="small"
        sx={{ ml: 1, flex: 1 }}
        placeholder="Search"
        inputProps={{ roboto: 'Search' }}
        onChange={onChange}
      />
      <IconButton type="button" sx={{ p: '10px' }} aria-label="search">
        <SearchIcon />
      </IconButton>
    </Paper>
  );
};
