import { useContext, useEffect, useState } from 'react';
import { Page } from '../common/structure/Page';
import { PageHeader } from '../common/structure/PageHeader';
import { PageTitle } from '../common/structure/PageTitle';
import { DashboardComponent } from '../dashboard/components/DashboardComponent';
import { Authcontext } from '../../../auth/context/auth-context';
import Loading2 from '../common/loading/Loading2';
import { ShopifyComponent } from './components/ShopifyComponent';
import { Box } from '@mui/material';
import { ProductStatus } from './components/ProductStatus';
import products from '../../../permanentImages/products.png';
import sync from '../../../permanentImages/sync.png';
import unsync from '../../../permanentImages/unsync.png';
import inventory from '../../../permanentImages/inventory.png';
import { PageSubTitle } from '../common/structure/PageSubTitle';

interface SynchronizationOverview {
  numberOfProducts: number;
  numberOfSyncedProducts: number;
  numberOfUnsyncedProducts: number;
}

export const ShopifyPage = () => {
  const auth = useContext(Authcontext);

  const [isLoading, setIsLoading] = useState(true);

  const [synchronizationData, setSynchronizationData] =
    useState<SynchronizationOverview>();

  const [productsList, setProductsList] = useState<any>([]);
  const [showProductList, setShowProductList] = useState<boolean>(false);

  useEffect(() => {
    const sendRequest = async () => {
      try {
        const response = await fetch(
          process.env.REACT_APP_BACKEND_URL + '/shopify-status/overview',
          {
            method: 'GET',
            headers: {
              Authorization: `Bearer ${auth.token}`,
            },
          }
        );

        const shopifyOverview = await response.json();

        setSynchronizationData(shopifyOverview);
        setIsLoading(false);
      } catch (err) {}
    };
    sendRequest();
  }, [auth.token]);

  const getInventoryStockStatus = async () => {
    try {
      const response = await fetch(
        process.env.REACT_APP_BACKEND_URL +
          '/shopify-status/check-product-synchronization',
        {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${auth.token}`,
          },
        }
      );

      const products = await response.json();

      console.log(products);

      setShowProductList(true);
      setProductsList(products);
      setIsLoading(false);
    } catch (err) {}
  };

  function startSynchronizationCheck() {
    setIsLoading(true);
    getInventoryStockStatus();
  }

  return (
    <>
      {!synchronizationData ? (
        <Loading2 />
      ) : (
        <Page>
          <>
            {isLoading && <Loading2 />}
            <PageHeader>
              <PageTitle title="Shopify" />
              <PageSubTitle title="Overview of your shopify store" />
            </PageHeader>
            <>
              <DashboardComponent
                label="Products"
                value={synchronizationData.numberOfProducts}
                icon={products}
              />
              <DashboardComponent
                label="Synchronized"
                value={synchronizationData.numberOfSyncedProducts}
                icon={sync}
              />
              <DashboardComponent
                label="Not Synchronized"
                value={synchronizationData.numberOfUnsyncedProducts}
                icon={unsync}
              />
              <ShopifyComponent
                startSynchronizationCheck={startSynchronizationCheck}
                disableButton={isLoading}
                icon={inventory}
              />
            </>

            {showProductList ? (
              <Box sx={{ width: '100%' }}>
                {productsList.length !== 0 ? (
                  productsList.map((product: any) => {
                    return <ProductStatus product={product} />;
                  })
                ) : (
                  <p style={{ textAlign: 'center', fontSize: '25px' }}>
                    All products are correctly synchronized
                  </p>
                )}
              </Box>
            ) : (
              <span></span>
            )}

            {/*
            {productsWithShopifyStatus && !isProgressBarVisible && (
              <Box sx={{ width: '100%', height: '400px' }}>
                <p
                  style={{
                    marginTop: '30px',
                    fontFamily: 'Roboto',
                    fontWeight: '400',
                    fontSize: '20px',
                  }}
                >
                  Inventory Synchronization Status
                </p>
                <Box sx={{ width: '100%', height: '110px' }}>
                  <DashboardComponent
                    label="Products correctly synchronized"
                    value={numberOfProductsWithNoErrors}
                    sx={{ width: '565px' }}
                  />
                  <DashboardComponent
                    label="Products not correctly synchronized"
                    value={numberOfProductsWithErrors}
                    sx={{ width: '565px', mr: 0 }}
                  />
                </Box>
                <p
                  style={{
                    marginTop: '30px',
                    fontFamily: 'Source Code Pro',
                    fontWeight: '400',
                    fontSize: '20px',
                  }}
                >
                  List of products with a synchronization problem
                </p>
                {productsWithShopifyStatus
                  .filter((product: any) => !product.isSynchronized)
                  .map((product: any, index: number) => {
                    return <ProductStatus product={product} index={index} />;
                  })}
              </Box>
            )}
                */}
          </>
        </Page>
      )}
    </>
  );
};
