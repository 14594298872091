import './styles/Product.css';
import { Link } from 'react-router-dom';
import { useState } from 'react';
import { FiEdit, FiTrash2 } from 'react-icons/fi';
import { Paper, Typography } from '@mui/material';

export const ProductCard = ({ product }) => {
  const [show, setShow] = useState(false);

  let prices = [];

  product.variants.forEach((variant) => {
    if (variant.price !== 0 && Number(variant.price))
      prices.push(variant.price);
  });

  const maxPrice = parseFloat(Math.max.apply(null, prices)).toFixed(2);
  const minPrice = parseFloat(Math.min.apply(null, prices)).toFixed(2);

  var categoryName = product.category.split(' ')[0];

  const imageUrl = product?.media[0]?.url ?? '';

  return (
    <Link to={`/admin/catalog/editproduct/${product.id}`}>
      <Paper
        className="admin-div-product"
        onMouseEnter={() => setShow(true)}
        onMouseLeave={() => setShow(false)}
      >
        <div className="admin-imagem_produto">
          <img src={imageUrl} alt="logo" />
        </div>
        {show ? (
          <div className="admin-icons-edit_trash">
            {' '}
            <FiEdit className="admin-icons-edit_trash-edit" />{' '}
            <FiTrash2 className="admin-icons-edit_trash-trash" />{' '}
          </div>
        ) : (
          <div>
            <div className="sku_and_categorie">
              <p className="div_produto-categorie">{categoryName}</p>
              <p className="div_produto-sku">{product.id}</p>
            </div>
            <div style={{ height: '30px' }}>
              <Typography
                sx={{
                  width: '200px',
                  fontSize: '14px',
                  pt: 0,
                  mt: 0,
                  fontWeight: 500,
                  marginBottom: 0,
                  color: 'black',
                  fontFamily: 'Source Code Pro',
                  marginLeft: '13px',
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                }}
              >
                {product.title + ' ' + product.color}
              </Typography>
            </div>
            <p className="admin-div_produto-price">
              {minPrice}€ - {maxPrice}€
            </p>
            {product.active ? (
              <div className="Shopify_synchronized_div">
                <div className="admin-div_produto-shopify-circle"></div>
                <p className="admin-div_produto-shopify">Active</p>
              </div>
            ) : (
              <div className="Shopify_synchronized_div">
                <div className="not_admin-div_produto-shopify-circle"></div>
                <p className="not_admin-div_produto-shopify">Draft</p>
              </div>
            )}
            {product.shopifySynchronized ? (
              <div className="Shopify_synchronized_div">
                <div className="admin-div_produto-shopify-circle"></div>
                <p className="admin-div_produto-shopify">Synced with Shopify</p>
              </div>
            ) : (
              <div className="Shopify_synchronized_div">
                <div className="not_admin-div_produto-shopify-circle"></div>
                <p className="not_admin-div_produto-shopify">
                  Not synced with Shopify
                </p>
              </div>
            )}
          </div>
        )}
      </Paper>
    </Link>
  );
};
