export function getTotalShopcartQuantity(shopcartProducts: any[]) {
  let totalQuantity = 0;

  shopcartProducts.forEach((shopcartProduct: any) => {
    shopcartProduct.variants.forEach((variant: any) => {
      if (variant.quantity) {
        totalQuantity += variant.quantity;
      }
    });
  });

  return totalQuantity;
}
