import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';

export interface userInformationSliceState {
  discount: number;
  email: string;
  name: string;
  shippingAddresses: string[];
}

const initialState: userInformationSliceState = {
  discount: 1,
  email: '',
  name: '',
  shippingAddresses: [],
};

export const userInformationSlice = createSlice({
  name: 'userInformation',
  initialState,
  reducers: {
    updateUserInformation: (
      state,
      action: PayloadAction<userInformationSliceState>
    ) => {
      state.discount = action.payload.discount;
      state.email = action.payload.email;
      state.name = action.payload.name;
      state.shippingAddresses = [...action.payload.shippingAddresses];
    },
  },
});

export const { updateUserInformation } = userInformationSlice.actions;

export default userInformationSlice.reducer;
