import { useContext, useEffect, useState } from 'react';
import { Authcontext } from '../../auth/context/auth-context';
import { apiRequest } from '../common/apiRequest';
import { Method } from '../common/Method';

export const useCheckRecoveryPasswordCode = (code: string) => {
  const auth = useContext(Authcontext);

  const [data, setData] = useState<any>();
  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    const getRecoverPasswordRequestCode = async () => {
      const response = await apiRequest(
        `/auth/recover-password-request/checkCode`,
        Method.PUT,
        auth,
        JSON.stringify({
          code: code,
        })
      );

      console.log(response);

      setData(response.success);
      setIsLoading(false);
    };
    getRecoverPasswordRequestCode();
  }, [auth, code]);

  return { data, isLoading };
};
