import { Method } from './Method';

export const apiRequest = async (
  url: string,
  method: Method,
  auth: any,
  body?: any
) => {
  try {
    const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}${url}`, {
      method,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${auth.token}`,
      },
      body,
    });

    const data = await response.json();
    return data;
  } catch (error) {
    return;
  }
};
