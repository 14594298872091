import { useContext, useEffect } from 'react';
import { PageV2 } from '../common/structure/PageV2';
import { PageHeaderV2 } from '../common/structure/PageHeaderV2';
import { InputColumn } from '../common/structure/InputColumn';
import { UpdateGeneralInformation } from './componentsV2/UpdateGeneralInformation';
import { UpdateProductDetails } from './componentsV2/UpdateProductDetails';
import { UpdateProductStatus } from './componentsV2/UpdateProductStatus';
import { UpdateMediaComponent } from './media/UpdateMediaComponent';
import { VariantsComponent } from './components/VariantsComponent';
import { useGetProductById } from '../../../repositories/products/useGetProductById';
import { useHistory, useParams } from 'react-router-dom';
import { PageSubmit } from '../common/structure/PageSubmit';
import { useForm } from 'react-hook-form';
import { Box } from '@mui/material';
import { useDispatch } from 'react-redux';
import { showError, showSuccess } from '../../../redux2/snackbar';
import { Authcontext } from '../../../auth/context/auth-context';
import { ProductDto } from '../../../dtos/ProductDto';

export const UpdateProductPage = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const auth = useContext(Authcontext);

  const productId = useParams<any>().productId;

  const { data: product, isLoading } = useGetProductById(productId);

  const { register, watch, reset, setValue, handleSubmit } = useForm({
    defaultValues: product,
  });

  const variants = watch('variants') ?? [];

  useEffect(() => {
    if (!isLoading) {
      reset(product);
    }
  }, [isLoading, product, reset]);

  async function onSubmit(productDto: ProductDto) {
    try {
      const response = await fetch(
        process.env.REACT_APP_BACKEND_URL + `/products/${product.id}`,
        {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${auth.token}`,
          },
          body: JSON.stringify(productDto),
        }
      );
      if (response.ok) {
        dispatch(showSuccess('Product updated'));
      } else {
        dispatch(showError('Product update failed'));
      }
      history.push('/admin/catalog');
    } catch (err) {
      dispatch(showError('Product update failed'));
      history.push('/admin/catalog');
    }
  }

  if (!isLoading) {
    return (
      <PageV2>
        <PageHeaderV2 title="Update product" />
        <Box component="form" onSubmit={handleSubmit(onSubmit)}>
          <PageSubmit title={'Update'} />
          <InputColumn>
            <UpdateGeneralInformation register={register} />
            <UpdateProductDetails
              register={register}
              watch={watch}
              setValue={setValue}
            />
          </InputColumn>
          <InputColumn sx={{ ml: '20px' }}>
            <UpdateProductStatus register={register} watch={watch} />
            <UpdateMediaComponent product={product} />
            <VariantsComponent variants={variants} setValue={setValue} />
          </InputColumn>
        </Box>
      </PageV2>
    );
  } else {
    return <div></div>;
  }
};
