export function getTotalShopcartValue(shopcartProducts: any[]) {
  let totalShopcartValue = 0;
  shopcartProducts.forEach((shopcartProduct: any) => {
    shopcartProduct.variants.forEach((variant: any) => {
      totalShopcartValue += variant.quantity * variant.price;
    });
  });

  return totalShopcartValue;
}
