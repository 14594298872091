import { Box, Typography } from '@mui/material';
import UploadFileIcon from '@mui/icons-material/UploadFile';

export const ImportImagesPage = () => {
  return (
    <Box>
      <Box height={130} mt={3}>
        <Typography
          sx={{ fontFamily: 'Source Code Pro', fontSize: 16, fontWeight: 300 }}
        >
          - Gather all your product images in one folder.
        </Typography>
        <Typography
          sx={{ fontFamily: 'Source Code Pro', fontSize: 16, fontWeight: 300 }}
        >
          - Make sure to name your product images correctly. The image names
          should match with the names in the CSV file.
        </Typography>
        <Typography
          sx={{ fontFamily: 'Source Code Pro', fontSize: 16, fontWeight: 300 }}
        >
          - This is crucial for proper association.
        </Typography>
        <Typography
          sx={{ fontFamily: 'Source Code Pro', fontSize: 16, fontWeight: 300 }}
        >
          - Select all the images inside the folder you created (not the folder
          itself).
        </Typography>
        <Typography
          sx={{ fontFamily: 'Source Code Pro', fontSize: 16, fontWeight: 300 }}
        >
          - Proceed to import these images.
        </Typography>
      </Box>
      <form>
        <input
          id={'csvFileInput'}
          type={'file'}
          style={{ display: 'none' }}
          accept={'.jpg'}
          //onChange={handleCsvSubmit}
        />
        <label htmlFor="csvFileInput">
          <div
            className="importProducts_div"
            style={{
              margin: 'auto',
              width: '500px',
              height: '180px',
              overflow: 'auto',
              marginTop: '50px',
              border: '2px dashed #424242',
              borderRadius: '5px',
            }}
          >
            <div style={{ margin: 'auto', width: '70px', marginTop: '30px' }}>
              <UploadFileIcon
                sx={{
                  color: '#424242',
                  fontSize: '70px',
                }}
              />
            </div>
            <p
              style={{
                fontSize: '18px',
                textAlign: 'center',
                fontFamily: 'Source Code Pro',
              }}
            >
              Select images
            </p>
          </div>
        </label>
      </form>
    </Box>
  );
};
