import Box from '@mui/material/Box';
import { ReactNode } from 'react';
import { grey } from '@mui/material/colors';

export const Page = (props: { children: ReactNode }) => {
  return (
    <Box
      style={{
        marginLeft: '230px',
        backgroundColor: grey[50],
        paddingTop: '0px',
        paddingRight: '0px',
        paddingLeft: '20px',
      }}
    >
      <Box
        style={{
          width: '1200px',
          margin: 'auto',
          paddingTop: '0px',
          height: '100%',
          display: 'flex',
          flexWrap: 'wrap',
          alignContent: 'flex-start',
          justifyContent: 'space-between',
        }}
      >
        {props.children}
      </Box>
    </Box>
  );
};
