import * as React from 'react';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';

interface StoreSelectorProps {
  setStore: any;
  stores: string[];
}

export const StoreSelector: React.FunctionComponent<StoreSelectorProps> = ({
  setStore,
  stores,
}) => {
  const [shippingAddress, setShippingAddress] = React.useState('');

  const handleChange = (event: SelectChangeEvent) => {
    setStore(event.target.value);
    setShippingAddress(event.target.value as string);
  };

  return (
    <Box sx={{ minWidth: 120 }}>
      <FormControl fullWidth>
        <InputLabel id="demo-simple-select-label" size="small">
          Delivery store
        </InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={shippingAddress}
          label="Delivery store"
          onChange={handleChange}
          size="small"
        >
          {stores.map((store) => {
            return (
              <MenuItem key={store} value={store}>
                {store}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
    </Box>
  );
};
