import { FunctionComponent } from 'react';

interface GridComponent2Props {
  name: string;
  placeholder: string;
  defaultValue: any;
  onChange?: any;
  value?: any;
  isBarcode?: boolean;
  isStock?: boolean;
  isSize?: boolean;
}

const GridComponent2: FunctionComponent<GridComponent2Props> = ({
  name,
  placeholder,
  defaultValue,
  value,
  onChange,
  isBarcode,
  isStock,
  isSize,
}) => {
  return (
    <div
      style={{
        width: isBarcode ? '185px' : '75px',
        height: '30px',
        border: '1px solid #D4D2D6',
        overflow: 'hidden',
        marginTop: '4px',
        borderRadius: '3px',
        float: 'left',
        marginLeft: '6px',
      }}
    >
      <input
        name={name}
        placeholder={placeholder}
        onChange={onChange}
        defaultValue={defaultValue}
        value={value && value}
        disabled={isSize}
        style={{
          width: '90%',
          border: '0',
          outline: 'none',
          fontSize: '13px',
          marginTop: '6px',
          textAlign: 'center',
          fontFamily: 'Source Code Pro',
          fontWeight: '500',
          color: isStock ? 'green' : 'black',
          backgroundColor: 'white',
        }}
      ></input>
    </div>
  );
};

export default GridComponent2;
