import { useState, useEffect } from 'react';
import './styles/AdminCatalog.css';
import Box from '@mui/material/Box';
import Loading2 from '../common/loading/Loading2';
import { Page } from '../common/structure/Page';
import { SearchBar } from '../common/SearchBar';
import { ProductCard } from './components/ProductCard';
import { PageHeader } from '../common/structure/PageHeader';
import { PageTitle } from '../common/structure/PageTitle';
import { useGetAllProducts } from '../../../repositories/products/useGetAllProducts';
import { Button } from '@mui/material';
import { useHistory } from 'react-router-dom';
import { PageSubTitle } from '../common/structure/PageSubTitle';
import { ProductDto } from '../../../dtos/ProductDto';

export const ProductsListPage = () => {
  const history = useHistory();
  const [filteredProducts, setFilteredProducts] = useState<ProductDto[]>([]);

  const { data: products, isLoading } = useGetAllProducts();

  useEffect(() => {
    if (!isLoading && products) {
      setFilteredProducts(products);
    }
  }, [isLoading, products]);

  function search(e: any) {
    if (products) {
      setFilteredProducts(
        products.filter(
          (product: any) =>
            product.title
              .toUpperCase()
              .startsWith(e.target.value.toUpperCase()) ||
            product.color
              .toUpperCase()
              .startsWith(e.target.value.toUpperCase()) ||
            product.id.toUpperCase().startsWith(e.target.value.toUpperCase())
        )
      );
    }
  }

  return (
    <>
      {isLoading && <Loading2 />}
      <Page>
        <>
          <PageHeader>
            <>
              <PageTitle title="Products" />
              <PageSubTitle title="Catalog and product management" />
            </>
          </PageHeader>
          <Box
            sx={{
              height: '50px',
              width: '100%',
              display: 'flex',
              justifyContent: 'space-between',
              flexWrap: 'wrap',
              marginBottom: '30px',
              marginRight: '20px',
            }}
          >
            <Box
              sx={{
                height: '30px',
                minWidth: '350px',
                display: 'flex',
                flexWrap: 'wrap',
                gap: '10px',
                marginBottom: '30px',
              }}
            >
              <Button
                variant="contained"
                sx={{
                  backgroundColor: '#424242',
                  '&:hover': {
                    backgroundColor: '#9E9E9E',
                  },
                }}
                onClick={() => history.push('/admin/catalog/addproduct')}
              >
                Create Product
              </Button>
              <Button
                variant="outlined"
                sx={{
                  color: '#424242',
                  borderColor: '#424242',
                  '&:hover': {
                    backgroundColor: '#9E9E9E',
                    color: 'white',
                    borderColor: '#9E9E9E',
                  },
                }}
                onClick={() => history.push('/admin/catalog/importProducts')}
              >
                Import Products
              </Button>
            </Box>
            <Box sx={{ height: '30px', display: 'flex', marginBottom: '30px' }}>
              <SearchBar onChange={search} />
            </Box>
          </Box>
          <div
            style={{
              maxWidth: '1200px',
              display: 'flex',
              flexWrap: 'wrap',
              gap: '20px',
              alignContent: 'center',
            }}
          >
            {filteredProducts.length > 0 &&
              filteredProducts.map((product: any) => (
                <ProductCard key={product.id} product={product} />
              ))}
          </div>
        </>
      </Page>
    </>
  );
};
