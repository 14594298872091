import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
} from '@mui/material';
import { InputComponent } from '../../common/structure/InputComponent';
import { InputV2 } from '../../common/structure/InputV2';
import { useEffect, useState } from 'react';
import { TagsComponentV2 } from './TagsComponentV2';
import { GETrequest } from '../../../../common/functions/requests';
import { Category } from '../../../../models/Category';

export const ProductDetails = () => {
  const changeHandler = (e: any) => {};

  // category
  // get categories
  const [categories, setCategories] = useState<Category[]>();
  const [category, setCategory] = useState('');
  useEffect(() => {
    GETrequest('/stocks/categories').then((categoriesDto: Category[]) => {
      setCategories(categoriesDto);
    });
  }, []);

  const handleChange = (event: SelectChangeEvent) => {
    setCategory(event.target.value as string);
  };

  // tags
  const [tags, setTags] = useState<string[]>([]);

  function createTag(tag: string) {
    if (!tags.includes(tag)) {
      setTags((prevTags) => [...prevTags, tag]);
    }
  }

  function deleteTag(tagToDelete: string) {
    const filteredTags = tags.filter((tag) => tag !== tagToDelete);
    setTags(filteredTags);
  }

  return (
    <InputComponent hasTitle={true} title="Details" sx={{ mt: '20px' }}>
      <FormControl
        fullWidth
        sx={{ marginLeft: '15px', marginTop: '15px', width: '560px' }}
      >
        <InputLabel size="small" id="category-select-label">
          Category
        </InputLabel>
        <Select
          labelId="category-select-label"
          id="category-select"
          value={category}
          label="Category"
          onChange={handleChange}
          size="small"
        >
          {categories?.map((cat) => (
            <MenuItem value={10}>{cat.name}</MenuItem>
          ))}
        </Select>
      </FormControl>
      <TagsComponentV2
        label="Tags"
        values={tags}
        addValue={createTag}
        removeValue={deleteTag}
      />
      <InputV2
        name="description"
        label="Description"
        changeHandler={changeHandler}
        isMultiline
      />
      <InputV2
        name="materials"
        label="Materials"
        changeHandler={changeHandler}
        isMultiline
      />
      <InputV2
        name="informationForClients"
        label="Information for clients"
        changeHandler={changeHandler}
      />
    </InputComponent>
  );
};
