import { Box } from '@mui/material';
import { FunctionComponent, useEffect, useState } from 'react';
import { SearchBar } from '../../../../common/formElements/SearchBar';
import { GETrequest } from '../../../../common/functions/requests';
import { CategorieComponent } from './CategorieComponent';
import { sortProducts } from '../functions/sortProducts';

interface Categorie {
  _id: number;
  name: string;
  isChecked: boolean;
}

interface ProductsFilterComponentProps {
  stateProducts: any;
  products: any;
  setProducts: any;
}

export const FilterSection: FunctionComponent<ProductsFilterComponentProps> = ({
  stateProducts,
  products,
  setProducts,
}) => {
  const [categories, setCategories] = useState<any>([]);

  // To manage categories filter and search at the same time, is necessary to have the logic together

  // Every time a checkbox is clicked or something searched, should be triggered the same function that contains
  // the necessary logic to update the list of products;

  // function to check if product contains tag equal to categorie
  function doesProductHaveCategorie(tags: string[], categorieName: string) {
    let containsTag = false;
    tags.forEach((tag) => {
      if (tag.toUpperCase() === categorieName.toUpperCase()) {
        containsTag = true;
      }
    });

    return containsTag;
  }

  useEffect(() => {
    GETrequest('/stocks/tags').then((categories) => {
      const categoriesFormated: Categorie[] = categories.map(
        (categorie: any) => {
          return {
            id: categorie.id,
            name: categorie.name,
            isChecked: false,
          };
        }
      );
      setCategories(categoriesFormated);
    });
  }, []);

  function handleFilterSearch(e: any) {
    const searchValue = e.target.value.toUpperCase();
    /*

    setSearch(searchValue);

    handleSearch({ eventType: EventType.Search, searchValue });

    */

    setProducts(
      stateProducts.filter((product: any) => {
        const skuWithoutFirstLetter = product.id.substring(1);

        return (
          product.title.toUpperCase().startsWith(searchValue) ||
          product.color.toUpperCase().startsWith(searchValue) ||
          product.id.toUpperCase().startsWith(searchValue) ||
          skuWithoutFirstLetter.toUpperCase().startsWith(searchValue)
        );
      })
    );
  }

  function handleSelectedSearch(selectedCategorie: Categorie, action: any) {
    /*
    handleSearch({
      eventType: EventType.Category,
      selectedCategory: selectedCategorie,
      categoryEvent,
    });
    */

    // update categories
    const categoriesUpdated = categories.map((categorie: Categorie) => {
      if (categorie._id === selectedCategorie._id) {
        return {
          id: categorie._id,
          name: categorie.name,
          isChecked: true,
        };
      } else {
        return categorie;
      }
    });

    setCategories(categoriesUpdated);

    if (action === 'select') {
      const filteredProducts = stateProducts.filter((product: any) => {
        return doesProductHaveCategorie(product.tags, selectedCategorie.name);
      });

      setProducts(filteredProducts);
    } else {
      const categoriesUpdated = categories.map((categorie: any) => {
        if (categorie.id === selectedCategorie._id) {
          return {
            id: categorie.id,
            name: categorie.name,
            isChecked: false,
          };
        } else {
          return categorie;
        }
      });

      setCategories(categoriesUpdated);

      let sortedProducts = sortProducts(stateProducts);

      setProducts(sortedProducts);
    }
  }

  return (
    <Box
      sx={{
        width: '270px',
        height: '600px',
        position: 'fixed',
        float: 'left',
        overflow: 'scroll',
      }}
    >
      <SearchBar onChange={handleFilterSearch} />
      <p
        style={{
          marginTop: '0',
          fontSize: '20px',
          fontFamily: 'Source Code Pro',
          fontWeight: '400',
          marginBottom: '25px',
        }}
      >
        Tags
      </p>
      {categories.map((categorie: any) => {
        return (
          <CategorieComponent
            key={categorie.id}
            categorie={categorie}
            handleChange={handleSelectedSearch}
          />
        );
      })}
    </Box>
  );
};
