export class ProductRepository {
  async getAllProducts(auth: any) {
    try {
      const products = await fetch(
        process.env.REACT_APP_BACKEND_URL + '/products',
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${auth.token}`,
          },
        }
      );
      return await products.json();
    } catch (err) {}
  }

  async getProductById(productId: string, auth: any) {
    try {
      const product = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/products/${productId}`,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${auth.token}`,
          },
        }
      );
      return await product.json();
    } catch (err) {}
  }

  async deleteProduct(productId: string, auth: any) {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/products/${productId}`,
        {
          method: 'DELETE',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${auth.token}`,
          },
        }
      );
      return await response.json();
    } catch (err) {}
  }

  async updateProduct(productId: string, auth: any, product: any) {
    const url = `${process.env.REACT_APP_BACKEND_URL}/products/${productId}`;
    try {
      await fetch(url, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${auth.token}`,
        },
        body: JSON.stringify(product),
      });
    } catch (err) {}
  }
}
