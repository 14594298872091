import { useState, useEffect, useContext } from 'react';
import { useParams } from 'react-router-dom';
import { ProductRepository } from '../../../repositories/ProductRepository';
import { Loading } from '../common/Loading';
import { Authcontext } from '../../../auth/context/auth-context';
import { ProductInformation } from './components/ProductInformation';
import Images from './components/Images';

export const ProductDetailsPage = (props: any) => {
  const auth = useContext(Authcontext);

  const productRepository = new ProductRepository();

  const productId = useParams<any>().productId;
  const [product, setProduct] = useState<any>();

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    productRepository.getProductById(productId, auth).then((product) => {
      setProduct(product);
      setIsLoading(false);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productId]);

  //const discountValue = useSelector((state) => state.userInfo);
  //const { value } = discountValue;
  //const discount = (100 - value) / 100;
  const discount = 1;

  const [maxPrice, setMaxPrice] = useState<number>(0);
  const [minPrice, setMinPrice] = useState<number>(0);

  useEffect(() => {
    const prices: any = [];
    if (product) {
      product.variants.forEach((variant: any) => {
        if (variant.price !== 0 && Number(variant.price))
          prices.push(variant.price * discount);
      });

      setMaxPrice(
        Number(parseFloat(Math.max.apply(null, prices).toString()).toFixed(2))
      );
      setMinPrice(
        Number(parseFloat(Math.min.apply(null, prices).toString()).toFixed(2))
      );
    }
  }, [discount, product]);

  const [image1, setImage1] = useState(undefined);
  const [image2, setImage2] = useState(undefined);
  const [image3, setImage3] = useState(undefined);
  const [image4, setImage4] = useState(undefined);

  useEffect(() => {
    if (product?.media[0]?.url) setImage1(product?.media[0]?.url);
    if (product?.media[1]?.url) setImage2(product?.media[1]?.url);
    if (product?.media[2]?.url) setImage3(product?.media[2]?.url);
    if (product?.media[3]?.url) setImage4(product?.media[3]?.url);
  }, [product?.media]);

  return (
    <>
      {isLoading ? (
        <Loading />
      ) : (
        <div
          style={{
            width: '1200px',
            height: '550px',
            margin: 'auto',
            marginTop: '30px',
            marginBottom: '90px',
          }}
        >
          <Images img1={image1} img2={image2} img3={image3} img4={image4} />
          <ProductInformation
            product={product}
            minPrice={minPrice}
            maxPrice={maxPrice}
          />
        </div>
      )}
    </>
  );
};
