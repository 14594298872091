import { FunctionComponent } from 'react';
import { TextField } from '@mui/material';

interface InputV2Props {
  label: string;
  name: string;
  changeHandler?: any;
  register?: (name: string) => any;
  value?: any;
  isMultiline?: boolean;
  isDisabled?: boolean;
}

export const InputV2: FunctionComponent<InputV2Props> = ({
  label,
  name,
  changeHandler,
  register,
  value,
  isMultiline,
  isDisabled,
}) => {
  if (register) {
    return (
      <TextField
        value={value}
        label={label}
        variant="outlined"
        name={name}
        onChange={changeHandler}
        size="small"
        multiline={isMultiline ? true : false}
        rows={isMultiline ? 4 : 0}
        disabled={isDisabled}
        sx={{
          width: '560px',
          marginLeft: '15px',
          marginTop: '15px',
        }}
        {...register(name)}
      />
    );
  }
  return (
    <TextField
      value={value}
      label={label}
      variant="outlined"
      name={name}
      onChange={changeHandler}
      size="small"
      multiline={isMultiline ? true : false}
      rows={isMultiline ? 4 : 0}
      disabled={isDisabled}
      sx={{
        width: '560px',
        marginLeft: '15px',
        marginTop: '15px',
      }}
    />
  );
};
