import { Box, Switch } from '@mui/material';
import { InputComponent } from '../../common/structure/InputComponent';
import { useState } from 'react';

export const ProductStatus = () => {
  const [isActive, setIsActive] = useState(false);
  const [isShopifySynchronized, setIsShopifySynchronized] = useState(false);

  return (
    <InputComponent
      hasTitle={true}
      title="Status"
      sx={{ ml: '20px', float: 'right' }}
    >
      <Box sx={{ width: '50%', float: 'left' }}>
        <p
          style={{
            marginBottom: '10px',
            marginLeft: '15px',
            fontSize: '15px',
          }}
        >
          B2B
        </p>
        {isActive !== undefined && (
          <Switch
            checked={isActive}
            inputProps={{ 'aria-label': 'controlled' }}
            onChange={() => setIsActive(!isActive)}
            sx={{ ml: '10px' }}
          />
        )}
        {isActive ? (
          <p style={{ display: 'inline', fontSize: '14px' }}>Active</p>
        ) : (
          <p style={{ display: 'inline', fontSize: '14px' }}>Draft</p>
        )}
      </Box>
      <Box sx={{ width: '50%', float: 'left' }}>
        <p
          style={{
            marginBottom: '10px',
            marginLeft: '15px',
            fontSize: '15px',
          }}
        >
          Shopify
        </p>
        {isShopifySynchronized !== undefined && (
          <Switch
            checked={isShopifySynchronized}
            inputProps={{ 'aria-label': 'controlled' }}
            onChange={() => setIsShopifySynchronized(!isShopifySynchronized)}
            sx={{ ml: '10px' }}
          />
        )}
        {isShopifySynchronized ? (
          <p style={{ display: 'inline', fontSize: '14px' }}>Synchronized</p>
        ) : (
          <p style={{ display: 'inline', fontSize: '14px' }}>
            Not Synchronized
          </p>
        )}
      </Box>
    </InputComponent>
  );
};
