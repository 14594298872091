import Box from '@mui/material/Box';
import { DataGrid } from '@mui/x-data-grid';
import { useHistory } from 'react-router-dom';

const columns = [
  { field: 'id', headerName: 'ID', width: 200 },
  {
    field: 'name',
    headerName: 'Name',
    width: 270,
  },
  {
    field: 'email',
    headerName: 'Email',
    width: 320,
  },
  {
    field: 'billingAddress',
    headerName: 'Billing Address',
    width: 350,
  },
];

export const UsersTable = (users: any) => {
  const history = useHistory();

  const rows = users.users.map((user: any) => {
    return {
      id: user.id,
      name: user.name,
      email: user.email,
      billingAddress: user.billingAddress,
    };
  });

  return (
    <Box
      sx={{ height: 625, width: '100%', position: 'relative', float: 'left' }}
    >
      <DataGrid
        rows={rows}
        columns={columns}
        pageSize={10}
        getRowId={(row) => `${row.email}${row.id}`}
        rowsPerPageOptions={[10]}
        disableSelectionOnClick
        onRowClick={(row) => history.push(`/admin/users/${row.row.email}`)}
        sx={{ cursor: 'pointer' }}
      />
    </Box>
  );
};
