import { Box, Chip } from '@mui/material';
import TextField from '@mui/material/TextField';
import { FunctionComponent } from 'react';

interface SelectInput {
  label: string;
  values: any;
  addValue: any;
  removeValue: any;
}

export const TagsComponent: FunctionComponent<SelectInput> = ({
  label,
  values,
  addValue,
  removeValue,
}) => {
  function onEnter(e: any) {
    if (e.key === 'Enter') {
      addValue(e.target.value);
      e.target.value = '';
    }
  }
  function onDelete(categoryId: string) {
    removeValue(categoryId);
  }

  return (
    <Box>
      <TextField
        label={label}
        variant="outlined"
        name="tag"
        onKeyDown={onEnter}
        size="small"
        sx={{ width: '560px', marginLeft: '15px', marginTop: '15px' }}
      />
      <Box sx={{ width: '560px', margin: 'auto' }}>
        {values.map((value: any) => {
          return (
            <Chip
              label={value.name}
              key={value._id}
              onDelete={() => onDelete(value._id)}
              sx={{ mt: '5px', mr: '5px' }}
            />
          );
        })}
      </Box>
    </Box>
  );
};
