import './Login.css';
import React, { useState, useContext, useEffect } from 'react';
import { useHistory, Link } from 'react-router-dom';
import { Authcontext } from './context/auth-context';
import logo from '../images/logo.png';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import LoginIcon from '@mui/icons-material/Login';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert, { AlertProps } from '@mui/material/Alert';
import { GETrequest } from '../common/functions/requests';
import { updateUserInformation } from '../redux2/userInformation';
import { useDispatch } from 'react-redux';

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
  props,
  ref
) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export const LoginPage = () => {
  const dispatch = useDispatch();
  const auth = useContext(Authcontext);
  const history = useHistory();

  const [isLoading, setIsLoading] = useState(false);

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  let responseData;

  // fetch user

  const getUser = async (token: any) => {
    const url = `/users/${email}`;
    try {
      const response = await fetch(process.env.REACT_APP_BACKEND_URL + url, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      });
      const user = await response.json();
      const userInfo = {
        discount: user[0].discount,
        email: user[0].email,
        name: user[0].name,
        shippingAddresses: user[0].shippingAddresses,
      };
      dispatch(updateUserInformation(userInfo));
    } catch (err) {}
  };

  // ---------------------------------

  const [errorSnackBarOpen, setErrorSnackBarOpen] = React.useState(false);

  // landing page
  // request categories from API
  const [isLandingPageActive, setIsLandingPageActive] = useState(false);
  useEffect(() => {
    GETrequest('/stocks/get/landingPage').then((info) => {
      if (info[0].active) {
        setIsLandingPageActive(true);
      }
    });
  }, []);

  const handleKeyPress = async (event: any) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      setIsLoading(true);
      try {
        const response = await fetch(
          process.env.REACT_APP_BACKEND_URL + '/auth/login/',
          {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              email: email,
              password: password,
            }),
          }
        );
        responseData = await response.json();

        setIsLoading(false);

        if (response.ok) {
          auth.login(
            responseData.userId,
            responseData.token,
            responseData.email
          );
          if (responseData.email === 'admin') {
            history.push('/admin/catalog');
          } else {
            // call user info and store it on redux
            getUser(responseData.token);

            if (isLandingPageActive) {
              history.push('/user/homepage');
            } else {
              history.push('/user/catalog');
            }
          }
        } else {
          setErrorSnackBarOpen(true);
        }
      } catch (err) {}
    }
  };

  const clickToLogin = async (event: any) => {
    event.preventDefault();
    setIsLoading(true);
    try {
      const response = await fetch(
        process.env.REACT_APP_BACKEND_URL + '/auth/login',
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            email: email,
            password: password,
          }),
        }
      );
      responseData = await response.json();

      setIsLoading(false);

      if (response.ok) {
        auth.login(responseData.userId, responseData.token, responseData.email);
        if (responseData.email === 'admin') {
          history.push('/admin/catalog');
        } else {
          // call user info and store it on redux
          getUser(responseData.token);
          if (isLandingPageActive) {
            history.push('/user/homepage');
          } else {
            history.push('/user/catalog');
          }
        }
      } else {
        setErrorSnackBarOpen(true);
      }
    } catch (err) {}
  };

  return (
    <>
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={errorSnackBarOpen}
        autoHideDuration={2000}
        onClose={() => setErrorSnackBarOpen(false)}
      >
        <Alert
          onClose={() => setErrorSnackBarOpen(false)}
          severity={'error'}
          sx={{ minWidth: '200px' }}
        >
          {'Authentication failed'}
        </Alert>
      </Snackbar>
      <div className="div-login">
        <div className="login-header"></div>
        <div className="div-login-img">
          <img src={logo} alt="logo"></img>
        </div>
        <div className="login-data">
          <div className="login-data_div">
            <TextField
              label="Username"
              variant="outlined"
              onChange={(e) => setEmail(e.target.value)}
              size="small"
              sx={{ width: '250px', marginTop: '20px' }}
            />
            <TextField
              label="Password"
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              variant="outlined"
              size="small"
              sx={{ width: '250px', marginTop: '20px' }}
              onKeyPress={handleKeyPress}
            />
            <Button
              variant="contained"
              size="small"
              startIcon={<LoginIcon />}
              sx={{
                width: '130px',
                float: 'right',
                marginTop: '15px',
                backgroundColor: '#424242',
                borderColor: '#424242',
                '&:hover': {
                  backgroundColor: '#9E9E9E',
                  borderColor: '#9E9E9E',
                },
              }}
              onClick={clickToLogin}
              disabled={isLoading}
            >
              LOGIN
            </Button>

            <Link style={{ textDecoration: 'none' }} to="/passwordRecovery">
              <p className="p_login-forgotten">Forgot password?</p>
            </Link>
          </div>
        </div>
      </div>
    </>
  );
};
