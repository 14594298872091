export const TableLegend = () => {
  return (
    <div style={{ marginBottom: '10px', marginTop: '10px' }}>
      <p
        style={{
          fontFamily: 'Source Code Pro',
          fontWeight: '500',
          fontSize: '12px',
          display: 'inline',
          color: 'grey',
          marginLeft: '39px',
        }}
      >
        Size
      </p>
      <p
        style={{
          fontFamily: 'Source Code Pro',
          fontWeight: '500',
          fontSize: '12px',
          display: 'inline',
          marginLeft: '50px',
          color: 'grey',
        }}
      >
        Stock
      </p>
      <p
        style={{
          fontFamily: 'Source Code Pro',
          fontWeight: '500',
          fontSize: '12px',
          display: 'inline',
          marginLeft: '45px',
          color: 'grey',
        }}
      >
        Price
      </p>
      <p
        style={{
          fontFamily: 'Source Code Pro',
          fontWeight: '500',
          fontSize: '12px',
          display: 'inline',
          marginLeft: '55px',
          color: 'grey',
        }}
      >
        PVP
      </p>
      <p
        style={{
          fontFamily: 'Source Code Pro',
          fontWeight: '500',
          fontSize: '12px',
          display: 'inline',
          marginLeft: '95px',
          color: 'grey',
        }}
      >
        Barcode
      </p>
    </div>
  );
};
