import { Box, Typography } from '@mui/material';
import { aggregateProducts } from '../functions/aggregateProducts';
import Papa from 'papaparse';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import { useContext } from 'react';
import { Authcontext } from '../../../../auth/context/auth-context';
import { useDispatch } from 'react-redux';
import { showError, showSuccess } from '../../../../redux2/snackbar';

export const ImportCsvPage = () => {
  const auth = useContext(Authcontext);
  const dispatch = useDispatch();

  // create category
  async function importProducts(products: any) {
    try {
      const response = await fetch(
        process.env.REACT_APP_BACKEND_URL + '/products/import',
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${auth.token}`,
          },
          body: JSON.stringify(products),
        }
      );
      if (response.ok) {
        dispatch(showSuccess('Products imported'));
      } else {
        dispatch(showError('Products import failed'));
      }
    } catch (err) {
      dispatch(showError('Products import failed'));
    }
  }

  const handleCsvSubmit = (e: any) => {
    const file = e.target.files[0];
    if (file) {
      Papa.parse(file, {
        complete: function (results) {
          const aggregatedProducts = aggregateProducts(results.data);
          console.log(aggregatedProducts);
          importProducts(aggregatedProducts);
        },
      });
    }
  };

  function downloadCsvExample() {
    console.log('download');
  }

  return (
    <Box>
      <Box height={130}>
        <Typography
          sx={{ fontFamily: 'Source Code Pro', fontSize: 16, fontWeight: 300 }}
        >
          - Download an example CSV file to understand the required format. You
          can get it{' '}
          <span
            style={{ color: 'blue', cursor: 'pointer' }}
            onClick={downloadCsvExample}
          >
            here
          </span>
          .
        </Typography>
        <Typography
          sx={{ fontFamily: 'Source Code Pro', fontSize: 16, fontWeight: 300 }}
        >
          - When adding tags and images to the CSV, separate them with a comma
          (,).
        </Typography>
        <Typography
          sx={{ fontFamily: 'Source Code Pro', fontSize: 16, fontWeight: 300 }}
        >
          - Finally, import the updated CSV file.
        </Typography>
      </Box>

      <form>
        <input
          id={'csvFileInput'}
          type={'file'}
          style={{ display: 'none' }}
          accept={'.csv'}
          onChange={handleCsvSubmit}
        />
        <label htmlFor="csvFileInput">
          <Box
            className="importProducts_div"
            sx={{
              margin: 'auto',
              width: '500px',
              height: '180px',
              overflow: 'auto',
              marginTop: '50px',
              border: '2px dashed #424242',
              borderRadius: '5px',
            }}
          >
            <Box sx={{ margin: 'auto', width: '70px', marginTop: '30px' }}>
              <UploadFileIcon
                sx={{
                  color: '#424242',
                  fontSize: '70px',
                }}
              />
            </Box>
            <p
              style={{
                fontSize: '18px',
                textAlign: 'center',
                fontFamily: 'Source Code Pro',
              }}
            >
              Select CSV file
            </p>
          </Box>
        </label>
      </form>
    </Box>
  );
};
