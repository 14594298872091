import { useContext, useEffect, useState } from 'react';
import { Authcontext } from '../../auth/context/auth-context';
import { apiRequest } from '../common/apiRequest';
import { Method } from '../common/Method';

export const useGetProductById = (productId: string) => {
  const auth = useContext(Authcontext);

  const [data, setData] = useState<any>();
  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    const getProductById = async () => {
      const product = await apiRequest(
        `/products/${productId}`,
        Method.GET,
        auth
      );
      setData(product);
      setIsLoading(false);
    };
    getProductById();
  }, [auth, productId]);

  return { data, isLoading };
};
