import * as React from 'react';
import Paper from '@mui/material/Paper';
import InputBase from '@mui/material/InputBase';
import IconButton from '@mui/material/IconButton';
import SearchIcon from '@mui/icons-material/Search';

export const SearchBar = ({ onChange }) => {
  return (
    <Paper
      component="form"
      sx={{
        display: 'flex',
        width: '240px',
        marginLeft: '1px',
        marginBottom: '20px',
        height: '37px',
      }}
    >
      <InputBase
        size="small"
        sx={{ ml: 1, flex: 1 }}
        placeholder="Search"
        inputProps={{ 'aria-label': 'Search' }}
        onChange={onChange}
      />
      <IconButton type="button" sx={{ p: '10px' }} aria-label="search">
        <SearchIcon />
      </IconButton>
    </Paper>
  );
};
