import { Box, Button } from '@mui/material';
import { FunctionComponent, useContext, useEffect, useState } from 'react';
import { ProductQuantitySelector } from '../common/components/ProductQuantitySelector';
import { Link, useParams } from 'react-router-dom';
import { GETauthrequest } from '../../../common/functions/requests';
import { Loading } from '../common/Loading';
import { Authcontext } from '../../../auth/context/auth-context';
import { sortByProductIdFirst } from './utils/sortByProductIdFirst';

export const QuantitySelectorPage: FunctionComponent = () => {
  const auth = useContext(Authcontext);

  const productName: string = useParams<any>().productName;
  const productId: string = useParams<any>().productId;

  const [products, setProducts] = useState<any>([]);

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    GETauthrequest(auth, '/products/name/' + productName).then(
      (products: any) => {
        const sortedProducts = sortByProductIdFirst(products, productId);
        setProducts(sortedProducts);
        setIsLoading(false);
      }
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productName]);

  if (productName.length === 0) {
    return null;
  }

  console.log(products);

  return (
    <>
      {isLoading ? (
        <Loading />
      ) : (
        <Box
          style={{
            width: '1200px',
            margin: 'auto',
          }}
        >
          {products
            .filter((product: any) => product.active)
            .map((product: any) => (
              <ProductQuantitySelector product={product} key={product.id} />
            ))}
          <Box
            sx={{
              width: '200px',
              height: '35px',
              float: 'right',
              mt: '30px',
            }}
          >
            <Link to="/user/catalog">
              <Button
                variant="contained"
                className="button"
                style={{ width: '100%', marginBottom: '30px' }}
              >
                CONTINUE SHOPPING
              </Button>
            </Link>
          </Box>
        </Box>
      )}
    </>
  );
};
