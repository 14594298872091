import {
  Alert,
  Box,
  Button,
  Snackbar,
  Switch,
  TextField,
  Typography,
} from '@mui/material';
import { SyntheticEvent, useContext, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Authcontext } from '../../../../auth/context/auth-context';
import { GETrequest } from '../../../../common/functions/requests';

export const LandingPage = () => {
  const auth = useContext(Authcontext);

  const [defaultValues, setDefaultValues] = useState<any>();

  const { register, handleSubmit, watch, reset } = useForm({
    defaultValues: async () => defaultValues,
  });

  // request categories from API
  useEffect(() => {
    GETrequest('/stocks/get/landingPage').then((info) => {
      let values = {
        active: info.active,
        title: info.title,
        subtitle: info.subtitle,
      };
      setDefaultValues(values);
      reset({
        active: info[0].active,
        title: info[0].title,
        subtitle: info[0].subtitle,
      });
    });
  }, [reset]);

  const onSubmit = async (data: any) => {
    const url =
      process.env.REACT_APP_BACKEND_URL + '/stocks/update/landingPage';
    try {
      let request: any = await fetch(url, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${auth.token}`,
        },
        body: JSON.stringify(data),
      });

      let response = await request.json();

      if (response.message === 'success') {
        handleClick();
      }
    } catch (err) {}
  };

  const title = watch('title');
  const subtitle = watch('subtitle');
  const active = watch('active');

  // snack bar
  const [open, setOpen] = useState(false);

  const handleClick = () => {
    setOpen(true);
  };

  const handleClose = (
    event: Event | SyntheticEvent<any, Event>,
    reason: string
  ) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };

  if (!defaultValues) return null;

  return (
    <Box mt="20px">
      <Snackbar
        open={open}
        autoHideDuration={2000}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      >
        <Alert
          onClose={() => setOpen(false)}
          severity="success"
          sx={{ width: '100%' }}
        >
          Landing page updated
        </Alert>
      </Snackbar>
      <Box
        sx={{
          width: '750px',
          height: '450px',
          backgroundColor: 'rgb(229, 229, 229)',
          overflow: 'hidden',
          float: 'left',
        }}
      >
        <Box sx={{ width: '90%', margin: 'auto', mt: '150px' }}>
          <Typography variant="h2" align="center">
            {title}
          </Typography>
          <Typography variant="h4" align="center">
            {subtitle}
          </Typography>
        </Box>
      </Box>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Box sx={{ float: 'left', ml: '30px' }}>
          <Switch
            checked={active}
            {...register('active')}
            inputProps={{ 'aria-label': 'controlled' }}
            sx={{ float: 'left' }}
          />
          <Typography
            sx={{ float: 'left', fontSize: '18px', mt: '5px', ml: '10px' }}
          >
            {active ? 'Active' : 'Disabled'}
          </Typography>
          <br />
          <TextField
            id="outlined-basic"
            label="Title"
            variant="outlined"
            defaultValue={title}
            size="small"
            sx={{
              width: '300px',
              marginTop: '20px',
            }}
            {...register('title')}
          />
          <br />
          <TextField
            id="outlined-basic"
            label="Subtitle"
            variant="outlined"
            defaultValue={subtitle}
            {...register('subtitle')}
            size="small"
            sx={{
              marginTop: '20px',
              width: '300px',
            }}
          />
          <br />
          <Button
            variant="contained"
            sx={{
              marginTop: '20px',
              backgroundColor: '#424242',
              '&:hover': {
                backgroundColor: '#9E9E9E',
              },
            }}
            type="submit"
          >
            Save
          </Button>
        </Box>
      </form>
    </Box>
  );
};
