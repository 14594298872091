import { FunctionComponent, useEffect, useState } from 'react';
import '../components/Product.css';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { getProductMaxPrice } from '../functions/getProductMaxPrice';
import { getProductMinPrice } from '../functions/getProductMinPrice';

interface ProductCardProps {
  product: any;
}

export const ProductCard: FunctionComponent<ProductCardProps> = ({
  product,
}) => {
  const [isImageLoaded, setIsImageLoaded] = useState(false);

  const categoryFirstName = product.category.split(' ')[0];

  const discount = 1;

  const minPrice = getProductMinPrice(product.variants, discount);
  const maxPrice = getProductMaxPrice(product.variants, discount);
  const isMinPriceEqualToMaxPrice = minPrice === maxPrice;

  useEffect(() => {}, [isImageLoaded]);

  const isPriceVisible = useSelector(
    (state: any) => state.priceVisiblitiy.value
  );

  return (
    <Link
      to={`/user/catalog/product/${product.id}`}
      style={{ textDecoration: 'none' }}
    >
      <div className="user-div-product">
        <div className="admin-imagem_produto">
          <img
            style={{ display: isImageLoaded ? 'block' : 'none' }}
            src={product.media[0].url}
            onLoad={() => setIsImageLoaded(true)}
            alt="product"
          />
        </div>
        <div className="sku_and_categorie">
          <p className="div_produto-categorie">{categoryFirstName}</p>
          <p className="div_produto-sku">{product.id}</p>
        </div>
        <p className="div_produto-title">
          {product.title} {product.color}
        </p>
        {isPriceVisible &&
          (product.variants.length === 0 ? (
            <p className="div_produto-price-notAvailable">Not Available</p>
          ) : (
            <p className="admin-div_produto-price">
              {isMinPriceEqualToMaxPrice
                ? `${minPrice}€`
                : `${minPrice}€ - ${maxPrice}€`}
            </p>
          ))}
      </div>
    </Link>
  );
};
