import './LoginFooter.css';
import countryIcon from '../../images/netherlandsIcon.png';
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import StoreOutlinedIcon from '@mui/icons-material/StoreOutlined';
import Box from '@mui/material/Box';
import logo from '../../images/logosNorte2020.png';

const LoginFooter = () => {
  return (
    <footer className="div-login_footer">
      <div className="div_footer_information">
        <div
          style={{
            margin: 'auto',
            width: '830px',
            height: '80%',
          }}
        >
          <div
            className="div-footer-about"
            style={{
              width: '230px',
              marginRight: '70px',
            }}
          >
            <Box sx={{ marginLeft: '42px' }}>
              <div style={{ float: 'left', marginRight: '5px' }}>
                <StoreOutlinedIcon sx={{ fontSize: '18px', mt: '0px' }} />
              </div>
              <p className="div-footer-about-title">ABOUT OUR BRAND</p>
            </Box>
            <a
              className="div-footer-about-a"
              href={process.env.REACT_APP_LINK_STORY}
            >
              <p className="div-footer-about-links">Our Story</p>
            </a>
            <a
              className="div-footer-about-a"
              href={process.env.REACT_APP_LINK_NEWS}
            >
              <p className="div-footer-about-links">News</p>
            </a>
            <a
              className="div-footer-about-a"
              href={process.env.REACT_APP_LINK_LOOKBOOK}
            >
              <p className="div-footer-about-links">Lookbook</p>
            </a>
          </div>
          <div
            className="div-footer-info"
            style={{
              width: '230px',
              marginRight: '70px',
            }}
          >
            <Box sx={{ marginLeft: '58px' }}>
              <div style={{ float: 'left', marginRight: '5px' }}>
                <InfoOutlinedIcon sx={{ fontSize: '18px', mt: '0px' }} />
              </div>
              <p className="div-footer-about-title">INFORMATION</p>
            </Box>
            <a
              className="div-footer-about-a"
              target="_tab"
              href={process.env.REACT_APP_LINK_TERMSANDCONDITIONS}
            >
              <p className="div-footer-about-links">Terms and Conditions</p>
            </a>
            <a
              className="div-footer-about-a"
              href={process.env.REACT_APP_LINK_PRIVACY}
            >
              <p className="div-footer-about-links">Privacy Policy</p>
            </a>
            <a
              className="div-footer-about-a"
              href={process.env.REACT_APP_LINK_COOKIES}
            >
              <p className="div-footer-about-links">Cookies Privacy</p>
            </a>
          </div>
          <div className="div-footer-contact" style={{ width: '230px' }}>
            <Box sx={{ marginLeft: '67px' }}>
              <div style={{ float: 'left', marginRight: '5px' }}>
                <LocationOnOutlinedIcon sx={{ fontSize: '18px', mt: '0px' }} />
              </div>
              <p className="div-footer-about-title">CONTACTS</p>
            </Box>
            <p className="div-footer-about-links">
              {process.env.REACT_APP_LINK_PHONE}
            </p>
            <p className="div-footer-about-links">
              {process.env.REACT_APP_LINK_EMAIL}
            </p>
            <p className="div-footer-about-links">
              {process.env.REACT_APP_LINK_ADDRESS}
            </p>
            <Box sx={{ marginLeft: '50px' }}>
              <div style={{ float: 'left', marginRight: '5px' }}>
                <img src={countryIcon} alt="logo" style={{ width: '15px' }} />
              </div>
              <p
                style={{
                  fontSize: '12px',
                  fontWeight: '500',
                  cursor: 'pointer',
                  color: 'black',
                  fontFamily: 'Source Code Pro',
                }}
              >
                {process.env.REACT_APP_LINK_COUNTRY}
              </p>
            </Box>
          </div>
        </div>
      </div>

      <div
        style={{
          width: '100%',
          height: '50px',
          textAlign: 'center',
        }}
      >
        <img
          src={logo}
          alt="logo"
          style={{ height: '100%', width: '500px' }}
        ></img>
      </div>

      <div className="line_footer"></div>

      <p className="p_footer_developer" style={{ textAlign: 'center' }}>
        <b>StockWised</b>
      </p>
    </footer>
  );
};

export default LoginFooter;
