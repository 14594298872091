import React, { FunctionComponent } from 'react';
import Checkbox from '@mui/material/Checkbox';

interface CategorieComponentProps {
  categorie: any;
  handleChange: any;
}

export const CategorieComponent: FunctionComponent<CategorieComponentProps> = ({
  categorie,
  handleChange,
}) => {
  const [checked, setChecked] = React.useState(false);

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setChecked(event.target.checked);
    handleChange(categorie, event.target.checked ? 'select' : 'unselect');
  };

  return (
    <div style={{ marginTop: '0px' }} key={categorie.id}>
      <Checkbox
        checked={checked}
        onChange={handleCheckboxChange}
        inputProps={{ 'aria-label': 'controlled' }}
        size="small"
      />
      <p
        style={{
          display: 'inline',
          fontSize: '17px',
          marginTop: '7px',
          fontFamily: 'Source Code Pro',
          fontWeight: '300',
        }}
      >
        {categorie.name}
      </p>
    </div>
  );
};
