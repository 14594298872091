import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from 'react-router-dom';
import { useState, useCallback } from 'react';
import { LoginPage } from './auth/LoginPage';
import { ProductsListPage as UserProductsListPage } from './features/user/products/ProductsListPage';
import { ProductDetailsPage } from './features/user/products/ProductDetailsPage';
import { PurchaseConfirmationPage } from './features/user/shopcartV2/PurchaseConfirmationPage';
import { OrdersListPage as UserOrdersListPage } from './features/user/orders/OrdersListPage';
import { OrderDetailPage as UserOrderDetailPage } from './features/user/orders/OrderDetailPage';
import { AccountPage as UserAccount } from './features/user/account/AccountPage';
import { ProductsListPage as AdminProductsListPage } from './features/admin/products/ProductsListPage';
import { OrdersListPage as AdminOrdersListPage } from './features/admin/orders/OrdersListPage';
import { OrderDetailPage as AdminOrderDetailPage } from './features/admin/orders/OrderDetailPage';
import { UsersListPage as AdminUsersListPage } from './features/admin/users/UsersListPage';
import { Navbar as UserNavbar } from './features/user/common/header/Navbar';
import { Navbar } from './features/admin/common/navbar/Navbar';
import LoginFooter from './auth/components/LoginFooter';
import { StockErrorPage } from './features/user/shopcartV2/StockErrorPage';
import { AccountPage as AdminAccountPage } from './features/admin/account/AccountPage';
import { DashboardOverviewPage } from './features/admin/dashboard/DashboardPage';
import { Authcontext } from './auth/context/auth-context';
import React from 'react';
import { UserDetailPage as AdminUserDetailPage } from './features/admin/users/UserDetailPage';
import { ImportUsersPage } from './features/admin/users/ImportUsersPage';
import { ImportProductsPage } from './features/admin/products/ImportProductsPage';
import { StockPage } from './features/admin/stocks/StockPage';
import { ShopifyPage } from './features/admin/shopify/ShopifyPage';
import { StoreSettingsPage } from './features/admin/settings/StoreSettingsPage';
import { LandingPage } from './features/user/landingPage/LandingPage';
import { ShopcartPageV2 } from './features/user/shopcartV2/ShopcartPageV2';
import { QuantitySelectorPage } from './features/user/quantitySelectorV2/QuantitySelectorPage';
import { CreateOrderPage } from './features/admin/orders/CreateOrderPage';
import { CreateProductPage } from './features/admin/products/CreateProductPage';
import { CreateUserPageV2 } from './features/admin/users/CreateUserPageV2';
import { UpdateProductPage } from './features/admin/products/UpdateProductPage';
import { RequestPasswordRecoveryPage } from './auth/RequestPasswordRecoveryPage';
import { PasswordRecoveryPage } from './auth/PasswordRecoveryPage';

export const Routes: React.FC = () => {
  const [token, setToken] = useState<any>(false);
  const [userId, setUserId] = useState<any>(null);
  const [email, setEmail] = useState<string>('');

  const login = useCallback((uid: any, token: any, email: string) => {
    setToken(token);
    setUserId(uid);
    setEmail(email);
  }, []);

  const logout = useCallback(() => {
    setToken(null);
    setUserId(null);
    setEmail('');
  }, []);

  let routes;

  if (
    token &&
    (email === 'admin' || email === 'adminPerto' || email === 'administrator')
  ) {
    routes = (
      <Switch>
        <Route path="/admin/catalog" exact>
          <Navbar />
          <AdminProductsListPage />
        </Route>
        <Route path="/admin/shopify" exact>
          <Navbar />
          <ShopifyPage />
        </Route>
        <Route path="/admin/updateStocks" exact>
          <Navbar />
          <StockPage />
        </Route>
        <Route path="/admin/catalog/addproduct" exact>
          <Navbar />
          <CreateProductPage />
        </Route>
        <Route path="/admin/catalog/editproduct/:productId" exact>
          <Navbar />
          <UpdateProductPage />
        </Route>
        <Route path="/admin/catalog/importProducts" exact>
          <Navbar />
          <ImportProductsPage />
        </Route>
        <Route path="/admin/orders" exact>
          <Navbar />
          <AdminOrdersListPage />
        </Route>
        <Route path="/admin/orders/createOrder" exact>
          <Navbar />
          <CreateOrderPage />
        </Route>
        <Route path="/admin/orders/:orderId" exact>
          <Navbar />
          <AdminOrderDetailPage />
        </Route>
        <Route path="/admin/users" exact>
          <Navbar />
          <AdminUsersListPage />
        </Route>
        <Route path="/admin/users/adduser" exact>
          <Navbar />
          <CreateUserPageV2 />
        </Route>
        <Route path="/admin/users/importUsers" exact>
          <Navbar />
          <ImportUsersPage />
        </Route>
        <Route path="/admin/users/:email" exact>
          <Navbar />
          <AdminUserDetailPage />
        </Route>
        <Route path="/admin/account" exact>
          <Navbar />
          <AdminAccountPage />
        </Route>
        <Route path="/admin/store" exact>
          <Navbar />
          <StoreSettingsPage />
        </Route>
        <Route path="/admin/dashboard/overview" exact>
          <Navbar />
          <DashboardOverviewPage />
        </Route>
        <Redirect to="admin/catalog" />
      </Switch>
    );
  } else if (token) {
    routes = (
      <Switch>
        <Route path="/user/catalog" exact>
          <UserNavbar />
          <UserProductsListPage />
        </Route>
        <Route path="/user/homepage" exact>
          <UserNavbar />
          <LandingPage />
        </Route>
        <Route path="/user/catalog/product/:productId" exact>
          <UserNavbar />
          <ProductDetailsPage />
        </Route>
        <Route
          path="/user/catalog/quantity/:productName/product/:productId"
          exact
        >
          <UserNavbar />
          <QuantitySelectorPage />
        </Route>
        <Route path="/user/catalog/shopcart" exact>
          <UserNavbar />
          <ShopcartPageV2 />
        </Route>
        <Route path="/user/catalog/confirmation" exact>
          <PurchaseConfirmationPage />
        </Route>
        <Route path="/user/catalog/stockError" exact>
          <StockErrorPage />
        </Route>
        <Route path="/user/orders" exact>
          <UserNavbar />
          <UserOrdersListPage />
        </Route>
        <Route path="/user/orders/:orderId" exact>
          <UserNavbar />
          <UserOrderDetailPage />
        </Route>
        <Route path="/user/account" exact>
          <UserNavbar />
          <UserAccount />
        </Route>
      </Switch>
    );
  } else {
    routes = (
      <Switch>
        <Route path="/" exact>
          <LoginPage />
          <LoginFooter />
        </Route>
        <Route path="/passwordRecovery" exact>
          <RequestPasswordRecoveryPage />
          <LoginFooter />
        </Route>
        <Route path="/passwordRecovery/:code" exact>
          <PasswordRecoveryPage />
          <LoginFooter />
        </Route>
        <Redirect to="/" />
      </Switch>
    );
  }

  return (
    <Authcontext.Provider
      value={{
        isLoggedIn: !!token,
        userId: userId,
        token: token,
        email: email,
        login: login,
        logout: logout,
      }}
    >
      <Router>{routes}</Router>
    </Authcontext.Provider>
  );
};
